import Footer from '../footer';
import Header from '../Header';
import Whatsapp from '../Whatsapp';

function PoliticaPrivacidad() {
  return (
    <>
      <Header isHome={false} />
      <Whatsapp />
      <div className="container mt-5 mb-md-5">
        <h1 className="h1">Política de privacidad</h1>
        <h2 className="h3">1. PROTECCIÓN DE DATOS DE CARÁCTER PERSONAL</h2>
        <p>
          <span>
            <strong>DATOS DE CONTACTO DEL RESPONSABLE DE TRATAMIENTO</strong>
          </span>
        </p>
        <ul>
          <li>
            <span>Denominación social: DRESS KIDS STORE S.L.</span>
          </li>
          <li>
            <span>NIF: B05397534</span>
          </li>
          <li>
            <span>
              Domicilio social: AVDA. CERVANTES Nº 51, EDIFICIO 10, 5º, 48970,
              BASAURI, VIZCAYA.
            </span>
          </li>
          <li>
            <span>Teléfono contacto: 944.005.005</span>
          </li>
          <li>
            <span>
              Dirección de correo:{' '}
              <a className="lightLink" href="mailto:info@dresskids.es">
                info@dresskids.es
              </a>
            </span>
          </li>
        </ul>
        <h3 className="h3">
          1.1. DATOS DE CONTACTO DEL DATA PROTECTION OFFICER<span>  </span>
          (D.P.O.)
        </h3>
        <p>
          Conforme al artículo 37 del RGPD 2016/679 y al artículo 34 de la LO
          3/2018, no se requiere D.P.O.
        </p>

        <h3 className="h3">
          1.2 DEBER DE INFORMACIÓN CONFORME AL RGPD 679/2016 Y A LA LO 3/2018.
        </h3>
        <p>
          El usuario que accede a poner sus datos en los formularios de contacto
          de esta página web, conforme a lo expuesto en este párrafo de este
          aviso legal, DECLARA Y MANIFIESTA EXPRESAMENTE QUE:
        </p>
        <p>
          Autoriza expresamente a la entidad DRESS KIDS STORE S.L. con domicilio
          en AVDA. CERVANTES Nº 51, EDIFICIO 10, 5º, 48970, BASAURI, VIZCAYA a
          la recogida y tratamiento de los datos de carácter personal de quien
          firma, o actúa como representante legal, tutor o curador, de alguna
          persona menor de edad. Así mismo declara que se le ha informado
          convenientemente acerca de la tabla que se presenta en relación al
          derecho recogido en el art. 13 del RGPD.
        </p>

        <p>
          <strong>
            INFORMACIÓN BÁSICA SOBRE PROTECCIÓN DE DATOS DE CLIENTES
          </strong>
        </p>
        <p>
          RESPONSABLE: DRESS KIDS STORE S.L. AVDA. CERVANTES Nº 51, EDIFICIO 10,
          5º, 48970, BASAURI, VIZCAYA FINALIDAD PREVISTA: Gestión de los datos
          introducidos en los formularios de recogida de datos de la página.
          Puede requerir el registro de Actividades de Tratamiento mediante
          solicitud en el correo electrónico: info@dresskids.es DPO: Conforme al
          artículo 37 del RGPD 2016/679 y al artículo 34 de la LO 3/2018, no se
          requiere D.P.O. LEGITIMACIÓN: Consentimiento expreso del interesado
          que puede ser revocado en cualquier momento. TRANSFERENCIA
          INTERNACIONAL DE DATOS: No se hará transferencia internacional de
          datos. DESTINATARIOS DE CESIONES DE DATOS: No se cederán datos salvo
          que sea necesario para cumplir con la propia prestación solicitada.
          Asesoría fiscal y contable (en caso de compra a través de la web).
          Empresas de transporte para poder entregar las compras realizadas.
          DERECHOS: Derecho a solicitar el acceso a los datos personales
          relativos al interesado. Derecho a solicitar su rectificación o
          supresión. Derecho a solicitar la limitación de su tratamiento.
          Derecho a oponerse al tratamiento. Derecho a la portabilidad de los
          datos. Derecho a la exclusión publicitaria. INFORMACIÓN ADICIONAL:
          Puede presentar en cualquier momento reclamación ante la AEPD en calle
          Jorge Juan, 6. 28001 - Madrid. PLAZOS DE CONSERVACIÓN DE LOS DATOS:
          CLIENTES: Mientras dure la relación comercial. USUARIO: Mientras dure
          la relación con usuario. Puede ser revocado en cualquier momento DÓNDE
          EJERCITAR SUS DERECHOS: Solicite nuestros formularios para el
          ejercicio de derechos en: info@dresskids.es.
        </p>
        <p>
          Se prohíbe el que menores de 14 años pongan los datos en nuestros
          formularios de recogida de datos, dado que es contrario al art. 8
          RGPD, en caso de necesitar el consentimiento de un menor, deberá darlo
          y por lo tanto recoger datos de la persona que ostenta la Patria
          Potestad y/o tutela del menor.
        </p>
        <p>
          La presente política de protección de datos podrá variar con el tiempo
          debido a los posibles cambios legislativos, jurisprudenciales o de los
          criterios seguidos por la Agencia de Española de Protección de Datos
          y/o la autoridad competente en cada momento. Es por ello que DRESS
          KIDS STORE S.L. se reserva el derecho a modificar el presente aviso
          legal para adaptarlo a novedades legislativas o jurisprudenciales que
          se encuentran vigentes en el momento preciso en que se acceda a los
          websites, así como a prácticas del sector. Le pedimos que revise este
          aviso legal periódicamente.
        </p>
        <h3 className="h3">
          1.3 DERECHOS RECONOCIDOS POR EL REGLAMENTO UE 2016/679 Y LO 3/2018
        </h3>
        <p>
          Como titular de derechos, usted tiene el control de sus datos, y por
          lo tanto en cualquier momento puede ejercitar sus derechos. Los
          derechos que puede ejercitar son:
        </p>
        <ul>
          <li>
            <strong>
              Derecho a solicitar el acceso a los datos personales:
            </strong>{' '}
            usted podrá preguntar a DRESS KIDS STORE S.L. si tratamos sus datos.
          </li>
          <li>
            <strong>Derecho a solicitar su rectificación, </strong>siempre y
            cuando estén recogidos incorrectamente
            <strong>; y o solicitar su supresión.</strong>
          </li>
          <li>
            <strong>Derecho a solicitar la limitación de su tratamiento</strong>
            : en determinados casos puedes solicitar suspender temporalmente el
            tratamiento de los datos o que se conserven durante más tiempo del
            necesario cuando sea necesario.
          </li>
          <li>
            <strong>Derecho a oponerse al tratamiento: </strong>Dejaremos de
            tratar sus datos, salvo por motivos legales.
          </li>
          <li>
            <strong>Derecho a la portabilidad de los datos: </strong>Puede
            pedirnos en cualquier momento que enviemos sus datos a la mercantil
            que desee, mediante petición expresa.
          </li>
          <li>
            <strong>Derecho a la exclusión publicitaria</strong>, en cualquier
            momento usted puede comunicarnos su deseo de no recibir publicidad
            de nuestra empresa, comunicándonoslo mediante un email.
          </li>
        </ul>
        <h3 className="h3">1.4. CÓMO PUEDO SOLICITAR LOS DERECHOS</h3>
        <p>
          DRESS KIDS STORE S.L. informará a todos sus trabajadores acerca del
          procedimiento para atender los derechos de los interesados; tenemos
          definido un protocolo para poder dar respuesta a todos los ejercicios
          de derechos. Nos pueden enviar su solicitud de ejercicio de derechos a
          info@dresskids.es o bien por correo postal a nuestra dirección que
          ponemos conforme al art. 10 de la LSSI – CE. Le pedimos que, siempre
          para el ejercicio de los mismos, tengan en cuenta lo siguiente:
        </p>
        <ul>
          <li>
            Los titulares de los datos personales (interesados) podrán ejercer
            los derechos reconocidos en el Reglamento 679/2016 y LO 3/2018 de 5
            diciembre. El ejercicio de los derechos es gratuito.
          </li>
          <li>
            El responsable del tratamiento deberá dar respuesta a los
            interesados sin dilación indebida y de forma concisa, transparente,
            inteligible, con un lenguaje claro y sencillo y conservar la prueba
            del cumplimiento del deber de responder a las solicitudes de
            ejercicio de derechos formuladas.
          </li>
          <li>
            Si la solicitud se presenta por medios electrónicos, la información
            se facilitará por estos medios cuando sea posible, salvo que el
            interesado solicite que sea de otro modo.
          </li>
          <li>
            Las solicitudes deben responderse en el plazo de 1 mes desde su
            recepción, pudiendo prorrogarse en otros dos meses teniendo en
            cuenta la complejidad o el número de solicitudes, pero en ese caso
            debe informarse al interesado de la prórroga en el plazo de un mes a
            partir de la recepción de la solicitud, indicando los motivos de la
            dilación.
          </li>
        </ul>

        <h2 className="h3">
          2. PRINCIPIOS BÁSICOS QUE RIGEN EN NUESTRA POLÍTICA DE PRIVACIDAD
        </h2>
        <p>
          Este aviso legal, se rige por el PRINCIPIO DE TRANSPARENCIA, y está
          redactado con la finalidad que se pueda entender por todas las
          personas que se conectan a esta página web. En caso de tener alguna
          duda en cuanto a su complejidad, no duden en ponerse en contacto para
          poder aclararlo.
        </p>
        <h2 className="h3">
          2.1. CONFIDENCIALIDAD
        </h2>
        <p>
          Todos los datos proporcionados mediante correo electrónico o
          formularios electrónicos serán tratados según la normativa vigente
          sobre protección de datos personales, y en todo caso tendrán el
          carácter de confidencial para el personal de DRESS KIDS STORE S.L. que
          gestione dicha información, como así firman en los contratos de
          confidencialidad que nuestra entidad regula con sus empleados.
        </p>
        <h3 className="h3">
          2.2. INFORMACIÓN SOBRE LA VOLUNTAD DE DEJAR LOS DATOS Y SUS
          CONSECUENCIAS
        </h3>
        <ul>
          <li>
            <strong>
              VOLUNTARIEDAD
              <br />{' '}
            </strong>
            Se informa a los Usuarios, que las respuestas a las preguntas
            planteadas en los formularios de recogida de datos contenidos en la
            presente website tienen carácter voluntario, si bien la negativa a
            facilitar los datos solicitados puede comportar la imposibilidad de
            acceso a los servicios que lo requieran.
          </li>
        </ul>
        <ul>
          <li>
            <strong>CONSECUENCIA</strong>
            <br />
            Mediante la implementación de los formularios incluidos en la web,
            relativos a servicios prestados por DRESS KIDS STORE S.L. los
            Usuarios aceptan la inclusión y tratamiento de los datos que
            proporcionen en un fichero de datos de carácter personal, del cual
            es titular de DRESS KIDS STORE S.L., pudiéndose ejercer los derechos
            pertinentes según la cláusula siguiente.
          </li>
        </ul>
        <p>
          Puede solicitar en la dirección postal o electrónica de la entidad el
          Registro de las Actividades de Tratamiento de sus datos
        </p>
        <h3 className="h3">
          2.3 INFORMACIÓN SOBRE LOS DATOS QUE SE CONSERVAN
        </h3>
        <p>
          Los datos que conservamos son los meramente recogidos en los
          formularios que se tienen en la página web como son datos de contacto
          (carácter básico), como son el nombre, apellido, e-mail, teléfono,
          etc. Esta información es recibida por DRESS KIDS STORE S.L. y nunca es
          vendida, cedida o arrendada a otras empresas, exceptuando el caso
          lógico para la prestación del servicio.
        </p>

        <h3 className="h3">2.4. DURANTE CUÁNTO TIEMPO</h3>
        <ul>
          <li>
            <strong>
              Datos recogidos para la entrega de nuestro newsletter
            </strong>
            : Desde la suscripción hasta el momento que solicita el usuario la
            baja del servicio mediante el envío de un mail.
          </li>
        </ul>
        <ul>
          <li>
            <strong>
              Datos de usuarios subidos a páginas y redes sociales:
            </strong>
             Desde que el usuario ofrece su consentimiento hasta que nos
            comunica su voluntad de permanecer en las mismas.
          </li>
        </ul>
        <p>
          El usuario, en todo caso, puede en cualquier momento revocar el
          consentimiento para el tratamiento de sus datos.
        </p>
        <h3 className="h3">2.5. CON QUÉ FINALIDAD SE CONSERVAN DATOS</h3>
        <p>
          La única finalidad de tratar sus datos es prestarle el servicio
          solicitado por nuestra empresa y en caso de que nos haya consentido el
          envío de comunicaciones comerciales, poder enviarle las novedades
          sobre productos y precios que vendemos.
        </p>
        <h2 className="h3">
          3. INFORMACIÓN DE LA LISTA DE DISTRIBUCIÓN DE INFORMACIÓN
        </h2>
        <p>
          Si ha aceptado usted el envío de publicidad vía correo electrónico,
          DRESS KIDS STORE S.L. podría envía un e-mail notificando mejoras,
          novedades u ofertas, que se produzcan en esta página Web. En cualquier
          momento pueden darse de baja enviando un e-mail a{' '}
          <a className="lightLink" href="mailto:info@dresskids.es">
            info@dresskids.es
          </a>
          .
        </p>
        <p>
          <strong>
            INFORMACIÓN BÁSICA SOBRE PROTECCIÓN DE DATOS DE CLIENTES
          </strong>
        </p>
        <p>
          RESPONSABLE: DRESS KIDS STORE S.L. AVDA. CERVANTES Nº 51, EDIFICIO 10,
          5º, 48970, BASAURI, VIZCAYA FINALIDAD PREVISTA: Envío de publicidad,
          siempre y cuando el usuario haya aceptado dicho envío. Gestión de los
          datos introducidos en los formularios de recogida de datos de la
          página. Puede requerir el registro de Actividades de Tratamiento
          mediante solicitud en el correo electrónico: info@dresskids.es DPO:
          Conforme al artículo 37 del RGPD 2016/679 y al artículo 34 de la LO
          3/2018, no se requiere D.P.O. LEGITIMACIÓN: Consentimiento expreso del
          interesado que puede ser revocado en cualquier momento. TRANSFERENCIA
          INTERNACIONAL DE DATOS: No se hará transferencia internacional de
          datos. DESTINATARIOS DE CESIONES DE DATOS: No se cederán datos salvo
          que sea necesario para cumplir con la propia prestación solicitada.
          DERECHOS: Derecho a solicitar el acceso a los datos personales
          relativos al interesado. Derecho a solicitar su rectificación o
          supresión. Derecho a solicitar la limitación de su tratamiento.
          Derecho a oponerse al tratamiento. Derecho a la portabilidad de los
          datos. Derecho a la exclusión publicitaria. INFORMACIÓN ADICIONAL:
          Puede presentar en cualquier momento reclamación ante la AEPD en calle
          Jorge Juan, 6. 28001 - Madrid. PLAZOS DE CONSERVACIÓN DE LOS DATOS:
          USUARIO: Conforme a la política de cookies. DÓNDE EJERCITAR SUS
          DERECHOS: Solicite nuestros formularios para el ejercicio de derechos
          en: info@dresskids.es.
        </p>
      </div>
      <Footer />
    </>
  );
}
export default PoliticaPrivacidad;
