import formalKidNino from '../../img/outfits/niño/formal-kid.jpg';
import formalMiniNino from '../../img/outfits/niño/formal-mini.jpg';
import sportKidNino from '../../img/outfits/niño/sport-kid.jpg';
import sportMiniNino from '../../img/outfits/niño/sport-mini.jpg';
import casualKidNino from '../../img/outfits/niño/casual-kid.jpg';
import casualMiniNino from '../../img/outfits/niño/casual-mini.jpg';

import formalKidNina from '../../img/outfits/niña/formal-kid.jpg';
import formalMiniNina from '../../img/outfits/niña/formal-mini.jpg';
import sportKidNina from '../../img/outfits/niña/sport-kid.jpg';
import sportMiniNina from '../../img/outfits/niña/sport-mini.jpg';
import casualKidNina from '../../img/outfits/niña/casual-kid.jpg';
import casualMiniNina from '../../img/outfits/niña/casual-mini.jpg';

function Outfit(props) {
  return (
    <>
		{props && props.foto === 'formalKidNino' && 
			<img className='w-100' src={formalKidNino} />
		}
		{props && props.foto === 'formalMiniNino' &&
			<img className='w-100' src={formalMiniNino} />
		}
		{props && props.foto === 'sportKidNino' &&
			<img className='w-100' src={sportKidNino} />
		}
		{props && props.foto === 'sportMiniNino' &&
			<img className='w-100' src={sportMiniNino} />
		}
		{props && props.foto === 'casualKidNino' &&
			<img className='w-100' src={casualKidNino} />
		}
		{props && props.foto === 'casualMiniNino' &&
			<img className='w-100' src={casualMiniNino} />
		}
		
		{props && props.foto === 'formalKidNina' && 
			<img className='w-100' src={formalKidNina} />
		}
		{props && props.foto === 'formalMiniNina' &&
			<img className='w-100' src={formalMiniNina} />
		}
		{props && props.foto === 'sportKidNina' &&
			<img className='w-100' src={sportKidNina} />
		}
		{props && props.foto === 'sportMiniNina' &&
			<img className='w-100' src={sportMiniNina} />
		}
		{props && props.foto === 'casualKidNina' &&
			<img className='w-100' src={casualKidNina} />
		}
		{props && props.foto === 'casualMiniNina' &&
			<img className='w-100' src={casualMiniNina} />
		}
		</>
  );
}

export default Outfit;
