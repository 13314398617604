import { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ChildSelector from '../components/ChildSelector';
import UserChildSelector from '../components/UserChildSelector';
import { PrimaryButton } from '../ui/atoms/Button';
import { PATH_PAGINA_INICIO } from '../constants';
import KidInfoContext from '../contexts/KidInfoContext';
import AuthContext from '../contexts/AuthContext.js';
import Whatsapp from '../components/Whatsapp';
import { Helmet } from 'react-helmet';
import Logo from '../img/logo.svg';

function Paso0() {
  const [, setKidInfo] = useContext(KidInfoContext);
  const [auth] = useContext(AuthContext);
  const history = useHistory();

  return (
    <>
      <Helmet>
        <title>¿Para quién va a ser el pack? - Dresskids</title>
      </Helmet>
      <div className="container pt-5 pb-5">
        <Whatsapp />
        <Link to={`${auth.user ? '/area-usuario' : PATH_PAGINA_INICIO}`}>
          <i className="bi bi-arrow-left fs-3"></i>
        </Link>
        {auth.user && (
          <h1 className="h1 text-center">
            Hola de nuevo, ¡nos alegramos de volver a verte!
          </h1>
        )}

        {auth.user === null && (
          <>
            <div className="logo-mob mt-4 d-flex mb-4">
              {' '}
              <img src={Logo} alt="Logo Dresskids" />
            </div>
            <p className="fs-4 mb-5">
              Te regalamos tiempo y de la compra de ropa para tus peques ya nos
              encargamos nosotros. Sólo cuéntanos cómo es tu hij@, el
              presupuesto que quieres gastarte y ¡deja que se haga la magia!
              Recibirás tu pack exclusivo donde nos indiques. Si no te gusta no
              hay problema, nos lo cuentas y devuélvelo gratis ¿Empezamos?
            </p>
          </>
        )}
        
        {auth.user === null && (
                <div className="col-12 mt-2 mb-5 d-flex justify-content-center">
                  <Link to="/login">
                    <PrimaryButton value="¿Ya tienes cuenta Dresskids?" />
                  </Link>
                  {/* <PrimaryButton
                  value="Continuar"
                  onClick={() => {
                    setKidInfo((prev) => ({ ...prev, creating: true }));
                    history.push('/paso1');
                  }}
                /> */}
                </div>
        )}

        <h1 className="h1 text-center">¿Para quién va a ser el pack?</h1>

        <div className="row d-flex justify-content-center mt-4">
          <div className="col-12">
            <div className="row mt-4">
              <div className="col-12">
                <div className="d-flex flex-row flex-md-row justify-content-center align-items-center mb-3 mb-md-0">
                  {auth.user ? <UserChildSelector /> : <ChildSelector />}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Paso0;
