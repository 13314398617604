import { useContext } from 'react';
import Header from '../components/Header.js';
import HubspotForm from 'react-hubspot-form';
import ChildSelector from '../components/ChildSelector.js';
import AuthContext from '../contexts/AuthContext.js';
import Whatsapp from '../components/Whatsapp.js';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';
import { PATH_PAGINA_INICIO } from '../constants.js';
import { PrimaryButton } from '../ui/atoms/Button.js';
import Logo from '../img/logo.svg';
import Chat from '../img/chat.svg';
import Slide1 from '../img/slides/slide-4.jpg';
import Slide2 from '../img/slides/slide-2.jpg';
import Slide3 from '../img/slides/slide-3.jpg';
import Slide4 from '../img/slides/slide-1.jpg';
import Slide1mob from '../img/slides/slide-4-mob.jpg';
import Slide2mob from '../img/slides/slide-2-mob.jpg';
import Slide3mob from '../img/slides/slide-3-mob.jpg';
import Slide4mob from '../img/slides/slide-1-mob.jpg';
import { Helmet } from 'react-helmet';
import Footer from '../components/footer.js';

function Home(props) {
  const [auth] = useContext(AuthContext);

  return (
    <>
      <Helmet>
        <title>
          Dresskids | Tu Personal Shopper Infantil. Ropa para niños online
        </title>
        <meta
          name="description"
          content="Creamos looks de moda infantil de las mejores marcas de ropa para niños y niñas de 2 a 12 años. Compra online en 3 minutos ¡Y recibe tu caja en 48 horas!"
        />
      </Helmet>
      <div className="container-fluid px-0">
        <Header isHome={true} />
        <div className="d-flex flex-column flex-md-row ">
          <div className="left-content d-flex flex-column order-1 order-md-0">
            <div className="d-none d-md-flex flex-column padding">
              <Link to={PATH_PAGINA_INICIO}>
                <img
                  className="logo mb-2 mt-4"
                  src={Logo}
                  alt="Logo Dresskids"
                />
              </Link>
              <p className='mb-4'>La nueva forma de comprar ropa para tus peques</p>

              <a
                href="https://api.whatsapp.com/send?phone=34747449077&text=¡Hola!,%20tengo%20una%20duda%20sobre%20Dresskids"
                target="_blank"
                className="chat "
                rel="noreferrer"
              >
                <img src={Chat} />
              </a>
            </div>

            <div className="home-left-text padding py-4 py-md-5 mt-md-5">
              <h2 className="h3 secondaryC" id="como-funciona">
                Cómo funciona
              </h2>
              <p className="mb-1">
                <strong>1. Háblanos de tu peque.</strong> Rellena el breve
                cuestionario que te facilitamos. En Solo 6 pasos conoceremos
                vuestros gustos y necesidades.
              </p>
              <p className="mb-1">
                <strong>2. Elige tu pack.</strong> Para adaptarnos a vuestras
                necesidades, tienes disponible dos packs diferentes: Pack
                DressKids y el Pack Personalizado.
              </p>
              <p className="mb-1">
                <strong>3. Recíbelo en casa.</strong> Recibirás el Pack en tu
                domicilio. Dispones de 5 días para probar las prendas
                cómodamente y sin prisas.{' '}
                <a href="/como-funciona">Saber más ›</a>
              </p>
              <Link to="/paso0">
                <PrimaryButton className="mt-3" value="Comenzar" />
              </Link>
            </div>
{/*             <div className="footer-home padding py-3">
              <div className="d-flex flex-row">
                <a href="https://www.facebook.com/dresskids.es" target="_blank">
                  <i className="fab fa-facebook-f me-4"></i>
                </a>
                <a
                  href="https://www.instagram.com/dresskids.es/"
                  target="_blank"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </div> */}
          </div>

          <div className="right-content order-0 order-md-1 ">
            <div className="home-right position-relative">
              <Carousel controls={false} className="d-none d-sm-flex" fade>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={Slide1}
                    alt="Invierte tu tiempo donde más te importa"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={Slide2}
                    alt="Convierte una oblicación en algo divertido"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={Slide3}
                    alt="Da una solución a tu día a día"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={Slide4}
                    alt="Evita llevar a tus hijos de tiendas"
                  />
                </Carousel.Item>
              </Carousel>
              
              <div className="d-flex d-md-none info-text-mob">
                <p>La nueva forma de comprar ropa para tus peques</p>
              </div>
              
              <Carousel controls={false} className="d-flex d-sm-none " fade>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={Slide1mob}
                    alt="Invierte tu tiempo donde más te importa"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={Slide2mob}
                    alt="Convierte una oblicación en algo divertido"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={Slide3mob}
                    alt="Da una solución a tu día a día"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={Slide4mob}
                    alt="Evita llevar a tus hijos de tiendas"
                  />
                </Carousel.Item>
              </Carousel>
              <div className="btn-comenzar padding d-flex flex-column d-md-none">
                <a className="btn btn-dark mb-3 " href="/paso0">
                  Comenzar
                </a>
                <a className="text-center fw-bold" href="#como-funciona">
                  <p className="mb-0 fw-bold">¿Cómo funciona?</p>
                  <i className="fas fa-chevron-down flecha text-center"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Home;
