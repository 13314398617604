import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { API_URL, PATH_PAGINA_INICIO } from '../constants';
import AuthContext from '../contexts/AuthContext';
import KidInfoContext, {
  kidInfoInitialState,
} from '../contexts/KidInfoContext';
import { getEndpointItemsWithPagination } from '../services/api-calls';
import {
  saveToLocalStorage,
  clearLocalStorage,
} from '../services/local-storage-manager';

function NavigationGuards() {
  const { pathname } = useLocation();
  const history = useHistory();
  const [auth, setAuth] = useContext(AuthContext);
  const [kidInfo, setKidInfo] = useContext(KidInfoContext);
  const [previousLocation, setPreviousLocation] = useState('');

  useEffect(() => {
    let lsAuth = localStorage.getItem('auth');
    if (!lsAuth) return;
    try {
      lsAuth = JSON.parse(lsAuth);
      if (!lsAuth.expiry) return;
      const currentTime = new Date();
      const expiryDate = new Date(lsAuth.expiry);
      if (currentTime > expiryDate) {
        localStorage.clear('auth');
        setAuth({ user: null, token: null, expiry: null });
        setKidInfo(kidInfoInitialState);
        window.location = PATH_PAGINA_INICIO;
        return;
      }
    } catch (e) {
      localStorage.clear('auth');
      window.location = PATH_PAGINA_INICIO;
      return;
    }

    if (
      pathname.startsWith('/paso11') ||
      pathname.startsWith('/fallo-compra/')
    ) {
      setAuth(lsAuth);
      return;
    }

    if (!auth.token) {
      setAuth(lsAuth);
      history.push(PATH_PAGINA_INICIO);
      return;
    } else {
      setAuth(lsAuth);
    }

    const getUserData = async () => {
      const userResponse = await fetch(`${API_URL}/users/me`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      });

      const user = await userResponse.json();
      if (userResponse.status !== 200) {
        history.push(PATH_PAGINA_INICIO);
      }

      const addressResponse = await fetch(`${API_URL}/address/me`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      });

      if (addressResponse.status !== 200) {
        history.push(PATH_PAGINA_INICIO);
      }
      let provinces = await getEndpointItemsWithPagination('provinces');
      provinces = provinces.map((province, index) => ({
        ...province,
        code: '' + index,
      }));
      provinces.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      let userAddress = await addressResponse.json();

      /* console.log(userAddress.status, "userAddress.status")
      console.log("hydra:member" in userAddress) */
/*       if ('hydra:member' in userAddress === false) {
        setAuth({ user: null, token: null });

        clearLocalStorage();
        history.push(PATH_PAGINA_INICIO);
      } */
      let address = userAddress['hydra:member'][0];
      if (address) {
        let addressCountry = provinces.find((p) => p.name === address.country);
        address = { ...address, country: addressCountry };
      }
      setAuth({
        user: { ...user, address },
        token: auth.token,
      });
      saveToLocalStorage(user, auth.token);
    };

    getUserData();
  }, [auth.token, history, setAuth, setKidInfo, pathname]);

  useEffect(() => {
    if (
      (pathname === '/paso1' ||
        pathname === '/paso2' ||
        pathname === '/paso3' ||
        pathname === '/paso4' ||
        pathname === '/paso5' ||
        pathname === '/paso6' ||
        pathname === '/paso7') &&
      kidInfo.gender === kidInfoInitialState.gender
    ) {
      history.push('/paso0');
      return null;
    }

    if (
      (pathname === '/paso2' ||
        pathname === '/paso3' ||
        pathname === '/paso4' ||
        pathname === '/paso5' ||
        pathname === '/paso6' ||
        pathname === '/paso7') &&
      (kidInfo.birthday === kidInfoInitialState.birthday ||
        kidInfo.eyesColor === kidInfoInitialState.eyesColor ||
        kidInfo.hairColor === kidInfoInitialState.hairColor ||
        kidInfo.skinColor === kidInfoInitialState.skinColor)
    ) {
      history.push('/paso1');
      return null;
    }
    if (pathname !== previousLocation) {
      window.scrollTo(0, 0);
    }
    setPreviousLocation(pathname);
  }, [
    pathname,
    previousLocation,
    history,
    kidInfo.gender,
    kidInfo.birthday,
    kidInfo.eyesColor,
    kidInfo.hairColor,
    kidInfo.skinColor,
    auth.token,
    setAuth,
  ]);

  return null;
}

export default NavigationGuards;
