import React from 'react';
import clsx from 'clsx';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { makeStyles } from '@material-ui/core/styles';

export const CheckBoxAtom = ({
  className,
  value,
  label,
  required,
  onChange,
  tabIndex,
  checked
}) => {
  const classes = CustomCheckBox();
  const handleOnChange = (e) => {
    onChange(e.target.checked);
  };

  console.log(checked,"cheked")

  const handleKeyDown = (event, e) => {
    if (event.key === 'Enter') {
      onChange(e.target.checked);
    }
  };

  return (
    <FormControlLabel
      className={classes.label}
      label={label}
      htmlFor={label}
      control={
        <Checkbox
          id={label}
          className={`${classes.root} ${className} ${checked ? 'checked':''}`}
          onChange={(e) => handleOnChange(e)}
          onKeyDown={(e) => handleKeyDown(e)}
          disableRipple
          color="default"
          checked={checked}
          checkedIcon={
            <span className={clsx(classes.icon, classes.checkedIcon)} />
          }
          icon={<span className={classes.icon} />}
          inputProps={{
            'aria-label': 'decorative checkbox',
            tabIndex: tabIndex,
          }}
        />
      }
    />
  );
};

const CustomCheckBox = makeStyles({
  root: {
    padding: '0',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:focus': {
      '&.icon': {
        border: '12px #79757B solid',
      },
    },
  },
  label: {
    '& span': {
      fontFamily: 'Kreon',
      fontSize: '12px',
      color: '#242324',
    },
  },
  icon: {
    borderRadius: 5,
    width: 20,
    height: 20,
    border: '2px #79757B solid',
    'input:hover ~ &': {
      backgroundColor: '#fff',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: 'var(--primary-color)',
    border: '2px var(--primary-color) solid',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: 'var(--primary-color)',
    },
  },
  '&.checked': {
    backgroundColor: 'var(--primary-color)',
    border: '2px var(--primary-color) solid',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: 'var(--primary-color)',
    },
  }
});
