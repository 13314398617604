import { useContext, useEffect, useState } from 'react';
import {
  PATH_PAGINA_INICIO,
  API_URL,
  TOKEN_ENDPOINT,
  API_BASE_URL,
} from '../constants';
import { PrimaryButton } from '../ui/atoms/Button';
import { Link, useHistory } from 'react-router-dom';
import AuthContext from '../contexts/AuthContext';
import { saveToLocalStorage } from '../services/local-storage-manager';

function LoginUsuario() {
	const history = useHistory();

	const [provinces, setProvinces] = useState([]);
  const [, setAuth] = useContext(AuthContext);
  // localStorage.setItem('auth', '{"user":{"@context":"/api/contexts/User","@id":"/api/users/95","@type":"User","id":95,"email":"marta@gurenet.com","name":"nomc","surname":"apc","phone":"13","packages":[{"@id":"/api/packages/253","@type":"Package","id":253,"identificator":"616fc462e324b","packageItems":[],"total":"0.00","date":"2021-10-20T07:25:22+00:00","isPaid":false,"retryPayment":true},{"@id":"/api/packages/249","@type":"Package","id":249,"identificator":"616d87010e5d7","packageItems":[{"@id":"/api/package_items/336","@type":"PackageItem","price":19.99},{"@id":"/api/package_items/337","@type":"PackageItem","price":14.99}],"total":"34.98","date":"2021-10-18T14:38:57+00:00","isPaid":false,"retryPayment":true},{"@id":"/api/packages/238","@type":"Package","id":238,"identificator":"61667e91afbf8","packageItems":[],"total":"0.00","date":"2021-10-13T06:37:05+00:00","isPaid":false,"retryPayment":true},{"@id":"/api/packages/220","@type":"Package","id":220,"identificator":"615ac542b99bd","packageItems":[{"@id":"/api/package_items/288","@type":"PackageItem","price":19.99},{"@id":"/api/package_items/289","@type":"PackageItem","price":22.99},{"@id":"/api/package_items/290","@type":"PackageItem","price":12.99}],"total":"41.98","date":"2021-10-04T09:11:30+00:00","isPaid":false,"retryPayment":true}]},"token":"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE2MzkxMjU5NTIsImV4cCI6MTYzOTEyOTU1Miwicm9sZXMiOlsiUk9MRV9VU0VSIl0sInVzZXJuYW1lIjoibWFydGFAZ3VyZW5ldC5jb20ifQ.AMpQq1q4Yy3orfPB4aPYd8W04hkLt14RlnkB-jOjAJkmMkFRFJSfsvuKBzWvw5KMYqwbJLUojPWFAuicuLYUw9n3xl5GLhouxLXqiKipA5lnEDSrBAH0Fby0ODTbeBp3916DR6PWFp_o2qaQpqZqRYpTIvx-F3PAcbyaTl6u5ymFGT_WdzTwhVBqlCTNp_uEiwg0L3bQjWugLHZE6kAyxPeyqonAaELWlCLe3MsDdp62zo9OTGok9gxQ15tko3SaCh5OYMcms4sXAN_uJfnQYhRCCiqdW9nRRZBxsflMUjFmMlD8llsxr_I7JHiX5BDQK_N0hcFDTd9Oskm_IR5A6A","expiry":"2021-12-10T09:00:54.599Z"}')

  return (
    <PrimaryButton
      className="w-100"
      value="Iniciar sesión"
      onClick={async () => {
        let loginSucces = true;


        let token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE2MzkxMjk4MTUsImV4cCI6MTYzOTEzMzQxNSwicm9sZXMiOlsiUk9MRV9VU0VSIl0sInVzZXJuYW1lIjoibWFydGFAZ3VyZW5ldC5jb20ifQ.Hbc5DPpvMO00rf2y4FkLtCshcR3VTl8woc7-yqfh3aCywswaumiKce_DSdRXphwDs2zoIcAjH69C4fdWjesFTwTXU20x7Y1UmnveQTI8rWR3OqbazPRw7ZQqmLFUESgT-Ysre6ii06_bi0gx83EFLGPm9GqFrP1-V9L5IuMzhlsO7uEYRHElcx7ro8j18R8SddN1kNX2V45gk7vuwAUroik_N6tXJkPp5NaewZUbvvUB7OPNiKS3yZ4Cu7AbhWjFU_mFb0mg3EBLIGlrK-M_ufNdZwC0WcKUqlNvxHi0aqEtLFRu6WjQGEuOByz2HGj5T5LNuDXy6G9y_St0eQodYA';
        let user;
        if (loginSucces) {

          const userResponse = await fetch(`${API_URL}/users/me`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          });
          user = await userResponse.json();
          // if (userResponse.status !== 200) {
          //   MySwal.fire({
          //     html: 'Email o contraseña incorrectos.',
          //     icon: 'error',
          //   });
          //   loginSucces = false;
          // }
        }
        if (loginSucces) {
         
          const addressResponse = await fetch(`${API_URL}/address/me`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          });
          let userAddress = await addressResponse.json();
          let address = userAddress['hydra:member'][0];
          if (address) {
            let addressCountry = provinces.find(
              (p) => p.name === address.country
            );
            address = { ...address, country: addressCountry };
          }
          setAuth({
            user: { ...user, address },
            token,
          });
          saveToLocalStorage(user, token);
        }

        history.push('/area-usuario');
      }}
    />
  );
}

export default LoginUsuario;
