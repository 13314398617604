import Footer from '../footer';
import Header from '../Header';
import Whatsapp from '../Whatsapp';

function CondicionesDeContratacion() {
  return (
    <>
      <Header isHome = {false}/>
      <Whatsapp/>
      <div className="container mt-5 mb-md-5 ">
        <h1 className="h1 top">
          Condiciones generales generales y específicas de contratación y uso de la web{' '}
          <br />
          <a className="lightLink" href="http://www.dresskids.es">www.dresskids.es</a>
        </h1>
        <p></p>

        <h2 className="h3">1. Introducción</h2>
        <p>
          Las presentes condiciones de contratación han sido redactadas teniendo
          en cuenta el RDL 1/2007, de 1 de abril, y sus modificaciones en
          relación a la legislación sobre consumidores y usuarios.
        </p>
        <p>
          En las mismas se regula la relación entre los consumidores
          (compradores a través de nuestra web) y nuestra empresa
          <strong>
            {' '}
            como propietaria de la web y en concepto de vendedor.{' '}
          </strong>
          Así como la{' '}
          <a className="lightLink" href="http://noticias.juridicas.com/base_datos/Privado/l7-1998.html">
            Ley
          </a>{' '}
          7/1998, de 13 de abril, sobre condiciones generales de la contratación
          que las rige, y por la normativa específica que regula la profesión
          del vendedor.
        </p>
        <p>
          El contrato que rige nuestra relación es de{' '}
          <span style={{ textDecoration: 'underline' }}>
            <strong>COMPRAVENTA FUERA DEL ESTABLECIMIENTO MERCANTIL</strong>
          </span>
          , regulado en el RDL 1/2007, de 1 de abril, sobre consumidores y
          usuarios.
        </p>
        <p>
          A su vez y previo a el contrato de compraventa, se da un contrato de
          PRESTACION DE SERVICIO DE ASESORAMIENTO por parte de la mercantil al
          consumidor, que depende de los datos y gustos que este consumidor
          previamente haya manifestado.
        </p>
        <p>
          www.dresskids.es podrá, en todo momento y sin previo aviso, modificar
          las presentes Condiciones Generales mediante la publicación de dichas
          modificaciones en el Portal con el fin de que puedan ser conocidas por
          los Usuarios siempre antes de la visita al Portal o a la adquisición
          de cualesquiera bienes ofertados en el Portal. Cumpliendo así el deber
          de información que la legislación le requiere.
        </p>
        <p>
          El presente contrato se regirá por la legislación española, en
          concreto la Ley de Consumidores y Usuarios, así como el resto de
          legislación que afecte a consumidores y usuarios, tanto en la
          legislación nacional como la de la Unión Europea, que será de
          aplicación en lo no dispuesto en este contrato en materia de
          interpretación, validez y ejecución.
        </p>
        <p>
          También se tiene en cuenta la Legislación sobre Condiciones de
          Contratación y la vigente Directiva 2002/58/CE sobre privacidad y
          comercio electrónico traspuesta en España a través de la Ley 34/2002.
        </p>
        <p>
          En caso de no regular algunas de las cuestiones que se susciten, será
          de aplicación como norma subsidiaria el Código Civil.
        </p>
        <p>
          El idioma de las condiciones generales de contratación y que rige lo
          pactado en estas condiciones es el ESPAÑOL-CASTELLANO, cualquier
          traducción realizada a las mismas, y en caso de discrepancia será de
          aplicación para su resolución el español - castellano.
        </p>
        <p>
          Como{' '}
          <span style={{ textDecoration: 'underline' }}>
            <strong>
              norma general de obligado cumplimiento para el usuario de la web
            </strong>
          </span>{' '}
          para estas condiciones, y antes de comenzar con las preguntas
          frecuentes que regirán las condiciones generales, el usuario/
          comprador se obliga a:
        </p>
        <p></p>
        <ul>
          <li>
            En el caso de registrarse, el Usuario se obliga a proporcionar
            verazmente los datos proporcionados y a mantenerlos actualizados,
            obligación recogida como principio de la Ley de Protección de datos
            de Carácter Personal.
          </li>
          <li>
            No introducir, almacenar o difundir en o desde el Portal, cualquier
            información o material que fuera difamatorio, injurioso, obsceno,
            amenazador, xenófobo, incite a la violencia a la discriminación por
            razón de raza, sexo, ideología, religión o que de cualquier forma
            atente contra la forma, el orden público, los derechos
            fundamentales, las libertades públicas, el honor, la intimidad o la
            imagen de terceros y en general la normativa vigente.
          </li>
          <li>
            No introducir, almacenar o difundir mediante el Portal ningún
            programa de ordenador, datos, virus, código, equipo de hardware o de
            telecomunicaciones o cualquier otro instrumento o dispositivo
            electrónico o físico que sea susceptible de causar daños en el
            Portal, en cualquiera de los Servicios, o en cualquiera de los
            equipos, sistemas o redes de www.dresskids.es, de cualquier Usuario,
            de sus proveedores o en general de cualquier tercero, o que de
            cualquier otra forma sea capaz de causarles cualquier tipo de
            alteración o impedir el normal funcionamiento de los mismos.
          </li>
          <li>
            No realizar actividades publicitarias, promocionales o de
            explotación comercial a través del Portal, no utilizando los
            contenidos y en particular la información obtenida a través del
            Portal para remitir publicidad, enviar mensajes con fines de venta
            directa o con cualquier otro fin comercial, ni para recoger o
            almacenar datos personales de terceros.
          </li>
          <li>
            No utilizar identidades falsas, ni suplantar la identidad de otros
            en la utilización del Portal o en la utilización o adquisición de
            cualquiera de los Productos, incluyendo la utilización en su caso de
            contraseñas o claves de acceso de terceros o de cualquier otra
            forma.
          </li>
          <li>
            No introducir, almacenar o difundir mediante el Portal cualquier
            contenido que infrinja derechos de propiedad intelectual, industrial
            o secretos empresariales de terceros, ni en general ningún contenido
            del cual no ostentara, de conformidad con la ley, el derecho a
            ponerlo a disposición de tercero.
          </li>
        </ul>
        <p></p>

        <h2 className="h3">2. Usuario</h2>
        <p>
          El acceso a la website de www.dresskids.es, implica que acepta ser
          usuario, e implica la aceptación de las condiciones de uso y el
          cumplimiento de la normativa legal de aplicación a la página web. Esta
          responsabilidad incluye:
        </p>
        <ol>
          <li>
            La licitud y veracidad de los datos que se faciliten en los
            formularios en los que se le soliciten datos de carácter personal
            para proceder a la operación de compraventa a través de la web.
          </li>
          <li>
            El uso adecuado de la información y contenido ofrecido en la página
            web de www.dresskids.es, conforme a la normativa nacional e
            internacional que sea de aplicación.
          </li>
          <li>
            En cuanto a rellenar datos como comprador, se regirá por la
            normativa vigente sobre protección de datos recogida en la “Política
            de privacidad” de la página web.
          </li>
          <li>
            Se solicitarán tallajes de menores cuyos datos serán puestos por
            mayores de edad y, por lo tanto, en la ficha de cliente habrá datos
            de menores que se regirán por la “Política de privacidad” expuesta
            en nuestro aviso legal.
          </li>
        </ol>
        <p></p>

        <h2 className="h3">3. Características de los servicios ofertados</h2>
        <p>
          Todos los productos que vendemos a través de la web tienen una serie
          de características y elementos comunes en cuanto a seguridad, y
          materiales a utilizar.
        </p>
        <p>
          Las fotografías que aparecen en nuestra página web tratan de ser lo
          más reales posibles, pero no olvide que son fotografías, además las
          que utilizamos son de nuestros proveedores y por lo tanto pueden ser
          exactas
        </p>
        <p>
          En caso de requerir las características del producto de forma más
          detallada, no dude en hacérnoslo saber por alguno de los medios de
          contacto que le facilitamos en la página web.
        </p>
        <p></p>

        <h2 className="h3">
          4. Condiciones específicas de la venta de productos
        </h2>

        <h3 className="h3">4.1. PRECIOS</h3>
        <p>
          En la página web, intentaremos determinar el precio habitual que el
          cliente utiliza en sus compras de ese tipo de productos. Por lo que el
          precio final del Pack enviado se ajustará a los criterios de compra
          habitual del cliente.
        </p>
        <p>
          El precio de los productos, estará establecido por cada proveedor, y
          el precio de su elección vendrá en la caja que recibirá en su
          domicilio y su elección determinara a cuánto asciende su factura
          final, llevará incluido el IVA.
        </p>
        <p>
          El cliente, como es lógico, y no estando de acuerdo en el
          asesoramiento de nuestro equipo podrá devolver todo el Pack,
          gratuitamente como se indica en el apartado de devoluciones.
        </p>
        <p>
          En el supuesto de que se produzca por cualquier causa un error
          tipográfico, lo solucionaremos actualizando el precio definitivo en la
          confirmación del pedido. Además, dado que el error puede considerarse
          nuestro, le daremos la opción de anular su pedido. En caso de haber
          procedido al cargo en su tarjeta de crédito, se le reembolsarán todas
          las cantidades adeudadas por este concepto.
        </p>
        <p>
          En cumplimiento de la legislación vigente el precio total final
          incluirá todos los gastos que la compra de nuestros productos puede
          conllevar, esto es, incluirán los impuestos y los gastos derivados del
          envío. A pesar de que nuestro sistema prevé que no se cobre nada hasta
          que el consumidor realice la elección definitiva de las prendas.
          Solamente se cobrara como gasto inicial el precio del envío que
          asciende a 4.95 €. La factura final, se realizara dependiendo de la
          elección final en el domicilio del cliente de las prendas que
          definitivamente elija.
        </p>
        <p></p>

        <h3 className="h3">4.2. FORMAS DE PAGO</h3>
        <p>
          <strong>Seguridad Online - Cifrado SSL</strong>
        </p>
        <p>
          Como norma general cabe decirle que todas las áreas relacionadas con
          el proceso de finalización de pedidos y MI CUENTA están aseguradas a
          través del protocolo SSL, que protege ante accesos no autorizados. La
          URL en la línea de direcciones de su navegador que comienza con
          https:// indica que está en un área segura.
        </p>
        <p></p>
        <p>
          Nuestra tienda on line, pone a su disposición las{' '}
          <strong>siguientes formas de pago:</strong>
        </p>
        <p>
          <strong>Tarjeta:</strong>
        </p>
        <p>
          Aceptamos tarjetas de crédito y débito de Visa, Visa Electrón y
          Mastercard. El pago se realizará a través de la pasarela de pago 100%
          segura que nuestra entidad bancaria pone a nuestra disposición con el
          nuevo sistema de tienda virtual. 
        </p>
        <p>
          En caso que no permita efectuar el pago mediante tarjeta de crédito,
          puede ser por distintas causas, compruebe si es alguna de las que le
          detallamos:
        </p>
        <ul>
          <li>La fecha de caducidad de su tarjeta</li>
          <li>
            La introducción de todos los números de su tarjeta, y sin errores
          </li>
          <li>
            Puede que se haya pasado del límite de gasto, esto lo deberá
            comprobar con su banco.
          </li>
        </ul>
        <p>
          Cuando seleccione <strong>CONFIRMAR</strong> en la página de detalles
          de los productos, es posible que se le redirija al sitio web de su
          banco para verificar su pago. Se trata de un paso adicional que ha
          añadido una normativa europea para mejorar la seguridad cuando realiza
          compras online. Por motivos de seguridad, es posible que el banco le
          dé instrucciones para que verifique la compra.
        </p>
        <p>Para verificar el pago con tarjeta:</p>
        <ol>
          <li>
            Sigue las instrucciones que se indican en el sitio web del banco.
          </li>
          <li>
            Uno de los mecanismos de verificación que puede pedirte el banco es
            que uses un código de seguridad único.
          </li>
          <li>
            Comprueba la bandeja de entrada de correo electrónico o el teléfono
            para obtener el código e introdúcelo cuando te lo soliciten.
          </li>
          <li>
            El banco también podría pedirte que verifiques la compra utilizando
            tecnología de reconocimiento facial o huella dactilar, si la tienes
            configurada.
          </li>
        </ol>
        <p>
          Una vez hecho esto, se te redirigirá de nuevo a la página de compra y
          recibirás una confirmación de que tu pedido se ha procesado
          correctamente.
        </p>
        <p>
          <strong>
            La mercantil, en el momento del  envío, realiza una RETENCION de la
            cantidad aproximada que cuesta el envío, que salvaguarda el coste de
            los productos que tiene el envío que se realiza. Si el cliente en 5
            DIAS desde la recepción del PACK no decide que productos se queda
            del PACK, se le cobran la totalidad de los productos que componen el
            PACK enviado, dado que entendemos que el cliente desea quedarse con
            la totalidad de productos que se le han enviado en el PACK.
          </strong>
        </p>
        <p></p>

        <h3 className="h3"> 4.3. POLÍTICA DE ENVÍOS</h3>
        <p>
          Nuestra empresa servirá pedidos solamente en Península y Baleares.
        </p>
        <p>
          El coste de transporte será de 4,95 euros para Península y Baleares.
        </p>
        <p>
          Para recibir su pedido, debe indicarnos una dirección de correo postal
          donde Vd. recibirá nuestro pedido, en caso de que sea un regalo para
          otra persona, entendemos que{' '}
          <strong>
            Vd. tiene autorización para cedernos los datos para el encargo que
            Vd. nos hace
          </strong>
          . No siendo nosotros responsables de dicha cesión de datos.
        </p>
        <p>
          El cliente se obliga a proporcionar a www.dresskids.es, la información
          necesaria del destinatario. Ante cualquier falta o error en la
          información, <a className="lightLink" href="http://www.dresskids.es">www.dresskids.es</a> no
          se hará responsable, y las posibles reexpediciones que por culpa de
          esta causa, y que ello suponga el aumento en los costes de envío serán
          a cargo del cliente.
        </p>
        <p>
          Una vez confirmado el pedido, los datos serán registrados por
          www.dresskids.es, una vez confirmado y validado el pago, se comenzará
          con la elaboración del pedido.
        </p>
        <p>
          Todos nuestros envíos serán servidos en los siguientes 5 días
          laborables máximo, no se tienen en cuenta estos plazos si hay en medio
          un fin de semana o festivos, locales autonómicos y/o nacionales,
          siguientes a la recepción y confirmación de su pedido.
        </p>
        <p>
          En caso de no tener stock en nuestro almacén, se lo haremos saber por
          medio de un email y por lo tanto no podrá computarse el tiempo hasta
          la confirmación del pedido. Los fines de semana y festivos nacionales
          y/o locales del domicilio del cliente no computarán como días de
          entrega.
        </p>
        <p>
          En nuestro caso el transporte se realizará mediante la mercantil SEUR
          y, por lo tanto, se regirán por las normas de transporte que esta
          empresa pone a su disposición en su página web. Esta empresa tiene un
          sistema de seguimiento del pedido y, por lo tanto, puede tener a
          través de su web, seguimiento del envío en cada momento.
        </p>
        <p>
          En caso de existir el pago de impuestos o tasas aduaneras, serán de
          exclusivo coste del cliente.
        </p>
        <p></p>

        <h3 className="h3">4.4. DERECHO DE DESISTIMIENTO</h3>
        <p>
          El derecho de desistimiento en productos es de 14 días naturales desde
          la recepción del pedido, conforme a la Legislación sobre consumidores
          y usuarios. RDL 1/2007 de 1 de abril y sus modificaciones posteriores.
          Antes de este ejercicio le pedimos que compruebe el punto 4.5 que son
          las excepciones a dicho derecho.
        </p>
        <p>
          En caso de solicitar su derecho de desistimiento, y que la empresa le
          haya cobrado alguna cantidad, esta cantidad será reembolsada
          inmediatamente en el numero de cuenta bancario que Vd. nos facilite.
          En caso de que le hubiésemos cobrado por tarjeta de crédito, en la
          tarjeta que nos hubiese solicitado.{' '}
        </p>
        <p></p>

        <h3 className="h3">4.5. EXCEPCIONES AL DERECHO DE DESISTIMIENTO</h3>
        <p>
          El derecho de desistimiento no será aplicable a los contratos que se
          refieran a:
        </p>
        <ol style={{ listStyleType: 'lower-alpha' }}>
          <li>
            La prestación de servicios, una vez que el servicio haya sido
            completamente ejecutado, cuando la ejecución haya comenzado, con
            previo consentimiento expreso del consumidor y usuario y con el
            reconocimiento por su parte de que es consciente de que, una vez que
            el contrato haya sido completamente ejecutado por el empresario,
            habrá perdido su derecho de desistimiento.
          </li>
          <li>
            El suministro de bienes o la prestación de servicios cuyo precio
            dependa de fluctuaciones del mercado financiero que el empresario no
            pueda controlar y que puedan producirse durante el periodo de
            desistimiento.
          </li>
          <li>
            <strong>
              El suministro de bienes confeccionados conforme a las
              especificaciones del consumidor y usuario o claramente
              personalizados
            </strong>
            .
          </li>
          <li>
            El suministro de bienes que puedan deteriorarse o caducar con
            rapidez.
          </li>
          <li>
            El suministro de bienes precintados que no sean aptos para ser
            devueltos por razones de protección de la salud o de higiene y que
            hayan sido desprecintados tras la entrega.
          </li>
          <li>
            El suministro de bienes que después de su entrega y teniendo en
            cuenta su naturaleza se hayan mezclado de forma indisociable con
            otros bienes.
          </li>
          <li>
            El suministro de bebidas alcohólicas cuyo precio haya sido acordado
            en el momento de celebrar el contrato de venta y que no puedan ser
            entregadas antes de 30 días, y cuyo valor real dependa de
            fluctuaciones del mercado que el empresario no pueda controlar.
          </li>
          <li>
            Los contratos en los que el consumidor y usuario haya solicitado
            específicamente al empresario que le visite para efectuar
            operaciones de reparación o mantenimiento urgente; si, en esa
            visita, el empresario presta servicios adicionales a los solicitados
            específicamente por el consumidor o suministra bienes distintos de
            las piezas de recambio utilizadas necesariamente para efectuar las
            operaciones de mantenimiento o reparación, el derecho de
            desistimiento debe aplicarse a dichos servicios o bienes
            adicionales.
          </li>
          <li>
            El suministro de grabaciones sonoras o de vídeo precintadas o de
            programas informáticos precintados que hayan sido desprecintados por
            el consumidor y usuario después de la entrega.
          </li>
          <li>
            El suministro de prensa diaria, publicaciones periódicas o revistas,
            con la excepción de los contratos de suscripción para el suministro
            de tales publicaciones.
          </li>
          <li>Los contratos celebrados mediante subastas públicas.</li>
          <li>
            El suministro de servicios de alojamiento para fines distintos del
            de servir de vivienda, transporte de bienes, alquiler de vehículos,
            comida o servicios relacionados con actividades de esparcimiento, si
            los contratos prevén una fecha o un periodo de ejecución
            específicos.
          </li>
          <li>
            El suministro de contenido digital que no se preste en un soporte
            material cuando la ejecución haya comenzado con el previo
            consentimiento expreso del consumidor y usuario con el conocimiento
            por su parte de que en consecuencia pierde su derecho de
            desistimiento.
          </li>
        </ol>
        <p></p>

        <h3 className="h3">4.6. DEVOLUCIONES</h3>
        <p>
          El usuario podrá devolver a{' '}
          <a className="lightLink" href="http://www.dresskids.es">www.dresskids.es</a> cualquier
          producto, sin que ello suponga coste de ningún tipo. El usuario
          dispondrá de un plazo de 5 días desde la recepción del producto para
          proceder al checkout. Una vez realizado el checkout el cliente entrega
          las prendas a devolver en una bolsa debidamente precintada, en uno de
          los puntos de recogida disponibles por la empresa de logística que
          presta servicio a Dresskids (Dresskids proporcionará esta bolsa para
          mayor comodidad del cliente).  Una vez recepcionada la devolución en
          Dresskids, disponemos de 5 días para comprobar el estado de las mismas
          (no haya sido usado, mantenga las etiquetas originales, sin manchas ni
          defectos, en el embalaje original).
        </p>
        <p>
          Pasado el plazo de los 5 días, si no se ha realizado el checkout, se
          procederá al cobro del total del pedido enviado. En caso de no querer
          que el producto lo recoja nuestro transportista, el coste del mismo
          será asumido por el cliente.
        </p>
        <p></p>

        <h3 className="h3">4.7. CAMBIOS</h3>
        <p>
          El Usuario podrá devolver a{' '}
          <a className="lightLink" href="http://www.dresskids.es">www.dresskids.es</a> cualquier
          producto que presente un defecto de fabricación, o solicitar el cambio
          de un producto que no se ajuste a su talla o su gusto, sin que ello le
          suponga coste de tipo alguno.
        </p>
        <p>
          En caso de que el producto resulte ser defectuoso, tiene conforme al
          RDL 1/2007, el Usuario dispondrá del plazo de 60 días para comunicar a{' '}
          <a className="lightLink" href="http://www.dresskids.es">www.dresskids.es</a> su falta de
          conformidad con el producto, indicando el modelo o nombre del mismo,
          una fotografía y una relación de los defectos encontrados. Pasado el
          citado plazo, no serán asumidos{' '}
          <a className="lightLink" href="http://www.dresskids.es">www.dresskids.es</a> los daños y
          perjuicios reclamados.
        </p>
        <p>
          DressKids no admite cambios de prendas. El cliente puede devolver las
          prendas de manera gratuita y en el siguiente pedido el cliente puede
          indicar en el campo observaciones si quiere que le volvamos a enviar
          una misma prenda en distinta talla. En caso que esté disponible,
          DressKids incluirá en el próximo pedido dicha prenda.
        </p>
        <p>
          La citada devolución deberá efectuarse con todas las etiquetas,
          embalajes, documentación y accesorios originales, reservándose en caso
          contrario <a className="lightLink" href="http://www.dresskids.es">www.dresskids.es</a> la
          devolución.
        </p>
        <p>
          Una vez recibido el producto y constatados los defectos{' '}
          <a className="lightLink" href="http://www.dresskids.es">www.dresskids.es</a>, procederá a la
          sustitución del mismo por otro de idénticas características, sin coste
          alguno para el Cliente o bien por un cupón de descuento a aplicar en
          la siguiente compra. En el caso de cambio por motivo de talla, se
          procederá a la sustitución del producto por otro de las mismas
          características, pero de la nueva talla indicada por el cliente.
        </p>
        <p>
          En caso de no querer que el producto lo recoja nuestro transportista,
          el coste del mismo será asumido por el cliente.
        </p>
        <p>
          Sólo asumimos los portes del primer cambio; si se quiere volver a
          cambiar el producto, el pago de todos los portes será por cuenta del
          cliente.
        </p>
        <p>
          En el caso de productos enviados a Canarias{' '}
          <a className="lightLink" href="http://www.dresskids.es">www.dresskids.es</a> asume los
          portes de la primera devolución por producto defectuoso ó cambio por
          talla desde el cliente a nuestra dirección, y el cliente asumirá los
          costes de vuelta a su dirección en Canarias. Si se quiere volver a
          cambiar el producto, el pago de todos los portes será por cuenta del
          cliente.
        </p>
        <p></p>

        <h3 className="h3">4.8. DISPONIBILIDAD</h3>
        <p>
          En principio, y como criterio general contamos con el 90 % del Stock
          de productos que se ofrecen a través de la tienda online. En todo
          caso, si no contásemos en ese momento con productos ofertados en
          nuestros almacenes, se lo haremos saber inmediatamente a través de un
          correo electrónico.
        </p>
        <p>
          Si ocasionalmente se produjese algún contratiempo con respecto a la
          disponibilidad del producto elegido, le daremos la alternativa de
          cancelar el producto, o bien elegir uno diferente.
        </p>
        <p>
          Si hemos procedido al cargo en su tarjeta de crédito se le
          reembolsarán todas las cantidades adeudadas por este concepto,
          evidentemente sin coste alguno para Vd.
        </p>
        <p></p>

        <h2 className="h3">
          5. Plataforma Europea para la resolución de conflictos
        </h2>
        <p>
          En cumplimiento del artículo 14. 1 del Reglamento UE 524/2013 se
          informa a los clientes de nuestra página web que la Unión Europea ha
          puesto a su disposición una plataforma para la resolución de los
          conflictos en línea que pueda surgir entre ambas partes.
        </p>
        <p>
          El enlace para hacer uso de dicha plataforma es
          http://ec.europa.eu/consumers/odr/ (operativa desde el día 15 de
          febrero de 2016)
        </p>
        <p>
          Los consumidores podrán someter las cuestiones sobre consumo en dicho
          enlace.
        </p>
        <p></p>

        <h2 className="h3">
          6. Impuestos aplicables a las ventas a través de esta web
        </h2>
        <p>
          Nuestra residencia fiscal esta en España y por lo tanto le son
          aplicables aquellos impuestos que el régimen tributario español prevé
          para estos casos. En el precio final del articulo expuesto, consta el
          Impuesto sobre el valor añadido (IVA) implementado, en nuestro caso es
          el 21 %. Para más información{' '}
          <a className="lightLink" href="http://www.aeat.es">www.aeat.es</a>
        </p>
        <p></p>

        <h2 className="h3">
          7. Legislación y Jurisdicción aplicable para casos de conflicto
        </h2>
        <p>
          Las condiciones de esta página web de venta on line, se regirán por la
          legislación española.
        </p>
        <p>
          En caso de conflicto para resolver cuestiones relativas a su compra, a
          UD. como consumidor le corresponde su fuero marcado por la ley,
          normalmente el de su domicilio.
        </p>
        <p></p>

        <h2 className="h3">
          8. Normativa de PAIS VASCO sobre hojas de reclamaciones
        </h2>
        <p>
          Todas las empresas, sean personas físicas o jurídicas, que
          comercialicen bienes o servicios deberán tener a disposición de los
          consumidores, hojas de reclamaciones con arreglo al modelo elaborado
          por la Consejería correspondiente del Gobierno de País Vasco, por el
          que se regulan las hojas de quejas y reclamaciones de las personas
          consumidoras y usuarias en País Vasco y su tramitación administrativa.
        </p>
        <p>
          La existencia de tales hojas de reclamación no excluye que el
          consumidor pueda formular su denuncia por cualquier otro medio
          legalmente previsto.
        </p>
        <p>
          Todos los establecimientos deberán tener en un lugar visible para el
          público un cartel que indique lo siguiente:{' '}
          <strong>
            “EXISTEN HOJAS DE RECLAMACIONES A DISPOSICIÓN DEL CONSUMIDOR O
            USUARIO”.
          </strong>
        </p>
        <p>
          Si la venta o prestación de servicio se realizase a domicilio o fuera
          de un establecimiento comercial permanente, el empresario o
          profesional que preste el servicio deberá llevar las hojas de
          reclamaciones con él. Esto está previsto especialmente para los
          servicios de reparación a domicilio.
        </p>
        <p>
          La entrega de las mismas, a petición del consumidor, es obligatoria y
          gratuita.
        </p>
        <p>
          Solicita por el consumidor, el empresario cubrirá los datos de
          identificación de su empresa: nombre o razón social, domicilio y
          número de identificación fiscal.
        </p>
        <p>
          El denunciante también deberá cumplimentar sus datos de
          identificación: nombre y apellidos, domicilio y DNI o pasaporte,
          además de exponer con claridad los hechos que motivan la denuncia. La
          empresa podrá utilizar la hoja de reclamaciones para hacer las
          alegaciones que considere oportunas a tal denuncia.
        </p>
        <p>
          El consumidor deberá enviar la copia blanca de la hoja de
          reclamaciones a la administración competente en materia de consumo
          (Agencia de Sanidad Ambiental y Consumo o Ayuntamiento
          correspondiente) en el plazo de un mes desde su fecha, acompañando los
          documentos que considere oportunos y prueben su reclamación.
        </p>
        <p>
          La empresa está obligada a guardar las copias rosas de las
          reclamaciones que le hayan formulado durante SEIS MESES.
        </p>
        <p></p>

        <h2 className="h3">
          9. Domicilio de la propiedad de la página web a efecto de
          notificaciones
        </h2>
        <p>Nuestra empresa está ubicada en:</p>
        <p>
          <strong>
            DRESS KIDS STORE S.L.
            <br />
          </strong>
          AVDA. CERVANTES Nº 51 - EDIF 10 - 5ª PLANTA - DPTO 6<br />
          48970 BASAURI (BIZKAIA)
        </p>
        <p></p>

        <h2 className="h3">10. Anexos</h2>
        <p>
          Formulario de Desistimiento (en caso de no ser una excepción del art.
          103 RDL 1/2007)
        </p>
        <p>
          (Complete y reenvíe este formulario únicamente si desea ejercer su
          derecho de desistimiento en relación al contrato.)
        </p>
        <p></p>
        <p>A:</p>
        <p>
          <strong>
            DRESS KIDS STORE S.L.
            <br />
          </strong>
          AVDA. CERVANTES Nº 51 - EDIF 10 - 5ª PLANTA - DPTO 6<br />
          48970 BASAURI (BIZKAIA)
        </p>
        <p></p>
        <p>
          Por la presente le comunico que deseo ejercitar el derecho de
          desistimiento en relación con mi contrato de compraventa FUERA DEL
          ESTABLECIMIENTO MERCANTIL de los siguientes bienes:
        </p>
        <p>Comprados con fecha: ________________________________________</p>
        <p>Número de pedido: ________________________________________</p>
        <p>Nombre del Consumidor: ________________________________________</p>
        <p>Dirección del Consumidor:</p>
        <p>Calle / Nº: _____________________________ ________</p>
        <p>Código Postal / Municipio: ________ ___________________________</p>
        <p>País: ________________________________________</p>
        <p>
          Firma del Consumidor Únicamente si este formulario se notifica en
          papel]
        </p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p>
          Estas condiciones son una primera versión V1.0 y podrán ser cambiadas
          por la empresa, siempre cumpliendo con la legislación sobre
          Consumidores y Usuarios, y otras legislaciones que por su sector
          puedan afectarle.
        </p>
      </div>
      <Footer />
    </>
  );
}
export default CondicionesDeContratacion;
