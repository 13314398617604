import Footer from '../footer';
import Header from '../Header';
import Whatsapp from '../Whatsapp';
import { Link } from 'react-router-dom';
import { PrimaryButton } from '../../ui/atoms/Button.js';
import { Helmet } from 'react-helmet';
import Outfit from '../../ui/atoms/Outfit';

function NuestrosOutfits() {
  return (
    <>
      <Header isHome={false} />
      <Whatsapp />
      <div className="container mt-5 mb-md-5">
        <Helmet>
          <title>
            Outfits para niñas y niños. Mejores conjuntos infantiles | DressKids
          </title>
          <meta
            name="description"
            content="En DressKids hemos creado los mejores outfits para niñas y niños. Conjuntos de todos los estilos: casual, deportivo, moderno ya sea para verano o invierno."
          />
        </Helmet>

        <h1 className="h1 text-center">
          Algunas ideas de outfits infantiles que encontrarás en la caja de
          DressKids
        </h1>
        <div className="mt-5">
          <p className='fs-4 mb-3'>
            Conjuntos de ropa casual, colorida, deportiva… Hay un pack DressKids
            de ropa infantil para cada niño, niña, y momento.
          </p>

          <h2 className="h3">Outfit casual para niños y niñas</h2>
          <p>
            Las conjuntos de ropa casual infantiles más adecuadas para el día a
            día en el colegio; prendas cómodas, de calidad y resistentes sin
            perder ni un ápice de su propia personalidad.
          </p>
          <div className='row mb-5'>
            <div className='col-6 col-md-3 mb-3'>
              <Outfit foto={'casualKidNino'} />
            </div>
            <div className='col-6 col-md-3 mb-3'>
              <Outfit foto={'casualKidNina'} />
            </div>
            <div className='col-6 col-md-3'>
              <Outfit foto={'casualMiniNino'} />
            </div>
            <div className='col-6 col-md-3'>
              <Outfit foto={'casualMiniNina'} />
            </div>
          </div>
        
          <h2 className="h3">Ropa formal para niños y niñas</h2>
          <p>
            Hay momentos en los que toca vestir a los niños y las niñas con ropa
            un poco más formal. Por suerte, gracias al trabajo de nuestro equipo
            de personal shoppers, los conjuntos de ropa formal para niños y
            niñas no tienen por qué ser sinónimo de ropa aburrida, sosa ni
            incómoda.
          </p>
          <div className='row mb-5'>
            <div className='col-6 col-md-3 mb-3'>
              <Outfit foto={'formalKidNino'} />
            </div>
            <div className='col-6 col-md-3 mb-3'>
              <Outfit foto={'formalKidNina'} />
            </div>
            <div className='col-6 col-md-3'>
              <Outfit foto={'formalMiniNino'} />
            </div>
            <div className='col-6 col-md-3'>
              <Outfit foto={'formalMiniNina'} />
            </div>
          </div>
        
          <h2 className="h3">Conjuntos deportivos para niños y niñas</h2>
          <p>
            ¿Buscas un conjunto de deporte para niños y niñas que les permita
            practicar sus deportes preferidos, correr por el parque junto con
            sus amigos o encaramarse a lo alto de un árbol para recuperar un
            balón?
          </p>
          <div className='row mb-5'>
            <div className='col-6 col-md-3 mb-3'>
              <Outfit foto={'sportKidNino'} />
            </div>
            <div className='col-6 col-md-3 mb-3'>
              <Outfit foto={'sportKidNina'} />
            </div>
            <div className='col-6 col-md-3'>
              <Outfit foto={'sportMiniNino'} />
            </div>
            <div className='col-6 col-md-3'>
              <Outfit foto={'sportMiniNina'} />
            </div>
          </div>
        </div>
        <div className='mb-4 d-flex justify-content-center'>
          <Link to="/paso0">
            <PrimaryButton className="mt-3" value="Necesito uno de vuestros packs Dresskids" />
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default NuestrosOutfits;
