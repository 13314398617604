import React from 'react';
import { Link } from 'react-router-dom';
import LogoFooter from '../img/logo-footer.svg';

function Footer() {
  return (
    <div className="footer">
      <div className="container ">
        <div className="row d-flex justify-content-center">
          <div className="col-12 col-md-4 d-flex flex-column align-items-center">
            <img className="logo-footer" src={LogoFooter} alt="" />
            <div className='rrss mt-4'>
              <a
                href="https://www.facebook.com/dresskids.es"
                target="_blank"
                rel="noreferrer"
                className='me-3'
              >
                <i class="fab fa-facebook-f"></i>
              </a>
              <a
                href="https://www.instagram.com/dresskids.es/"
                target="_blank"
                rel="noreferrer"
              >
                <i class="fab fa-instagram"></i>
              </a>
            </div>
          </div>

          <div className="col-12 text-center small mt-4">
            <ul className="list-inline mb-0">
              <li className="list-inline-item">
                <Link to="/aviso-legal">
                  <p className='mb-0'>Aviso Legal</p>
                </Link>
              </li>
              <li className="list-inline-item">
                <Link to="/politica-privacidad">
                  <p className='mb-0'>Política Privacidad</p>
                </Link>
              </li>
              <li className="list-inline-item">
                <Link to="/condiciones-de-contratacion">
                  <p>Condiciones de Contratación</p>
                </Link>
              </li>
              <li className="list-inline-item">
                <Link to="/politica-de-cookies">
                  <p className='mb-0'>Política de Cookies</p>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
