import { useContext, useEffect, useState } from 'react';
import Header from '../Header';
import { useHistory, useParams } from 'react-router-dom';
import { PrimaryButton } from '../../ui/atoms/Button';
import Footer from '../footer';
import './checkout.css';
import AuthContext from '../../contexts/AuthContext';
import { API_URL } from '../../constants';
import Whatsapp from '../Whatsapp';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import MegafonoIcon from '../../img/megafono.svg';
import { Helmet } from 'react-helmet';
import TagManager from 'react-gtm-module';

const preguntas = [
  {
    name: 'Tallas',
    id: '0',
    options: [
      { name: 'Le queda muy grande; es muy holgado.', id: '0' },
      { name: 'Le queda pequeño; le ajusta mucho.', id: '1' },
      { name: 'Le queda bien; es su talla.', id: '2' },
    ],
  },
  {
    name: 'Precio',
    id: '1',
    options: [
      { name: 'Se ajusta a lo esperado', id: '3' },
      { name: 'Me ha parecido muy caro.', id: '4' },
      { name: 'Es más barato de lo que esperaba.', id: '5' },
    ],
  },
  {
    name: 'Calidad',
    id: '2',
    options: [
      { name: 'Me ha gustado la calidad de las prendas', id: '6' },
      { name: ' No me ha parecido una prenda de calidad', id: '7' },
      { name: 'Tiene la calidad que esperaba', id: '8' },
    ],
  },
  {
    name: 'Estilo',
    id: '3',
    options: [
      { name: 'DressKids ha acertado con el estilo', id: '9' },
      { name: 'No es lo que esperaba', id: '10' },
      { name: 'Se ajusta a lo esperado', id: '11' },
    ],
  },
];

const ItemSelect = ({ onClick, value, isActive }) => {
  return (
    <div>
      <div
        className={`${isActive ? 'selectedCheckout' : ''} selectPill`}
        onClick={onClick}
      >
        {value}
      </div>
    </div>
  );
};

/* const Total = ({ prendas, prendasQuedar }) => {
  let totalPrice = 0;
  for (let prenda of prendas) {
    if (prendasQuedar.includes(prenda.packageItemId)) {
      totalPrice = totalPrice + prenda.price;
    }
  }

  if (prendas.length === prendasQuedar.length) {
    totalPrice = totalPrice * 0.75;
    setTotalPricePrendasAceptadas(totalPrice)
  }

  return (
    <>
      {totalPrice != 0 && (
        <p className="fs-5 mt-3 mb-2">
          <span className="fw-bold">Total:</span> {totalPrice.toFixed(2)}€
        </p>
      )}
      {prendas.length === prendasQuedar.length && (
        <p className="">¡Genial! Te aplicamos el 25% de descuento</p>
      )}
    </>
  );
};
 */
const PrendaSelector = ({
  prenda,
  optionSelected,
  opcionPrenda,
  cancelPrendaQuedar,
  cancelPrendaDevolver,
}) => {
  const [keepIt, setKeepIt] = useState(false);
  const [returnIt, setReturnIt] = useState(false);

  const handleKeepItChange = () => {
    if (!keepIt) {
      setKeepIt(true);
      setReturnIt(false);
      opcionPrenda(prenda.packageItemId, true);
    } else {
      setKeepIt(false);
      cancelPrendaQuedar(prenda.packageItemId);
    }
  };

  const handleReturnItChange = () => {
    if (!returnIt) {
      setReturnIt(true);
      setKeepIt(false);
      opcionPrenda(prenda.packageItemId, false);
    } else {
      setReturnIt(false);
      cancelPrendaDevolver(prenda.packageItemId);
    }
  };

  return (
    <div
      key={prenda.packageItemId}
      className="col-12 col-sm-6 col-md-4 col-lg mb-4"
    >
      <div className="checkout-box">
        <p className="fontSecondary fw-bold">{prenda.description}</p>
        <p>Precio: {prenda.price.toFixed(2)}€</p>
        <p>Ref.: {prenda.ean}</p>
        <ItemSelect
          id={prenda.packageItemId}
          price={prenda.price}
          onClick={handleKeepItChange}
          value="¡Me lo quedo!"
          isActive={keepIt}
        />
        <ItemSelect
          id={prenda.packageItemId}
          onClick={handleReturnItChange}
          value="Devolver"
          isActive={returnIt}
        />
        {returnIt && (
          <div
            class="accordion accordion-flush w-100 mt-3"
            id="accordionFlushExample"
          >
            {preguntas &&
              preguntas.map((pregunta) => {
                return (
                  <Questions
                    key={pregunta['@id']}
                    pregunta={pregunta}
                    optionSelected={optionSelected}
                    prendaId={prenda.packageItemId}
                    price={prenda.price}
                  />
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

const Questions = ({ pregunta, optionSelected, prendaId }) => {
  const [isActive, setisActive] = useState();
  const [comment, setComment] = useState('');

    console.log(isActive, "isActive")
  const handleCommentChange = (e) => {
    setComment(e.target.value);
    optionSelected(prendaId, pregunta.id, isActive, e.target.value);
  };

  const handleOptionSelected = (optionId) => {
    setisActive(optionId);
    optionSelected(prendaId, pregunta.id, optionId, comment);
  };

  return (
    <div className="accordion-item checkout w-100">
      <h2
        className="accordion-header"
        id={`flush-headingOne-${pregunta.id}-${prendaId}`}
      >
        <button
          className="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#flush-collapse-${pregunta.id}-${prendaId}`}
          aria-expanded="false"
          aria-controls={`flush-collapse-${pregunta.id}-${prendaId}`}
        >
          <span>{pregunta.name}</span>
        </button>
      </h2>

      <div
        id={`flush-collapse-${pregunta.id}-${prendaId}`}
        className="accordion-collapse collapse"
        aria-labelledby={`flush-headingOne-${pregunta.id}-${prendaId}`}
      >
        <div className="row">
          <div className="accordion-body">
            {pregunta.options &&
              pregunta.options.map((option, i) => {
                return (
                  <div key={i}>
                    <div
                      className={`mb-1 option ${
                        isActive === option.id && 'optionActive'
                      }`}
                      prueba={option.id}
                      isActive={isActive === option.id}
                      onClick={() => {
                        handleOptionSelected(option.id);
                      }}
                    >
                      {option.name}
                    </div>
                  </div>
                );
              })}
            <div className="form mt-2">
              <textarea
                className="form-control"
                placeholder="Añade un comentario"
                id="comentario"
                value={comment}
                onChange={handleCommentChange}
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const acceptPackageItemById = async (prendaId, quantity, token, comment) => {
  let body;
  if (quantity >= 1) {
    body = { quantityAccepted: 1, status: 'accepted' };
  } else if (quantity === 0) {
    body = { quantityAccepted: 0, status: 'rejected', reasons: comment };
  }
  await fetch(`${API_URL}/package_items/${prendaId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
};

function Checkout() {
  const [auth] = useContext(AuthContext);
  const [isActive] = useState('');
  const [prendas, setPrendas] = useState([]);
  const [prendasQuedar, setPrendasQuedar] = useState([]);
  const [prendasDevolver, setPrendasDevolver] = useState([]);
  const [opcionesDevolver, setOpcionesDevolver] = useState([]);
  const [totalPricePrendasPropuestas, setTotalPricePrendasPropuestas] =
    useState();
  const [totalPricePrendasAceptadas, setTotalPricePrendasAceptadas] =
    useState();
  const [shipingcost, setShipingcost] = useState(0);

  const MySwal = withReactContent(Swal);
  const { packageId } = useParams();
  const history = useHistory();

  const valid =
    prendas.length === prendasDevolver.length + prendasQuedar.length &&
    opcionesDevolver.length === prendasDevolver.length * preguntas.length;

  const Total = ({ prendas, prendasQuedar }) => {
    let totalPrice = 0;
    for (let prenda of prendas) {
      if (prendasQuedar.includes(prenda.packageItemId)) {
        totalPrice = totalPrice + prenda.price;
      }
    }

    if (prendas.length === prendasQuedar.length) {
      totalPrice = totalPrice * 0.75;
    }
    setTotalPricePrendasAceptadas(totalPrice);

    return (
      <>
        {totalPrice != 0 && (
          <p className="fs-5 mt-3 mb-2">
            <span className="fw-bold">Total:</span> {totalPrice.toFixed(2)}€
          </p>
        )}
        {prendas.length === prendasQuedar.length && (
          <p className="">¡Genial! Te aplicamos el 25% de descuento</p>
        )}
      </>
    );
  };

  useEffect(() => {
    let isActive = true;
    const getPedidoData = async () => {
      const pedidosResponse = await fetch(`${API_URL}/packages/${packageId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });

      const pedidosData = await pedidosResponse.json();
      console.log(pedidosData, 'pedidosData');
      const items = pedidosData.packageItems.map((pi) => ({
        ...pi.item,
        packageItemId: pi['@id'].substring('/api/package_items/'.length),
        price: pi.price,
      }));

      let precio = [];
      items &&
        items.length != 0 &&
        items.map((prenda, index) => {
          precio.push(prenda.price);
        });
      let total = precio && precio.reduce((a, b) => a + b, 0);

      if (isActive) {
        setPrendas(items);
        setShipingcost(pedidosData.shippingCosts);
        setTotalPricePrendasPropuestas(total);
      }
    };

    getPedidoData();

    return () => {
      isActive = false;
    };
  }, [auth.token, packageId]);

  const opcionPrenda = (prendaId, keep, price) => {
    if (keep) {
      setPrendasQuedar((prev) => [...prev, prendaId]);
      setPrendasDevolver((prev) => prev.filter((p) => p !== prendaId));
      setOpcionesDevolver((prev) =>
        prev.filter((o) => o.prendaId !== prendaId)
      );
    } else {
      setPrendasDevolver((prev) => [...prev, prendaId]);
      setPrendasQuedar((prev) => prev.filter((p) => p !== prendaId));
    }
  };

  const cancelPrendaQuedar = (prendaId) => {
    setPrendasQuedar((prev) => prev.filter((p) => p !== prendaId));
  };

  const cancelPrendaDevolver = (prendaId) => {
    setPrendasDevolver((prev) => prev.filter((p) => p !== prendaId));
    setOpcionesDevolver((prev) => prev.filter((o) => o.prendaId !== prendaId));
  };

  const optionSelected = (prendaId, preguntaId, optionId, comment) => {
    setOpcionesDevolver((prev) => {
      let temp = prev.filter(
        (o) => o.prendaId !== prendaId || o.preguntaId !== preguntaId
      );
      temp = [...temp, { prendaId, preguntaId, optionId, comment }];
      return temp;
    });
  };

  const tagManagerArgs = {
    gtmId: 'GTM-PJC7ZVW',
    dataLayer: {
      event: 'purchase',
      ecommerce: {
        transaction_id: packageId,
        value: totalPricePrendasPropuestas,
        shipping: shipingcost,
        currency: 'EUR',
        coupon: prendas.length,
        items: [
          {
            item_name: 'Productos Aceptados',
            item_id: packageId, //es un array
            price: totalPricePrendasAceptadas,
            quantity: prendasQuedar.length,
          },
        ],
      },
    },
    dataLayerName: 'dataLayer',
  };
  /* TagManager.dataLayer(tagManagerArgs); */

  return (
    <div>
      <Helmet>
        <title>Checkout - Dresskids</title>
      </Helmet>
      <Header isHome={false} />
      <Whatsapp />
      <div className="container pt-5 pb-5 ">
        <h1 className="h3">Check out</h1>
        <p className="mb-0">
          Indícanos con qué prendas decides quedarte y cuáles quieres devolver,
          y selecciona las opciones correspondientes de los desplegables.{' '}
        </p>
        <p className="fw-bold">
          Si en un plazo de 5 días no se realiza el check out, se procederá al
          cobro completo del pack.
        </p>
        <div className="d-flex justify-content-center mt-5 ">
          <img src={MegafonoIcon} alt="megáfono" />
        </div>
        <p className="fw-bold fs-4 fontSecondary text-center">
          Si te quedas con todas las prendas <br></br>
          <span className="fs-2 h1">¡Te descontamos un 25% del total</span>!
        </p>

        <div className="row mt-5">
          {prendas &&
            prendas.map((prenda, index) => (
              <PrendaSelector
                prenda={prenda}
                optionSelected={optionSelected}
                opcionPrenda={opcionPrenda}
                cancelPrendaQuedar={cancelPrendaQuedar}
                cancelPrendaDevolver={cancelPrendaDevolver}
                price={prenda.price}
              />
            ))}
          <div className="text-center">
            <Total prendas={prendas} prendasQuedar={prendasQuedar} />
            {!valid && (
              <>
              <small>Debes completar todos los datos de cada prenda</small>
                {/* <small className="text-center mt-3">
                  Debes indicar qué prendas te quedas y cuales devueles.{' '}
                </small><br></br>
                <small>En las prendas que devuelves, marca la opción correspondiente de cada desplegable para ayudarnos a mejorar tus futuras compras.</small> */}
              </>
            )}
          </div>
          <div className="col-12 mt-3 d-flex justify-content-center">
            <PrimaryButton
              value="Finalizar"
              disabled={!valid}
              onClick={async () => {
                await MySwal.fire({
                  html: 'Realizando checkout...',
                  icon: 'info',
                  didOpen: async () => {
                    MySwal.showLoading();
                    let selecciones = {};
                    for (let opcion of opcionesDevolver) {
                      for (const pregunta of preguntas) {
                        for (const opcionPregunta of pregunta.options) {
                          if (opcion.optionId === opcionPregunta.id) {
                            let texto = `${pregunta.name}: ${opcionPregunta.name} Comentario: ${opcion.comment}`;
                            if (!selecciones[opcion.prendaId]) {
                              selecciones[opcion.prendaId] = texto;
                            } else {
                              selecciones[opcion.prendaId] += '  ||  ' + texto;
                            }
                          }
                        }
                      }
                    }
                    for (let prendaId of prendasQuedar) {
                      await acceptPackageItemById(prendaId, 1, auth.token);
                    }
                    for (let prendaId of prendasDevolver) {
                      await acceptPackageItemById(
                        prendaId,
                        0,
                        auth.token,
                        selecciones[prendaId]
                      );
                    }
                    MySwal.close();
                  },
                });
                TagManager.dataLayer(tagManagerArgs);
                history.push(`/checkout-finalizado/${packageId}`);
              }}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Checkout;
