import Footer from '../footer';
import Header from '../Header';
import Whatsapp from '../Whatsapp';

function HowItWorks() {
  return (
    <>
      <Header isHome= {false} />
      <Whatsapp />
      <div className="container mt-5 mb-md-5">
        <h1 className="h1 mb-4 text-center">Cómo funciona</h1>
        <p className="h3">1. Háblanos de tu peque</p>
        <p>
          Rellena el breve cuestionario que te facilitamos. En Solo 6 pasos
          conoceremos vuestros gustos y necesidades. Cuanta más información
          aportes mejor será la elección de ropa que recibiréis en casa.
        </p>

        <p className="h3 mt-5">2. Elige tu pack</p>
        <p>
          Para adaptarnos a vuestras necesidades, tienes disponible dos packs
          diferentes:{' '}
        </p>
        <ul>
          <li>Pack DressKids: Incluye 5 prendas combinables entre sí. </li>
          <li>
            Pack Personalizado: Como sabemos que hay peques que necesitas más
            unas prendas que otras, puedes seleccionar las 5 prendas que más se
            ajusten a vuestras necesidades.{' '}
          </li>
        </ul>
        <p>
          <b>
            Precio del pack: Cada pack está adaptado a tu presupuesto habitual e
            indicado en el formulario. No son packs cerrados, cada niñx tiene un
            pack exclusivo que es elegido por su Personal Shopper en función de
            las necesidades y estará ajustado a vuestra compra habitual de ropa.
          </b>
        </p>
        <p>
          Para enviarte el pack solo pagas 4,95€ por los gastos de envío. Las
          prendas no se pagan por adelantado y no tienes compromiso ninguno de
          compra.
        </p>

        <p className="h3 mt-5">3. Recíbelo en casa</p>
        <p>
          Recibirás el Pack en tu domicilio. Dispones de 5 días para probar las
          prendas cómodamente y sin prisas. Elige el momento con tu peque que
          mejor os convenga. El servicio de asesoramiento es gratuito. Las
          prendas no tienen recargo de precio, costando lo mismo que en las
          tiendas (1).
        </p>

        <p className="h3 mt-5">4. Completa el CheckOut </p>
        <p>
          Desde que lo recibes, tienes 5 días para que nos digas que prendas te
          quedas. Para ello, te habilitamos un proceso de confirmación de
          compra: CheckOut. Cuando lo completes, te cobraremos solo las prendas
          que has decidido quedarte y te mandamos las instrucciones para que
          devuelvas gratuitamente las que no quieras.{' '}
          <b>Si decides quedarte todo, tendrás un 25% de descuento.</b>
        </p>

        <p className="h3 mt-5">5. Devuelve lo que no quieras</p>
        <p>
          Devuelve gratuitamente las prendas que no os quedéis. Para ello, te
          facilitamos una bolsa donde deberás introducir las prendas y
          depositarlo en el punto PickUp que selecciones. Encontrarás el listado
          durante el proceso o en el siguiente enlace:
          <a href="https://www.seur.com/busqueda-tiendas.do" target="_blank"> www.seur.com</a>
        </p>

        <small>(1) Precio recomendado por el proveedor</small>
      </div>
      <Footer />
    </>
  );
}
export default HowItWorks;
