import { API_URL } from '../constants';

export const getEndpointItemsWithPagination = async (endpoint) => {
  let currentPage = 1;
  let items = [];
  let last = 0;
  do {
    const response = await fetch(`${API_URL}/${endpoint}?page=${currentPage}`);
    const responseJson = await response.json();
    items = [...items, ...responseJson['hydra:member']];
    if (responseJson['hydra:view']) {
      last = Number(
        responseJson['hydra:view']['hydra:last'].substring(
          `/api/${endpoint}?page=`.length
        )
      );
      currentPage++;
    }
  } while (currentPage <= last);
  return items;
};
