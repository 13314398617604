import Footer from '../footer';
import Header from '../Header';
import Whatsapp from '../Whatsapp';

function AvisoLegal() {
  return (
    <>
      <Header isHome={false} />
      <Whatsapp />
      <div className="container mt-5 mb-md-5">
        <h1 className="h1">Aviso legal</h1>
        <h2 className="h3">1. TITULAR DEL SITIO WEB</h2>
        <p>
          En cumplimiento del deber de información contemplado en el artículo 10
          de la vigente Ley 34/2002, de 11 de julio, de Servicios de la Sociedad
          de la Información y de Comercio Electrónico, le comunicamos que el
          responsable de la web donde puede descargar diferentes aplicaciones
          es:
        </p>
        <ul>
          <li>Denominación social: DRESS KIDS STORE S.L.</li>
          <li>NIF: B05397534</li>
          <li>
            Domicilio social: AVDA. CERVANTES Nº 51, EDIFICIO 10, 5º, 48970,
            BASAURI, VIZCAYA
          </li>
          <li>Teléfono contacto: 944.005.005</li>
          <li>Dirección de correo: info@dresskids.es</li>
        </ul>
        <p>REGISTROS OFICIALES:</p>
        <ul>
          <li>
            Normativa aplicable por la profesión y/o códigos de conducta: No
            existen
          </li>
          <li>
            Sitio web de DRESS KIDS STORE S.L.:{' '}
            <a href="http://www.dresskids.es" className="lightLink">
              www.dresskids.es
            </a>
            .
          </li>
        </ul>
        <p> </p>
        <h2 className="h3">
          2. RESPONSABILIDAD DE LOS USUARIOS POR USO Y CONTENIDO
        </h2>
        <p>
          Tanto el acceso a los websites como el uso que pueda realizarse de la
          información y contenidos incluidos en los mismos, será de exclusiva
          responsabilidad de quien lo realice.
        </p>
        <p>
          Por tanto, el uso que pueda hacerse de la información, imágenes,
          contenidos y/o productos reseñados y accesibles a través del mismo,
          estará supeditada a la legalidad, sea nacional o internacional,
          aplicable, así como a los principios de buena fe y uso lícito por
          parte de los Usuarios, quienes serán enteramente responsables de dicho
          acceso y correcto uso.
        </p>
        <p>
          Los Usuarios estarán obligados a hacer un uso razonable de los
          servicios o contenidos, bajo el principio de buena fe y con respeto a
          la legalidad vigente, a la moral, al orden público, a las buenas
          costumbres, a los derechos de terceros o de la propia DRESS KIDS STORE
          S.L., todo ello según las posibilidades y fines para los que están
          concebidos. DRESS KIDS STORE S.L. no asume responsabilidades, ya sean
          directas o indirectas, por daño emergente o lucro cesante, derivadas
          del mal uso de los servicios o contenidos realizado por los Usuarios o
          terceros.
        </p>
        <h2 className="h3">3. CONCEPTO DE USUARIO</h2>
        <p>
          La utilización de la Web atribuye la condición de Usuario, e implica
          la aceptación plena y sin reservas de todas y cada una de las
          disposiciones incluidas en este Aviso Legal en la versión publicada
          por DRESS KIDS STORE S.L. en el mismo momento en que el Usuario acceda
          a la web. En consecuencia, el Usuario debe leer atentamente el
          presente Aviso Legal en cada una de las ocasiones en que se proponga
          utilizar la Web, ya que puede sufrir modificaciones.
        </p>
        <h2 className="h3">4. IDIOMA OFICIAL DE LA PÁGINA</h2>
        <p>
          El idioma legalmente establecido es el español de España, por lo tanto
          ante cualquier traducción de este aviso legal a cualquier otro idioma,
          se entenderán como vinculantes tanto en el Aviso Legal como en las
          condiciones del servicio, las cláusulas originales en español.
        </p>
        <h2 className="h3">5. REDES SOCIALES</h2>
        <p>
          DRESS KIDS STORE S.L. tiene o puede tener algún perfil abierto en las
          principales redes sociales. En todas las redes en las que esté, se
          reconoce como responsable del tratamiento de los datos que de sus
          usuarios y/o seguidores que se hayan incluido en el perfil de la
          empresa.
        </p>
        <p>
          El tratamiento que DRESS KIDS STORE S.L. llevará a cabo con dichos
          datos será el que la red social permita a los perfiles empresariales
          dentro de sus políticas como red social. Así pues, DRESS KIDS STORE
          S.L. podrá informar a sus seguidores por cualquier vía que la red
          social permita sobre sus actividades, nuevos productos, actos y
          eventos, así como ofertas de servicio personalizado de atención al
          cliente. DRESS KIDS STORE S.L. no extraerá datos de las redes
          sociales, a menos que se obtuviera puntual y expresamente el
          consentimiento del usuario para ello. El usuario acepta las
          condiciones que las plataformas de redes sociales ponen en sus
          políticas d privacidad.
        </p>
        <h2 className="h3">6. INFORMACIÓN SOBRE LOS LINKS</h2>
        <p>
          DRESS KIDS STORE S.L. no se hace responsable de las webs no propias a
          las que se puede acceder mediante vínculos "links" o de cualquier
          contenido puesto a disposición por terceros.
        </p>
        <p>
          Cualquier uso de un vínculo o acceso a una web no propia es realizado
          por voluntad y riesgo exclusivo del usuario y DRESS KIDS STORE S.L. no
          recomienda ni garantiza ninguna información obtenida a través de un
          vínculo ajeno a DRESS KIDS STORE S.L., ni se responsabiliza de ninguna
          pérdida, reclamación o perjuicio derivada del uso o mal uso de un
          vínculo, o de la información obtenida a través de él, incluyendo otros
          vínculos o webs, de la interrupción en el servicio o en el acceso, o
          del intento de usar o usar mal un vínculo, tanto al conectar a la Web
          de DRESS KIDS STORE S.L., como al acceder a la información de otras
          webs desde la Web de DRESS KIDS STORE S.L.
        </p>
        <p> </p>
        <h2 className="h3">7. RENUNCIA Y LIMITACIÓN DE LA RESPONSABILIDAD</h2>
        <p>
          La información y servicios incluidos o disponibles a través de las
          páginas web pueden incluir incorrecciones o errores tipográficos. De
          forma periódica se incorporan cambios a la información contenida.
          DRESS KIDS STORE S.L. puede introducir en cualquier momento mejoras
          y/o cambios en los servicios o contenidos.
        </p>
        <p>
          DRESS KIDS STORE S.L. ha obtenido la información y los materiales
          incluidos en la web de fuentes consideradas como fiables, pero, si
          bien se han tomado las medidas correspondientes para asegurar que la
          información contenida sea correcta, no garantiza que sea exacta y
          actualizada.
        </p>
        <p>
          También se advierte que los contenidos de esta web tienen finalidad
          informativa en cuanto a la calidad, situación, alojamiento, servicios
          y tarifas de DRESSKIDSSTORE S.L.
        </p>
        <h2 className="h3">
          8. INFORMACIÓN SOBRE LA EXENCIÓN DE TODA LA RESPONSABILIDAD DERIVADA
          DE UN FALLO TÉCNICO Y DE CONTENIDO
        </h2>
        <p>
          DRESS KIDS STORE S.L. declina cualquier responsabilidad en caso de que
          existan interrupciones o un mal funcionamiento de los servicios o
          contenidos ofrecidos en Internet, cualquiera que sea su causa.
          Asimismo, DRESS KIDS STORE S.L. no se hace responsable por caídas de
          la red, pérdidas de negocio a consecuencia de dichas caídas,
          suspensiones temporales del fluido eléctrico o cualquier otro tipo de
          daño indirecto que le pueda ser causado a los Usuarios por causas
          ajenas a DRESS KIDS STORE S.L.
        </p>
        <p>
          DRESS KIDS STORE S.L. no declara ni garantiza que los servicios o
          contenidos sean interrumpidos o que estén libres de errores, que los
          defectos sean corregidos, o que el servicio o el servidor que lo pone
          a disposición estén libres de virus u otros componentes nocivos, sin
          perjuicio de que DRESS KIDS STORE S.L. realiza sus mejores esfuerzos
          en evitar este tipo de incidentes. En caso de que el Usuario tomara
          determinadas decisiones o realizara acciones con base a la información
          incluida en cualquiera de los websites, se recomienda la comprobación
          de la información recibida con otras fuentes.
        </p>
        <h2 className="h3">9. PROPIEDAD INDUSTRIAL E INTELECTUAL</h2>
        <p>
          Los contenidos prestados por DRESS KIDS STORE S.L., así como los
          contenidos vertidos en la red a través de sus páginas web, constituyen
          una obra en el sentido de la legislación sobre propiedad intelectual
          por lo que se hallan protegidos por las leyes y convenios
          internacionales aplicables en la materia.
        </p>
        <p>
          Todas las imágenes y diseños que aparecen en las fotografías son
          propiedad de su autor, por lo que queda prohibida la reproducción de
          las mismas, la utilización de las mismas para uso comercial o no
          comercial. Los contenidos, imágenes, formas, opiniones, índices y
          demás expresiones formales que formen parte de las páginas Web, así
          como el software necesario para el funcionamiento y visualización de
          las mismas, constituyen asimismo una obra en el sentido del Derecho de
          Autor y quedan, por lo tanto, protegidas por las convenciones
          internacionales y legislaciones nacionales en materia de Propiedad
          intelectual que resulten aplicables. El incumplimiento de lo señalado
          implica la comisión de graves actos ilícitos y su sanción por la
          legislación civil y penal.
        </p>
        <p>
          Queda prohibida cualquier forma de reproducción, distribución,
          comunicación pública, transformación, puesta a disposición y, en
          general, cualquier otro acto de explotación pública referido tanto a
          las páginas Web como a sus contenidos e información, sin el expreso y
          previo consentimiento y por escrito DRESS KIDS STORE S.L. En
          consecuencia, todos los contenidos que se muestran en los diferentes
          websites y en especial, diseños, textos, gráficos, logos, iconos,
          botones, software, nombres comerciales, marcas, dibujos industriales o
          cualesquiera otros signos susceptibles de utilización industrial y
          comercial están sujetos a derechos de propiedad intelectual e
          industrial de DRESS KIDS STORE S.L. o de terceros titulares de los
          mismos que han autorizado debidamente su inclusión en los diferentes
          websites.
        </p>
        <p>
          Los contenidos, imágenes, formas, opiniones, índices y demás
          expresiones formales que formen parte de las páginas Web, así como el
          software necesario para el funcionamiento y visualización de las
          mismas, constituyen asimismo una obra en el sentido del Derecho de
          Autor y quedan, por lo tanto, protegidas por las convenciones
          internacionales y legislaciones nacionales en materia de Propiedad
          intelectual que resulten aplicables. El incumplimiento de lo señalado
          implica la comisión de graves actos ilícitos y su sanción por la
          legislación civil y penal.
        </p>
        <p>
          Queda prohibido todo acto por virtud del cual los Usuarios de los
          servicios o contenidos puedan explotar o servirse comercialmente,
          directa o indirectamente, en su totalidad o parcialmente, de
          cualquiera de los contenidos, imágenes, formas, índices y demás
          expresiones formales que formen parte de las páginas Web sin permiso
          previo y por escrito de DRESS KIDS STORE S.L.
        </p>
        <p>
          En concreto, y sin carácter exhaustivo, quedan prohibidos los actos de
          reproducción, distribución, exhibición, transmisión, retransmisión,
          emisión en cualquier forma, almacenamiento en soportes físicos o
          lógicos (por ejemplo, disquetes o disco duro de ordenadores),
          digitalización o puesta a disposición desde bases de datos distintas
          de las pertenecientes a las autorizadas por DRESS KIDS STORE S.L., así
          como su traducción, adaptación, arreglo o cualquier otra
          transformación de dichas opiniones, imágenes, formas, índices y demás
          expresiones formales que se pongan a disposición de los Usuarios a
          través de los servicios o contenidos, en tanto tales actos estén
          sometidos a la legislación aplicable en materia de Propiedad
          intelectual, industrial o de protección de la imagen.
        </p>
        <p>
          DRESS KIDS STORE S.L. es libre de limitar el acceso a las páginas web,
          y a los productos y/o servicios en ella ofrecidos, así como la
          consiguiente publicación de las opiniones, observaciones, imágenes o
          comentarios que los usuarios puedan hacerle llegar a través del
          e-mail.
        </p>
        <p>
          DRESS KIDS STORE S.L., en este sentido, podrá establecer, si lo
          considera oportuno, sin perjuicio de la única y exclusiva
          responsabilidad de los Usuarios, los filtros necesarios a fin de
          evitar que a través de sus páginas Web puedan verterse en la red
          contenidos u opiniones, considerados como racistas, xenófobos,
          discriminatorios, pornográficos, difamatorios o que, de cualquier
          modo, fomenten la violencia o la diseminación de contenidos claramente
          ilícitos o nocivos.
        </p>
        <p>
          Aquellos usuarios que envíen a las páginas web de DRESS KIDS STORE
          S.L., a su departamento de sugerencias, observaciones, opiniones o
          comentarios por medio del servicio de correo electrónico, salvo que
          expresen de manera cierta e inconfundible lo contrario, en los casos
          en los que por la naturaleza de los servicios o contenidos ello sea
          posible, se entiende que autorizan a DRESS KIDS STORE S.L. para la
          reproducción, distribución, exhibición, transmisión, retransmisión,
          emisión en cualquier formato, almacenamiento en soportes físicos o
          lógicos (por ejemplo, disquetes o disco duro de ordenadores),
          digitalización, puesta a disposición desde bases de datos
          pertenecientes a DRESS KIDS STORE S.L., traducción, adaptación,
          arreglo o cualquier otra transformación de tales observaciones,
          opiniones o comentarios, por todo el tiempo de protección de derecho
          de autor que esté previsto legalmente. Asimismo, se entiende que esta
          autorización se hace a título gratuito, y que por el solo hecho de
          enviar por e-mail tales observaciones, opiniones o comentarios, los
          usuarios declinan cualquier pretensión remuneratoria por parte de
          DRESS KIDS STORE S.L.
        </p>
        <p>
          De acuerdo con lo señalado en el párrafo anterior DRESS KIDS STORE
          S.L. queda autorizada igualmente para proceder a la modificación o
          alteración de tales observaciones, opiniones o comentarios, a fin de
          adaptarlos a las necesidades de formato editorial de las páginas Web,
          sin que por ello pueda entenderse que existe en absoluto cualquier
          tipo de lesión de cualesquiera de las facultades morales de derecho de
          autor que los Usuarios pudieran ostentar sobre aquéllas.
        </p>
        <p>
          Quedan prohibidos cualesquiera de los recursos técnicos, lógicos o
          tecnológicos por virtud de los cuales un tercero pueda beneficiarse,
          directa o indirectamente, con o sin lucro, de todos y cada uno de los
          contenidos, formas, índices y demás expresiones formales que formen
          parte de las páginas Web, o del esfuerzo llevado a cabo DRESS KIDS
          STORE S.L. para su funcionamiento. En concreto, queda prohibido todo
          link, hyperlink, framing o vínculo similar que pueda establecerse en
          dirección a las páginas Web de DRESS KIDS STORE S.L., sin el
          consentimiento previo, expreso y por escrito de DRESS KIDS STORE S.L.
          Cualquier trasgresión de lo dispuesto en este punto será considerada
          como lesión de los legítimos derechos de Propiedad intelectual de
          DRESS KIDS STORE S.L. sobre las páginas Web y todos los contenidos de
          las mismas.
        </p>
        <p>
          DRESS KIDS STORE S.L. no asumirá responsabilidad alguna ante
          consecuencias derivadas de las conductas y actuaciones antes citadas,
          del mismo modo que no asumirá responsabilidad alguna por los
          contenidos, servicios, productos, etc., de terceros a los que se pueda
          acceder directamente o a través de banners, enlaces, links,
          hyperlinks, framing o vínculos similares desde los websites de DRESS
          KIDS STORE S.L.
        </p>

        <h2 className="h3">10.RESOLUCIÓN DE CONFLICTOS</h2>
        <p>
          Este aviso legal no se archivará individualmente para cada usuario
          sino que permanecerá accesible por medio de Internet en esta página
          web. Es accesible mediante la conexión que cada usuario tenga en su
          sistema de conexión. Los Usuarios que tengan la condición de
          consumidores o usuarios conforme los define la normativa española y
          residan en la Unión Europea, si han tenido un problema con una compra
          realizada a DRESS KIDS STORE S.L. para tratar de llegar a un acuerdo
          extrajudicial pueden acudir a la 
          <a
            className="lightLink"
            href="https://webgate.ec.europa.eu/odr"
          >
             <strong className="lightLink"> Plataforma de Resolución de Litigios en Línea </strong>
          </a>
          creada por la Unión Europea y desarrollada por la Comisión Europea al
          amparo del 
          <a
            className="lightLink"
            href="http://eur-lex.europa.eu/legal-content/ES/TXT/?uri=celex:32013R0524"
          >
             <strong className="lightLink"> Reglamento (UE) 524/2013 </strong>
          </a>
          . Tal y como se recoge también en las “Condiciones Generales de
          contratación” y/o “Uso de la Web”.
        </p>

        <p>
          Siempre que el Usuario no sea consumidor o usuario, y cuando no haya
          una norma que obligue a otra cosa, las partes acuerdan someterse a los
          Juzgados y Tribunales de Bilbao, por ser este el lugar de celebración
          del contrato, con renuncia expresa a cualquier otra jurisdicción que
          pudiera corresponderles.
        </p>
        <p> </p>
        <h2 className="h3">11.LEGISLACIÓN</h2>
        <p>Las leyes que rigen esta página web son las leyes españolas.</p>
        <small>
          Aviso Legal redactado por HONOS ABOGADOS S.L.P. para DRESS KIDS STORE
          S.L. en cumplimiento del RGPD 679/2016 sobre protección de datos de
          carácter personal y la LSSI (Ley de Servicios de la Sociedad de
          Información), 34/2002, además de la legislación sobre Propiedad
          Intelectual. Los contenidos de este aviso legal se encuentran
          registrados en el Registro de Propiedad Intelectual Safe Creative, con
          código de registro 1602176603277. Se prohíbe la reproducción total o
          parcial del mismo.
        </small>
      </div>
      <Footer />
    </>
  );
}
export default AvisoLegal;
