import React, { useContext, useEffect, useState } from 'react';
import { PrimaryButton } from '../ui/atoms/Button';
import { Link } from 'react-router-dom';
import KidInfoContext, {
  kidInfoInitialState,
} from '../contexts/KidInfoContext';
import { getAgeFromBirthdate } from '../services/date-calculator';
import { API_IMAGES_URL } from '../constants';
import AuthContext from '../contexts/AuthContext';
import Whatsapp from '../components/Whatsapp';
import { getEndpointItemsWithPagination } from '../services/api-calls';
import Paso2icon from '../img/paso2-icon.svg';
import { Helmet } from 'react-helmet';

const FilterStyle = ({ id, isActive, image, onClick, value }) => {
  const imageUrl = encodeURI(`${API_IMAGES_URL}/type_clothing/${image}`);

  return (
    <div className="col-12 col-md-4 mb-4 mb-md-0" onClick={() => onClick(id)}>
      <div
        style={{ backgroundImage: `url(${imageUrl})` }}
        className={`photo ${isActive ? 'selected' : ''}`}
      >
        <p className="text-center h4">{value}</p>
      </div>
    </div>
  );
};

function Paso2() {
  const [kidInfo, setKidInfo] = useContext(KidInfoContext);
  const [stylesApi, setStylesApi] = useState([]);
  const [estilo, setEstilo] = useState(kidInfo.typeClothing);
  const [auth] = useContext(AuthContext);
  const [isValidForm, setIsValidForm] = useState(false);
  
  console.log(estilo, "estilo")
  console.log(kidInfo.typeClothing, "kidInfo.typeClothing")

  useEffect(() => {
    setIsValidForm(
      estilo !== kidInfo.typeClothing 
    );
  }, [estilo]);

  useEffect(() => {
    let isActive = true;

    const getImagesData = async () => {
      let images = await getEndpointItemsWithPagination('type_clothings');
      const age = getAgeFromBirthdate(kidInfo.birthday);
      images = images.filter(
        (image) =>
          image.gender['@id'] === `/api/genders/${kidInfo.gender}` &&
          image.minAge <= age &&
          image.maxAge >= age
      );

      const stylesData = images.map((image) => ({
        id: image.id,
        name: image.name,
        image: image.image,
      }));

      for (let styleData of stylesData) {
        if (styleData.id === kidInfo.typeClothing) {
          if (isActive) {
            setEstilo(styleData);
          }
          break;
        }
      }
      if (isActive) {
        setStylesApi(stylesData);
      }
    };

    getImagesData();

    return () => {
      isActive = false;
    };
  }, [kidInfo.birthday, kidInfo.gender, kidInfo.typeClothing]);

  return (
    <>
      <Helmet>
        <title>Qué tipo de ropa quieres? - Dresskids</title>
      </Helmet>
      <div className="container pt-2 pt-md-5 pb-5">
        <Whatsapp />
        <Link to={kidInfo.name ? '/area-usuario' : '/paso1'}>
          <i className="bi bi-arrow-left fs-3"></i>
        </Link>
        {auth.user ? (
          <>
            <div className="d-flex justify-content-center">
              <img src={Paso2icon} alt="Icono niño-niña" />
            </div>
            <h1 className="h1 text-center">¿Qué tal está {kidInfo.name}?</h1>
            <h1 className="h1 text-center">
              ¿Qué tipo de ropa quieres para esta ocasión?
            </h1>
          </>
        ) : (
          <>
            <div className="d-flex justify-content-center">
              <img src={Paso2icon} alt="Icono niño-niña" />
            </div>
            <h1 className="h1 text-center">¿Qué tipo de ropa quieres?</h1>
          </>
        )}

        <div className="row d-flex justify-content-center mt-4">
          <div className="col-12 col-sm-9">
            <div className="row mt-5">
              {stylesApi.map((style) => {
                return (
                  <FilterStyle
                    key={style.id}
                    id={style.id}
                    isActive={estilo === style}
                    image={style.image}
                    onClick={() => setEstilo(style)}
                    value={style.name}
                  />
                );
              })}
            </div>
          </div>
          <div className="col-12 col-md-5 mt-md-5 mt-3 d-flex justify-content-center">
            <Link className="w-100 d-flex justify-content-center" to="/paso3">
              <PrimaryButton
                disabled={!isValidForm}
                onClick={() => {
                  setKidInfo((prev) => ({ ...prev, typeClothing: estilo.id }));
                }}
                value="Continuar"
              />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Paso2;
