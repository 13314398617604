import { useState, useEffect, useContext } from 'react';
import { PrimaryButton } from '../ui/atoms/Button';
import { Link, useHistory } from 'react-router-dom';
import { InputText } from '../ui/atoms/Input';
import { SelectAtom } from '../ui/atoms/Select';
import ResumenPedido from '../components/ResumenPedido';
import AuthContext from '../contexts/AuthContext';
import KidInfoContext from '../contexts/KidInfoContext';
import { API_URL } from '../constants';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Whatsapp from '../components/Whatsapp';
import { getEndpointItemsWithPagination } from '../services/api-calls';
import { saveToLocalStorage } from '../services/local-storage-manager';
import Paso10icon from '../img/paso10-icon.svg';
import Credit1 from '../img/credit/icon-credit-1.png'
import Credit2 from '../img/credit/icon-credit-2.png'
import Credit3 from '../img/credit/icon-credit-3.png'
import { Helmet } from 'react-helmet';

function Paso10() {
  const [auth, setAuth] = useContext(AuthContext);
  const [kidInfo] = useContext(KidInfoContext);
  const [name, setNameValue] = useState(auth?.user?.name);
  const [surname, setSurnameValue] = useState(auth?.user?.surname);
  const [email] = useState(auth?.user?.email);
  const [phone, setPhoneValue] = useState(auth?.user?.phone);
  const [validPhone, setValidPhone] = useState(true);
  const [address, setAddressValue] = useState(auth?.user?.address?.address);
  const [cp, setCpValue] = useState(auth?.user?.address?.postalCode);
  const [validCp, setValidCp] = useState(true);
  const [city, setCityValue] = useState(auth?.user?.address?.city);
  const [country, setCountryValue] = useState(auth?.user?.address?.country);
  const [observaciones, setObservacionesValue] = useState('');
  const [isValidForm, setIsValidForm] = useState(false);
  // const [fieldsWithError, setFieldsWithError] = useState({});
  const [fieldsWithError] = useState({});
  const [provinces, setProvinces] = useState([]);
  const history = useHistory();

  const MySwal = withReactContent(Swal);

  useEffect(() => {
    let isActive = true;

    const getProvincesData = async () => {
      let provincesData = await getEndpointItemsWithPagination('provinces');
      provincesData = provincesData.map((province, index) => ({
        ...province,
        code: '' + index,
      }));
      provincesData.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      if (isActive) {
        setProvinces(provincesData);
      }
    };

    getProvincesData();

    return () => {
      isActive = false;
    };
  }, [setProvinces]);

  const updateNameValue = (field) => {
    setNameValue(field.value);
  };
  const updateSurName = (field) => {
    setSurnameValue(field.value);
  };

  const updatePhone = (field) => {
    // field.value.length < 10 && /^[0-9]*$/.test(field.value)
    // /^\(?(\ d {3})\)?[-]?(\d{3})[-]?(\d{4})$/.test(field.value)
    /(\+34|0034|34)?[ -]*(6|7)[ -]*([0-9][ -]*){8}/.test(field.value) &&
    field.value.length <= 9
      ? setValidPhone(true)
      : setValidPhone(false);
    setPhoneValue(field.value);
  };
  const updateAddress = (field) => {
    setAddressValue(field.value);
  };

  const updateCp = (field) => {
    field.value.length === 5 && /^[0-9]*$/.test(field.value)
      ? setValidCp(true)
      : setValidCp(false);
    setCpValue(field.value);
  };

  const updateCity = (field) => {
    setCityValue(field.value);
  };
  const updateCountry = (e) => {
    setCountryValue(e.target.value);
  };
  const updateObservaciones = (field) => {
    setObservacionesValue(field.value);
  };

  const pedido =
    kidInfo.type === 2
      ? {}
      : { type: 'personalizado', garments: kidInfo.garments };

  useEffect(() => {
    setIsValidForm(
      !(
        !name ||
        !surname ||
        !email ||
        !address ||
        !validCp ||
        !city ||
        !country ||
        !validPhone
      )
    );
  }, [name, surname, email, address, validCp, city, country, validPhone]);

  let date = new Date();
  // const formatDate = (date)=>{
  // let formatted_date = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear()
  //  return formatted_date;
  // }
  const fechaFinal = 'Nov 26 2022 23:59:59 GMT+0100';
  const fechaInicio = 'Nov 21 2022 00:00:00 GMT+0100';

  /* console.log(Date.parse(fechaFinal), "Date.parse(fechaFinal)")
console.log(Date.parse(fechaInicio), "Date.parse(fechaInii)")
console.log(Date.parse(date), "Date.parse(date)")

console.log(Date.parse(date) <= Date.parse(fechaFinal), "Date.parse(date) <= Date.parse(fechaFinal)") */
  return (
    <>
      <Helmet>
        <title>¿A dónde enviamos tu pack? - Dresskids</title>
      </Helmet>

      <div className="container pt-2 pt-md-5 pb-5">
        <Whatsapp />
        <Link to="/paso8">
          <i className="bi bi-arrow-left fs-3"></i>
        </Link>
        <div className="d-flex justify-content-center">
          <img src={Paso10icon} alt="Icono" />
        </div>
        <h1 className="h1 text-center mb-0">¿A dónde enviamos tu pack?</h1>
        <div className="row d-flex justify-content-center mt-5">
          <div className="col-12 col-md-6">
            <ResumenPedido pedido={pedido} show={true} />

            <div className="row">
              <div className="col-12 mb-4">
                {Date.parse(date) <= Date.parse(fechaFinal) &&
                Date.parse(date) >= Date.parse(fechaInicio) ? (
                  <p className="fw-bold text-center">
                    Necesitamos los datos de tu tarjeta para que cuando
                    confirmes lo que te quedas del pack podamos cobrar el
                    importe, te recordamos que las devoluciones son gratuitas.
                  </p>
                ) : (
                  <p>
                    Recuerda que hasta recibir el pack con las prendas y elegir
                    cuales quieres no pagarás nada, pero el envío tiene un coste
                    fijo de 4,95€
                  </p>
                )}
              </div>
              <div className="col-12 mb-4">
                <h3 className="h4">Datos de envío</h3>
              </div>
              <div className="col-md-6 col-12 mb-4">
                <InputText
                  name="name"
                  label="Nombre"
                  onChange={updateNameValue}
                  required={true}
                  value={name}
                />
              </div>
              <div className="col-md-6 col-12 mb-4">
                <InputText
                  name="surname"
                  label="Apellidos"
                  onChange={updateSurName}
                  required={true}
                  value={surname}
                />
              </div>
              <div className="col-md-6 col-12 mb-4">
                <InputText
                  name="email"
                  label="Email"
                  disabled="true"
                  required={true}
                  value={email}
                />
              </div>
              <div className="col-md-6 col-12 mb-4">
                <InputText
                  name="phone"
                  label="Teléfono"
                  onChange={updatePhone}
                  error={
                    fieldsWithError['phone'] ||
                    (!validPhone && {
                      message: 'Teléfono no válido',
                    })
                  }
                  required={true}
                  value={phone}
                />
              </div>

              <div className="col-12 mb-4">
                <hr></hr>
              </div>

              <div className="col-md-6 col-12 mb-4">
                <InputText
                  name="adress"
                  label="Dirección"
                  onChange={updateAddress}
                  required={true}
                  value={address}
                />
              </div>
              <div className="col-md-6 col-12 mb-4">
                <InputText
                  name="cp"
                  label="Código postal"
                  onChange={updateCp}
                  error={
                    fieldsWithError['cp'] ||
                    (!validCp && {
                      message: 'CP no válido',
                    })
                  }
                  required={true}
                  value={cp}
                />
              </div>
              <div className="col-md-6 col-12 mb-4">
                <InputText
                  name="city"
                  label="Localidad"
                  onChange={updateCity}
                  required={true}
                  value={city}
                />
              </div>
              <div className="col-md-6 col-12 mb-4">
                <SelectAtom
                  fullWidth={true}
                  label="Provincia"
                  name="province"
                  onChange={updateCountry}
                  required={true}
                  items={provinces}
                  defaultValue={country}
                  placeholder="Selecciona provincia"
                />
              </div>
              <div className="col-12 mb-4">
                <InputText
                  multiline={true}
                  name="observaciones"
                  label="Observaciones"
                  onChange={updateObservaciones}
                  value={observaciones}
                />
              </div>

              <div className="col-12 mt-3 d-flex justify-content-center">
                <div data-bs-toggle="modal" data-bs-target="#exampleModal">
                  <PrimaryButton
                    disabled={!isValidForm}
                    value="Continuar y pagar"
                  />
                </div>

                <div
                  class="warning modal fade"
                  id="exampleModal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5
                          class="modal-title text-center"
                          id="exampleModalLabel"
                        >
                          <i class="fas fa-info-circle me-2"></i>Importante
                        </h5>
                        {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                      </div>
                      <div class="modal-body">
                        <p className="mb-1">
                          Para continuar con el proceso deberás introducir los
                          datos de tu tarjeta.
                        </p>
                        <p className="fw-bold mb-1">Recuerda:</p>
                        <ul>
                          <li>
                            Solo pagas 4,95€ de gastos de envío (siempre
                            que no haya promoción de envío gratis)
                          </li>
                          <li>
                            Hasta recibir el pack con las prendas y elegir
                            cuales quieres no pagarás nada.
                          </li>
                          <li>No tienes compromiso de compra</li>
                          <li>La devolución es gratuita</li>
                        </ul>
                        <p>
                          Para cumplir la normativa PSD2, tu entidad bancaria
                          puede solicitar una validación adicional para asegurar
                          tu identidad. Cuando realices el pago, revisa las
                          notificaciones de tu móvil o banca online.
                        </p>
                        <ul className='list-inline d-flex justify-content-center'>
                          <li className='list-inline-item'><img src={Credit1} /></li>
                          <li className='list-inline-item'><img src={Credit2} /></li>
                          <li className='list-inline-item'><img src={Credit3} /></li>
                        </ul>
                      </div>
                      <div class="modal-footer">
                        <PrimaryButton
                          value="Continuar"
                          onClick={async () => {
                            let correct = true;
                            let response;
                            await MySwal.fire({
                              html: 'Redirigiendo a la plataforma de pago...',
                              icon: 'info',
                              didOpen: async () => {
                                Swal.showLoading();
                                const userId = auth.user.id;
                                // TODO how to avoid memory leak on this fetch
                                const modifyUserResponse = await fetch(
                                  `${API_URL}/users/${userId}`,
                                  {
                                    method: 'PATCH',
                                    headers: {
                                      'Content-Type': 'application/json',
                                      Authorization: `Bearer ${auth.token}`,
                                    },
                                    body: JSON.stringify({
                                      email: email,
                                      name: name,
                                      surname: surname,
                                      phone: phone,
                                    }),
                                  }
                                );
                                await modifyUserResponse.json();

                                // TODO check response correct const modifyUserJson =

                                // TODO how to aovid mem leak on this fetch
                                const addressResponse = await fetch(
                                  `${API_URL}/address/me`,
                                  {
                                    method: 'GET',
                                    headers: {
                                      'Content-Type': 'application/json',
                                      Authorization: `Bearer ${auth.token}`,
                                    },
                                  }
                                );
                                let userAddress = await addressResponse.json();
                                userAddress = userAddress['hydra:member'][0];
                                let userAddressId = userAddress.id;

                                // const modifyUserResponse =
                                await fetch(
                                  `${API_URL}/addresses/${userAddressId}`,
                                  {
                                    method: 'PATCH',
                                    headers: {
                                      'Content-Type': 'application/json',
                                      Authorization: `Bearer ${auth.token}`,
                                    },
                                    body: JSON.stringify({
                                      address: address,
                                      postalCode: cp,
                                      city: city,
                                      country: country.name,
                                      observations: observaciones,
                                    }),
                                  }
                                );

                                let now = new Date();
                                let expiry = new Date(
                                  now.getTime() + 15 * 60000
                                );
                                setAuth({
                                  user: { ...auth.user, address },
                                  token: auth.token,
                                  expiry,
                                });

                                setAuth((prev) => ({
                                  ...prev,
                                  user: {
                                    ...prev.user,
                                    email,
                                    name,
                                    surname,
                                    phone,
                                    address: {
                                      address,
                                      postalCode: cp,
                                      city,
                                      country,
                                      observations: observaciones,
                                    },
                                  },
                                }));
                                let lsAuth = localStorage.getItem('auth');
                                if (lsAuth) JSON.parse(lsAuth);
                                now = new Date();
                                expiry = new Date(now.getTime() + 15 * 60000);
                                lsAuth = {
                                  token: auth.token,
                                  user: {
                                    ...auth.user,
                                    email,
                                    name,
                                    surname,
                                    phone,
                                    address: {
                                      address,
                                      postalCode: cp,
                                      city,
                                      country,
                                      observations: observaciones,
                                    },
                                  },
                                  expiry,
                                };
                                saveToLocalStorage(lsAuth.user, auth.token);

                                if (!kidInfo.creating) {
                                  const modifyKid = await fetch(
                                    `${API_URL}/kids/${kidInfo.id}`,
                                    {
                                      method: 'PATCH',
                                      headers: {
                                        'Content-Type': 'application/json',
                                        Authorization: `Bearer ${auth.token}`,
                                      },
                                      body: JSON.stringify({
                                        name: kidInfo.name,
                                        birthday: kidInfo.birthday,
                                        eyesColor:
                                          '/api/eyes_colors/' +
                                          kidInfo.eyesColor,
                                        size: '/api/sizes/' + kidInfo.size,
                                        hairColor:
                                          '/api/hair_colors/' +
                                          kidInfo.hairColor,
                                        skinColor:
                                          '/api/skin_colors/' +
                                          kidInfo.skinColor,
                                        // styleClothing:
                                        //   '/api/style_clothing/' + kidInfo.styleClothing,
                                      }),
                                    }
                                  );
                                  const modifyKidJson = await modifyKid.json();
                                  console.log(modifyKidJson);
                                }

                                // TODO continue here with package
                                // create size object for package body
                                const sizeObject = {};
                                let sizesIndex = 0;
                                for (let garment in kidInfo.sizes) {
                                  /* let [minSize, maxSize] =
                                    kidInfo.sizes[garment].split('-'); */
                                  /*                                   minSize = Number(minSize);
                                  maxSize = Number(maxSize); */
                                  sizeObject[sizesIndex] = {
                                    garment: garment,
                                    data: kidInfo.sizes[garment],
                                  };
                                  sizesIndex++;
                                }

                                // create price object for package body
                                const priceObject = {};
                                let pricesIndex = 0;
                                for (let garmentValue in kidInfo.garmentsValues) {
                                  const price =
                                    kidInfo.garmentsValues[garmentValue].price;
                                  const garment =
                                    kidInfo.garmentsValues[garmentValue].name;
                                  priceObject[pricesIndex] = {
                                    garment,
                                    price,
                                  };
                                  pricesIndex++;
                                }

                                const createPackage = await fetch(
                                  `${API_URL}/packages`,
                                  {
                                    method: 'POST',
                                    headers: {
                                      'Content-Type': 'application/json',
                                      Authorization: `Bearer ${auth.token}`,
                                    },
                                    body: JSON.stringify({
                                      kid: `/api/kids/${kidInfo.id}`,
                                      typePackage: `/api/type_packages/${kidInfo.packageType}`,
                                      typeClothing: `/api/type_clothings/${kidInfo.typeClothing}`,
                                      styleClothing: `/api/style_clothings/${kidInfo.styleClothing}`,
                                      colorClothing: `/api/color_clothings/${kidInfo.colorClothing}`,
                                      garmentNotWanted:
                                        kidInfo.garmentNotWanted.join(),
                                      garmentCustom: kidInfo.garments,
                                      address: `/api/addresses/${userAddressId}`,
                                      size: sizeObject,
                                      price: priceObject,
                                      observations: kidInfo.observations,
                                      outlet: kidInfo.outlet,
                                    }),
                                  }
                                );
                                const modifycreatePackage =
                                  await createPackage.json();
                                console.log(
                                  modifycreatePackage,
                                  'modifycreatePackage'
                                );

                                window.location = `${API_URL}/do-purchase/${modifycreatePackage.code}/${auth.user.email}`;

                                // const pay = await fetch(
                                //   `${API_URL}/do-purchase/${modifycreatePackage.code}/${auth.user.email}`,
                                //   {
                                //     method: 'GET',
                                //     headers: {
                                //       'Content-Type': 'application/json',
                                //       Authorization: `Bearer ${auth.token}`,
                                //     },
                                //   }
                                // );
                                // response = await pay.json();
                                // //response.status === "succesful"

                                MySwal.close();
                              },
                            });

                            // if (correct && response.status === "" ) {
                            if (!correct) {
                              // TODO mostrar mensaje de error
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Paso10;
