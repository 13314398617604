import { useContext, useEffect, useState } from 'react';
import TablaPedidos from '../components/area-usuario/tablaPedidos';
import Footer from '../components/footer';
import Header from '../components/Header';
// import { PrimaryButton, SecondaryButton } from "../ui/atoms/button";
import { PrimaryButton, SecondaryButton } from '../ui/atoms/Button';
import AuthContext from '../contexts/AuthContext';
import { InputText } from '../ui/atoms/Input';
import { API_URL, PATH_PAGINA_INICIO } from '../constants';
import { getEndpointItemsWithPagination } from '../services/api-calls';
import UserChildSelector from '../components/UserChildSelector';
import { SelectAtom } from '../ui/atoms/Select';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Link } from 'react-router-dom';
import {
  clearLocalStorage,
  saveToLocalStorage,
} from '../services/local-storage-manager';
import { useHistory } from 'react-router-dom';
import Whatsapp from '../components/Whatsapp';
import KidInfoContext, {
  kidInfoInitialState,
} from '../contexts/KidInfoContext';
import { Helmet } from 'react-helmet';
import AreaIcon from '../img/area-personal.svg';
import { CheckBoxAtom } from '../ui/atoms/Checkbox';

function AreaUsuario() {
  const [auth, setAuth] = useContext(AuthContext);
  const [, setKidInfo] = useContext(KidInfoContext);
  const [name, setName] = useState(auth.user?.name);
  const [surname, setSurname] = useState(auth.user?.surname);
  const [email, setEmail] = useState(auth.user?.email);
  const [validEmail, setValidEmail] = useState(true);
  const [phone, setPhone] = useState(auth.user?.phone);
  const [validPhone, setValidPhone] = useState(true);
  const [address, setAddress] = useState(auth.user?.address?.address);
  const [postalCode, setPostalCode] = useState(auth.user?.address?.postalCode);
  const [validCp, setValidCp] = useState(true);
  const [city, setCity] = useState(auth.user?.address?.city);
  const [country, setCountry] = useState(auth.user?.address?.country);
  const [fieldsWithError] = useState({});
  const [provinces, setProvinces] = useState([]);
  const [newsletter, setNewsletterValue] = useState(auth.user?.newsletter);
  const MySwal = withReactContent(Swal);
  const history = useHistory();

  if (!auth.token) {
    history.push(PATH_PAGINA_INICIO);
  }

  console.log(auth ,"auth")

  useEffect(() => {
    let isActive = true;

    const getProvincesData = async () => {
      let provincesData = await getEndpointItemsWithPagination('provinces');
      provincesData = provincesData.map((province, index) => ({
        ...province,
        code: '' + index,
      }));
      provincesData.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      if (isActive) {
        setProvinces(provincesData);
      }
    };

    getProvincesData();

    return () => {
      isActive = false;
    };
  }, [setProvinces]);

  useEffect(() => {
    if (auth.user?.address) {
      setAddress(auth.user?.address?.address);
      setPostalCode(auth.user?.address?.postalCode);
      setCity(auth.user?.address?.city);
      setCountry(auth.user?.address?.country);
    }
  }, [auth?.user?.address, setAddress, setPostalCode, setCity, setCountry]);

  const changeName = (e) => {
    setName(e.value);
  };
  const changeSurname = (e) => {
    setSurname(e.value);
  };

  const changeEmail = (field) => {
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(field.value)
      ? setValidEmail(true)
      : setValidEmail(false);
    setEmail(field.value);
  };

  const changePhone = (field) => {
    field.value.length < 10 && /^[0-9]*$/.test(field.value)
      ? setValidPhone(true)
      : setValidPhone(false);
    setPhone(field.value);
  };

  const changeAddress = (e) => {
    setAddress(e.value);
  };

  const changePostalCode = (field) => {
    field.value.length === 5 && /^[0-9]*$/.test(field.value)
      ? setValidCp(true)
      : setValidCp(false);
    setPostalCode(field.value);
  };

  const changeCity = (e) => {
    setCity(e.value);
  };
  const changeCountry = (e) => {
    setCountry(e.target.value);
  };
  const acceptNewsletter = (value) => {
    setNewsletterValue(value);
  };

  return (
    <>
      <Helmet>
        <title>Área personal</title>
      </Helmet>
      <Header isHome={false} />
      <Whatsapp />
      <div className="container pt-5 pb-2">
        <div className="d-flex justify-content-center">
          <img src={AreaIcon} alt="Icono lápiz" />
        </div>
        <h1 className="text-center h1 mb-4 mb-lg-0">Área personal</h1>
        <div className="row">
          <h2 className="h3 mt-3">Pedidos</h2>
          <p>
            Finaliza el proceso haciendo el check out cuando hayas recibido tu
            pack. Deberás indicar qué prendas te vas a quedar o si hay alguna
            que quieras devolver.
          </p>
        </div>
      </div>
      <TablaPedidos email={email} />
      <div className="d-flex justify-content-center">
        <Link to="/paso0">
          <PrimaryButton value="Nuevo pedido" />
        </Link>
      </div>

      <div className="container pt-2 pb-5 ">
        <div className="row d-flex justify-content-center mt-4 mt-md-5">
          <h2 className="h3">Mis datos</h2>
          <p>
            Puedes modificar los datos de los perfiles que hayas creado haciendo
            clic en cualquiera de ellos y también puedes añadir uno nuevo
            haciendo clic en el botón de añadir.
          </p>
          <p className="text-center fw-bold mt-md-3">Perfiles</p>
          <UserChildSelector inUserArea={true} />

          <div className="col-12 col-md-8">
            <p className="fw-bold mt-5">Datos de envío</p>
            <div className="row ">
              <div className="col-12 col-md-6 form-floating mb-3">
                <InputText
                  name="nombre"
                  label="Nombre"
                  onChange={changeName}
                  value={name}
                />
              </div>
              <div className="col-12 col-md-6 form-floating mb-3">
                <InputText
                  name="apellidos"
                  label="Apellidos"
                  onChange={changeSurname}
                  value={surname}
                />
              </div>
              <div className="col-12 col-md-6 form-floating mb-3">
                <InputText
                  name="email"
                  label="Email"
                  onChange={changeEmail}
                  value={email}
                  error={
                    fieldsWithError['email'] ||
                    (!validEmail && {
                      message: 'Email no válido',
                    })
                  }
                />
              </div>
              <div className="col-12 col-md-6 form-floating mb-3">
                <InputText
                  name="telefono"
                  label="Teléfono"
                  onChange={changePhone}
                  value={phone}
                  error={
                    fieldsWithError['phone'] ||
                    (!validPhone && {
                      message: 'Teléfono no válido',
                    })
                  }
                />
              </div>

              <div className="col-12 col-md-6 form-floating mb-3 mt-3">
                <InputText
                  name="direccion"
                  label="Dirección"
                  onChange={changeAddress}
                  value={address}
                />
              </div>
              <div className="col-12 col-md-6 form-floating mb-3 mt-3">
                <InputText
                  name="cp"
                  label="Código postal"
                  onChange={changePostalCode}
                  value={postalCode}
                  error={
                    fieldsWithError['cp'] ||
                    (!validCp && {
                      message: 'CP no válido',
                    })
                  }
                />
              </div>
              <div className="col-12 col-md-6 form-floating mb-3">
                <InputText
                  name="localidad"
                  label="Localidad"
                  onChange={changeCity}
                  value={city}
                />
              </div>
              <div className="col-12 col-md-6 form-floating mb-3">
                <SelectAtom
                  fullWidth={true}
                  label="Provincia"
                  name="province"
                  onChange={changeCountry}
                  required={true}
                  items={provinces}
                  defaultValue={country}
                  placeholder="Selecciona provincia"
                />
              </div>
              <div className="col-12 d-flex justify-content-center">
                <PrimaryButton
                  value="Guardar"
                  onClick={() => {
                    MySwal.fire({
                      text: 'Modificando datos...',
                      icon: 'info',
                      showConfirmButton: false,
                      didOpen: async () => {
                        const userId = auth.user?.id;
                        setAuth((prev) => ({
                          ...prev,
                          user: {
                            ...auth.user,
                            email: email,
                            name: name,
                            surname: surname,
                            phone: phone,
                            address: {
                              address: address,
                              postalCode: postalCode,
                              city: city,
                              country: country,
                              observations: '', // TODO obtener estos datos?
                            },
                          },
                        }));

                        let lsAuth = localStorage.getItem('auth');
                        lsAuth = {
                          ...lsAuth,
                          user: {
                            ...auth.user,
                            email: email,
                            name: name,
                            surname: surname,
                            phone: phone,
                            address: {
                              address: address,
                              postalCode: postalCode,
                              city: city,
                              country: country,
                              observations: '', // TODO obtener estos datos?
                            },
                          },
                        };
                        saveToLocalStorage(lsAuth.user, auth.token);

                        // TODO how to avoid mem leak in this fetch
                        // const modifyUserResponse =
                        await fetch(`${API_URL}/users/${userId}`, {
                          method: 'PATCH',
                          headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${auth.token}`,
                          },
                          body: JSON.stringify({
                            email: email,
                            name: name,
                            surname: surname,
                            phone: phone,
                          }),
                        });

                        // const modifyUserResponse =
                        await fetch(
                          `${API_URL}/addresses/${auth.user.address.id}`,
                          {
                            method: 'PATCH',
                            headers: {
                              'Content-Type': 'application/json',
                              Authorization: `Bearer ${auth.token}`,
                            },
                            body: JSON.stringify({
                              address: address,
                              postalCode: postalCode,
                              city: city,
                              country: country.name,
                              observations: '', // TODO obtener estos datos?
                            }),
                          }
                        );

                        MySwal.close();
                      },
                    }).then(() => {
                      MySwal.fire({
                        text: 'Perfil modificado.',
                        icon: 'success',
                      });
                    });
                  }}
                />
              </div>

              <div className="col-12 mb-3 mt-3">
                <hr></hr>
              </div>

              <p className="fw-bold">Cambiar contraseña</p>
              <div className="col-12">
                <p>
                  Puedes cambiar tu contraseña haciendo clic en el siguiente
                  botón.
                </p>
              </div>
              <div className="col-12 mt-3 d-flex justify-content-center">
                <Link to="/cambiar-contrasena" className='link-btn d-flex'>
                  <PrimaryButton value="Cambiar contraseña" />
                </Link>
              </div>

              <div className="col-12 mb-3 mt-3">
                <hr></hr>
              </div>

              <p className="fw-bold">Newsletter</p>
              <div className="col-12">
                <p>
                  Puedes suscribirte o desuscribirte de nuestra newsletter
                  cuando quieras. No olvides guardar los cambios.
                </p>
                <div className="form-check mb-3">
                  <CheckBoxAtom
                    onChange={acceptNewsletter}
                    value={newsletter}
                    checked= {newsletter}
                  />
                  <span>
                    Deseo recibir comunicaciones de Dress Kids Store, S.L.
                  </span>
                </div>
              </div> 
              <div className="col-12 mt-3 d-flex justify-content-center">
                <PrimaryButton
                  value="Guardar cambios"
                  onClick={() => {
                    MySwal.fire({
                      text: 'Modificando datos...',
                      icon: 'info',
                      showConfirmButton: false,
                      didOpen: async () => {
                        const userId = auth.user?.id;

                        await fetch(`${API_URL}/users/${userId}`, {
                          method: 'PATCH',
                          headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${auth.token}`,
                          },
                          body: JSON.stringify({
                            newsletter: newsletter,
                          }),
                        });

                        // const modifyUserResponse =
                                                   await fetch(
                            `${API_URL}/addresses/${auth.user.address.id}`,
                            {
                              method: 'PATCH',
                              headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${auth.token}`,
                              },
                              body: JSON.stringify({
                                address: address,
                                postalCode: postalCode,
                                city: city,
                                country: country.name,
                                observations: '', // TODO obtener estos datos?
                              }),
                            }
                          );

                        MySwal.close();
                      },
                    }).then(() => {
                      MySwal.fire({
                        text: 'Perfil modificado.',
                        icon: 'success',
                      });
                    });
                  }}
                />
              </div>

              <div className="col-12 mb-3 mt-3">
                <hr></hr>
              </div>

              <div className="col-12 mt-3 d-flex justify-content-center">
                <SecondaryButton
                  value="Cerrar sesión"
                  onClick={() => {
                    setAuth({ user: null, token: null });
                    setKidInfo(kidInfoInitialState);
                    clearLocalStorage();
                    history.push(PATH_PAGINA_INICIO);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AreaUsuario;
