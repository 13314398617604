import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../img/logo.svg';
import Login from '../img/login.svg';
import LogoMob from '../img/logo-mob.svg';
import LogoMobHome from '../img/logo-mob-home.svg';
import ChildSelector from './ChildSelector.js';
import UserChildSelector from './UserChildSelector';
import { PATH_PAGINA_INICIO } from '../constants.js';
import AuthContext from '../contexts/AuthContext.js';
import Contacto from '../pages/Contacto';

function Header(isHome) {
  const [auth] = useContext(AuthContext);
  const openNav = () => {
		document.getElementById('myNav').style.width = '100%';    
  };
	const closeNav = () => {
		document.getElementById("myNav").style.width = "0%";
	}
  return (
    <>
    <div className={`header padding d-flex justify-content-between align-items-center ${isHome.isHome && "absolute"}`}>
      <div id="myNav" className="overlay">
				<a href="javascript:void(0)" className="closebtn" onClick={() => {closeNav()}}>&times;</a>
        <div className="overlay-content">
					<Link className="logo-home mb-4" to="/">
						<img src={Logo} alt="Logo Dresskids" />
					</Link>
          <Link to="/quienes-somos">Quiénes somos</Link>
          <Link to="/como-funciona">Cómo funciona</Link>
          <Link to="/nuestros-outfits">Nuestros outfits</Link>
          <a href="https://blog.dresskids.es/">Blog</a>
          <Link to='/contacto'>Contacto</Link>
        </div>
			</div>

      <div onClick={() => {openNav()}} >
        <i className="fas fa-bars fs-3 gray"></i>
      </div>
      
      {isHome.isHome === false ? (
        <Link to={PATH_PAGINA_INICIO} className='logo-mob'>
          <img src={Logo} alt="Logo Dresskids"/>
        </Link>
      ):(
        <Link to={PATH_PAGINA_INICIO} className="logo-mob d-block d-md-none">
          <img src={LogoMobHome} alt="Logo Dresskids" />
        </Link>
      )}

      {auth.user ? (
        <Link to="/area-usuario">
          <i class="fas fa-user"></i>
        </Link>
      ) : (
        <Link to="/login">
          <i class="fas fa-user"></i>
        </Link>
      )}
      
    </div>
    
    </>
  );
}

export default Header;
