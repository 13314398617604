import { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AuthContext from '../contexts/AuthContext';
import KidInfoContext from '../contexts/KidInfoContext';

function ChildSelector() {
  const [auth] = useContext(AuthContext);
  const [, setKidInfo] = useContext(KidInfoContext);
  const [isActive, setisActive] = useState();
  const location = useLocation();
  const history = useHistory();

  const buttons = [
    {
      id: 2,
      sex: 'niña',
    },
    {
      id: 1,
      sex: 'niño',
    },
  ];

  const Selector = ({ id, onClick, sex, isActive }) => {
    const onHandleOnClick = (id) => {
      onClick(id);
      setKidInfo((prev) => ({ ...prev, gender: id, creating: true }));
    };

    return (
      <div>
        <div
          onClick={() => onHandleOnClick(id)}
          className={`${sex} ${isActive ? 'selected' : ''} litle `}
        ></div>
        <p className='text-center text-capitalize fw-bold mt-3'>{sex}</p>
      </div>
    );
  };

  return (
    <>
      {buttons &&
        buttons.map((button) => (
          <Selector
            key={button.id}
            id={button.id}
            isActive={isActive === button.id}
            onClick={() => {
              setisActive(button.id);
              if (location.pathname !== '/nuevo-perfil') {
                const newLocation = auth.user ? '/nuevo-perfil' : '/paso1';
                history.push(newLocation);
              }
            }}
            sex={button.sex}
          />
        ))}
    </>
  );
}

export default ChildSelector;
