import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

function getModalStyle() {
  const top = 40;
  const left = 40;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '50%',
    backgroundColor: 'white',
    border: 'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '20px',
    '&:focus': {
      border: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },

  divButton: {
    cursor: 'pointer',
    fontWeight: '700',

    '&.secondary': {
      color: 'var(--primary-color)',
      fontFamily: 'Kreon !important',
      fontWeight: '600',
      height: '43px',
      //border: '3px solid #f49e83',
      //borderRadius: '10px',
      backgroundColor: '#fff',
      //minWidth: '111px',
      margin: '0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
}));

export default function SimpleModal(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = date.getFullYear();
    const formattedDate = dd + '/' + mm + '/' + yyyy;
    return formattedDate;
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title" className="h3 mb-3 mt-3">
        {props.title}
      </h2>
      {props.data && (
        <p id="simple-modal-description">
          <p className="mb-1">
            <span className="fw-bold">Nº de pedido:</span> {props.data.code}{' '}
          </p>
          <p className="mb-1">
            <span className="fw-bold">Fecha:</span>{' '}
            {props.data.date && formatDate(props.data.date.substring(0, 10))}{' '}
          </p>
          <p className="mb-1">
            <span className="fw-bold">Para:</span> {props.data.kid}
          </p>
          <p className="mb-1">
            <span className="fw-bold">Estado:</span>{' '}
            {props.data.statusTranslation}
          </p>
          <p className="mb-1">
            <span className="fw-bold"> Prendas:</span>{' '}
            {props.data.packageItems.length > 0 && props.data.status === "send" &&
              <span>¡Tu paquete está en camino!</span>
            }
            {props.data.packageItems.length > 0 && props.data.status !== "send" &&
              props.data.packageItems.map((prenda) => {
                return (
                  <li>
                    {prenda.item.description}
                    <ul className="modalList">
                      <li>Precio: {prenda.item.price}€</li>
                      <li>
                        Elección:
                        {prenda.status === 'accepted' && ' ¡Me lo quedo!'}
                        {prenda.status === 'rejected' && ' Devolver'}
                        {prenda.status === 'pending' &&
                          ' Pendiente de elección'}
                      </li>
                    </ul>
                  </li>
                );
              })}
            {props.data.packageItems.length <= 0 &&
              props.data.retryPayment === true &&
              ' -'}
            {props.data.packageItems.length <= 0 &&
              props.data.retryPayment === false &&
              ' Preparando el paquete'}
          </p>
          <p className="mb-1">
            <span className="fw-bold">Total:</span>{' '}
            {props.data.total === '0.00' || props.data.status === "send" ? '-' : props.data.total + '€'}
          </p>
        </p>
      )}

      <SimpleModal />
    </div>
  );

  return (
    <div className="d-flex justify-content-center">
      <div
        onClick={handleOpen}
        className={`${classes.divButton} ${
          props.secondary === true && 'secondary'
        }`}
      >
        {props.text}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
