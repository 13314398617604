import Footer from '../footer';
import Header from '../Header';
import Whatsapp from '../Whatsapp';
import { Link } from 'react-router-dom';
import { PrimaryButton } from '../../ui/atoms/Button.js';
import { Helmet } from 'react-helmet';
import Paso1icon from '../../img/paso1-icon.svg';
import Paso2icon from '../../img/paso2-icon.svg';
import Paso8icon from '../../img/paso8-icon.svg';
import Paso10icon from '../../img/paso10-icon.svg';
import Paso9icon from '../../img/paso9-icon.svg';
import Paso5icon from '../../img/paso5-icon.svg';

function ComoFunciona() {
  return (
    <>
      <Helmet>
        <title>
          Cómo funcionan nuestras Cajas de ropa para niños | Dresskids
        </title>
        <meta
          name="description"
          content="En DressKids hemos creado los mejores outfits para niñas y niños. Conjuntos de todos los estilos: casual, deportivo, moderno ya sea para verano o invierno."
        />
      </Helmet>
      <Header isHome={false} />
      <Whatsapp />
      <div className="container mt-5 mb-md-5">
        <h1 className="h1 text-center">
          ¿Cómo funcionan nuestras cajas de ropa infantil?
        </h1>
        <div className="mt-5">
          <div className="d-flex align-items-center mb-3">
            <img src={Paso1icon} alt="Icono niño-niña" className="me-3" />
            <h2 className="h3 mb-0">Háblanos de tu peque</h2>
          </div>
          <p>
            Rellena el cuestionario de estilo (no tardarás más de cinco
            minutos). Queremos conocer a tu peque, lo que le gusta y con lo que
            disfruta. Cuanta más información tengamos, mejor será el trabajo de
            nuestros personal shoppers infantiles y más fuertes serán los “Uala”
            de tu peque cuando vea la caja con las prendas de ropa que habrán
            seleccionado especialmente para él.
          </p>
          <div className="mb-4 d-flex justify-content-center">
            <Link to="/paso0">
              <PrimaryButton
                className="mt-3"
                value="Comenzar"
              />
            </Link>
          </div>

          <div className="d-flex align-items-center mb-3 mt-5">
            <img src={Paso8icon} alt="Icono niño-niña" className="me-3" />
            <h2 className="h3 mb-0 ">Elige tu caja de ropa infantil</h2>
          </div>
          <p>
            Cada una de nuestras cajas sorpresa con ropa para niños son
            exclusivas. En cada una de ellas, nuestros estilistas infantiles
            añadirán 5 prendas seleccionadas especialmente para tu peque en
            función de sus gustos, necesidades y del presupuesto que nos hayas
            indicado en el cuestionario de estilismo.
          </p>
          <p>Puedes elegir entre dos tipos de packs de ropa infantil:</p>

          <h3 className="fw-bold fs-6">
            1. Pack DressKids: La caja de ropa sorpresa para niños.
          </h3>
          <p>Incluye 5 prendas que se pueden combinar entre ellas</p>

          <h3 className="fw-bold fs-6">
            2. Pack Personalizado: Caja de ropa infantil totalmente
            personalizada.
          </h3>
          <p>
            Nosotros también somos padres y sabemos que siempre hay alguna
            prenda que necesitamos más que otra. Hay niños especialistas en
            hacerse agujeros en los pantalones a la primera de cambio, otras a
            las que una camiseta nueva les dura un telediario porque dejan volar
            (demasiado) su vena artística en clase de plástica… Por eso, con
            esta caja de ropa infantil personalizada podrás seleccionar las 5
            prendas que más se ajusten a la personalidad de tu peque.
          </p>
          <p>
            En ambos casos, si te quedas con el pack de ropa completo, podrás
            beneficiarte de un 25 % de descuento sobre el precio total de las
            prendas.
          </p>
          <div className="mb-4 d-flex justify-content-center">
            <Link to="/paso0">
              <PrimaryButton
                className="mt-3"
                value="Elegir mi pack"
              />
            </Link>
          </div>

          <div className="d-flex align-items-center mb-3 mt-5">
            <img src={Paso2icon} alt="Icono niño-niña" className="me-3" />
            <h2 className="h3 mb-0">
              Tu estilista infantil se pone manos a la obra
            </h2>
          </div>

          <p>
            Ahora que ya conocemos mucho mejor a la personita que va a recibir
            nuestra selección de ropa infantil; nos ponemos manos a la obra para
            elegir las prendas y enviar la caja de ropa sorpresa para tu niño o
            niña con el mismo mimo que lo harías tú.
          </p>

          <div className="mb-4 d-flex justify-content-center">
            <Link to="/paso0">
              <PrimaryButton
                className="mt-3"
                value="Comprar pack"
              />
            </Link>
          </div>

          <div className="d-flex align-items-center mb-3 mt-5">
            <img src={Paso10icon} alt="Icono niño-niña" className="me-3" />
            <h2 className="h3 mb-0">Recibes tu caja de ropa infantil</h2>
          </div>
          <p>
            En un plazo de 48 horas, recibirás un pack con las prendas de ropa
            que hayan elegido nuestros personal shoppers infantiles en tu
            domicilio. Sin compromisos y sin suscripciones. Prepárate para
            escuchar los gritos de emoción y sorpresa cuando vean lo que hemos
            seleccionado para tu hijo.
          </p>

          <div className="d-flex align-items-center mb-3 mt-5">
            <img src={Paso9icon} alt="Icono niño-niña" className="me-3" />
            <h2 className="h3">Empieza el desfile de moda infantil</h2>
          </div>
          <p>
            Después de descubrir qué os hemos enviado en la caja sorpresa de
            ropa para tu niño o niña, tendréis cinco días para que el peque se
            pruebe la ropa con total tranquilidad (podéis aprovechar para
            simular un desfile de moda y que el proceso sea todavía más
            divertido).
          </p>

          <div className="d-flex align-items-center mb-3 mt-5">
            <img src={Paso5icon} alt="Icono niño-niña" className="me-3" />
            <h2 className="h3">
              ¿Qué precio tienen las cajas de DressKids?
            </h2>
          </div>
          <p>
            Adaptamos cada uno de nuestros packs de ropa infantil al presupuesto
            que nos hayas marcado en el momento de completar el formulario.
          </p>
          <p>
            Cada niño recibirá una caja de ropa infantil online exclusiva que
            habrá creado su personal shopper infantil en función de las
            necesidades, gustos y características físicas.
          </p>
          <p>
            Las prendas de moda infantil no se pagan por adelantado y no tendrás
            ningún compromiso de compra ni de quedarte un mínimo de piezas; tan
            solo tendrás que correr con los gastos de envío.
          </p>

          <h2 className="h3 mt-5">
            Algunos peques ya disfrutan de sus outfits:
          </h2>
          <div className="row mt-5">
            <div className="col-12 col-md-4 mt-3 opinion">
              <div className="d-flex justify-content-center mb-3">
                <i class="bi bi-star-fill secondaryC me-2"></i>
                <i class="bi bi-star-fill secondaryC me-2"></i>
                <i class="bi bi-star-fill secondaryC me-2"></i>
                <i class="bi bi-star-fill secondaryC me-2"></i>
                <i class="bi bi-star-fill secondaryC me-2"></i>
              </div>
              <p>
                ¡Mi experiencia en DressKids ha sido perfecta! La atención al
                cliente es maravillosa y toda la ropa que mandaron para mi hija
                nos encantó. Repetiremos seguro.
              </p>
              <p>
                <small>Laura</small>
              </p>
            </div>
            <div className="col-12 col-md-4 mt-3 opinion">
              <div className="d-flex justify-content-center mb-3">
                <i class="bi bi-star-fill secondaryC me-2"></i>
                <i class="bi bi-star-fill secondaryC me-2"></i>
                <i class="bi bi-star-fill secondaryC me-2"></i>
                <i class="bi bi-star-fill secondaryC me-2"></i>
                <i class="bi bi-star-fill secondaryC me-2"></i>
              </div>
              <p>
                Parece que conocéis a mi peque de toda la vida. Nos ha encantado
                todo, no podría ser mejor.
              </p>
              <p>
                <small>Dolores</small>
              </p>
            </div>
            <div className="col-12 col-md-4 mt-3">
              <div className="d-flex justify-content-center mb-3">
                <i class="bi bi-star-fill secondaryC me-2"></i>
                <i class="bi bi-star-fill secondaryC me-2"></i>
                <i class="bi bi-star-fill secondaryC me-2"></i>
                <i class="bi bi-star-fill secondaryC me-2"></i>
                <i class="bi bi-star-fill secondaryC me-2"></i>
              </div>
              <p>
                Me encanta este servicio porque no me gusta nada ir de tiendas,
                y con los niños menos. La comodidad de que un personal shopper
                haga ese trabajo por ti y encima acierte tanto en talla como en
                estilo ¡Me parece increíble! Otra cosa a destacar es la calidad
                de las prendas y lo rápido que llega el paquete a casa. Estoy
                muy contenta con el servicio y lo recomiendo al 100%.
              </p>
              <p>
                <small>Cristina</small>
              </p>
            </div>
          </div>
          <div className="mb-4 d-flex justify-content-center">
            <Link to="/paso0">
              <PrimaryButton
                className="mt-3"
                value="Yo también quiero un outfit así para mis hijos"
              />
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default ComoFunciona;
