import React, { useState, useEffect, useContext } from 'react';
import { PrimaryButton } from '../ui/atoms/Button';
import { Link, useHistory } from 'react-router-dom';
import { SelectAtom } from '../ui/atoms/Select';
import KidInfoContext from '../contexts/KidInfoContext';
import { kidInfoInitialState } from '../contexts/KidInfoContext';
import AuthContext from '../contexts/AuthContext';
import Whatsapp from '../components/Whatsapp';
import { getEndpointItemsWithPagination } from '../services/api-calls';
import Paso4icon from '../img/paso4-icon.svg';
import { Helmet } from 'react-helmet';

const FilterTalla = ({
  handleChange,
  sizes,
  value,
  label,
  placeholder,
  name,
}) => {
  const handleOnChange = (e) => {
    const selection = e.target.value;
    handleChange(name, selection);
  };

  return (
    <SelectAtom
      fullWidth={true}
      label={label}
      name={name}
      onChange={handleOnChange}
      required={false}
      items={sizes}
      defaultValue={value}
      placeholder={placeholder}
    />
  );
};

function Paso4() {

  const [kidInfo, setKidInfo] = useContext(KidInfoContext);
  const [sizes, setSizes] = useState({});
  const [apiGarmentTypes, setApiGarmentTypes] = useState([]);
  const [isValidForm, setIsValidForm] = useState(false);
  const history = useHistory();
  const [auth] = useContext(AuthContext);


  useEffect(() => {
    let isActive = true;

    const getSizes = async () => {
      // Get all garment types with the kid's gender
      let apiGarmentTypes = await getEndpointItemsWithPagination(
        'garment_types'
      );
/*       console.log(apiGarmentTypes,"apiGarmentTypes") */
      apiGarmentTypes = apiGarmentTypes.filter(
        (s) => s.gender['@id'] === `/api/genders/${kidInfo.gender}`
      );
      apiGarmentTypes.sort((a, b) => a.position - b.position);
      apiGarmentTypes = apiGarmentTypes.map((gt) => ({ ...gt, sizes: [] }));

      // Get all sizes with the kid's gender
      let apiSizesData = await getEndpointItemsWithPagination('sizes');
      apiSizesData = apiSizesData.filter(
        (s) => s.type.gender === `/api/genders/${kidInfo.gender}`
      );
      apiSizesData.sort((a, b) => a.position - b.position);

/*       console.log(apiSizesData, "apiSizesData") */

      // Put array of sizes within corresponding garment type
      for (const apiSizesDatum of apiSizesData) {
        for (const apiGarmentType of apiGarmentTypes) {
          if (apiSizesDatum.type.id === apiGarmentType.id) {
            apiGarmentType.sizes.push(apiSizesDatum);
          }
        }
      }

      if (isActive) {
        setApiGarmentTypes(apiGarmentTypes);
        setSizes(kidInfo.sizes);
      }
    };

    getSizes();

    return () => {
      isActive = false;
    };
  }, [kidInfo.gender, kidInfo.sizes]);

  useEffect(() => {
    let selectedSizes = 0;
    if (sizes) {
      selectedSizes = Object.keys(sizes).length;
    }

    setIsValidForm(
      sizes !== kidInfoInitialState.sizes &&
        selectedSizes === apiGarmentTypes.length
    );
  }, [sizes, apiGarmentTypes.length]);

  const handleChange = (garmentName, selection) => {
    setSizes((prev) => ({ ...prev, [garmentName]: selection }));
  };

  return (
    <>
      <Helmet>
        <title>¿Qué tallas utiliza? - Dresskids</title>
      </Helmet>
      <div className="container pt-2 pt-md-5 pb-5">
        <Whatsapp />
        <Link to="/paso3">
          <i className="bi bi-arrow-left fs-3"></i>
        </Link>
        {auth.user ? (
          <>
            <div className="d-flex justify-content-center">
              <img src={Paso4icon} alt="Icono niño-niña" />
            </div>
            <h1 className="h1 text-center mb-0">
              ¿{kidInfo.name} ha crecido mucho?
            </h1>
          </>
        ) : (
          <>
            <div className="d-flex justify-content-center">
              <img src={Paso4icon} alt="Icono niño-niña" />
            </div>
            <h1 className="h1 text-center mb-0">¿Qué tallas utiliza?</h1>
          </>
        )}

        <div className="row d-flex justify-content-center mt-5">
          <div className="col-12 col-md-4">
            <div className="row">
              {apiGarmentTypes.map((garmentType) => (
                <div key={garmentType.id} className="col-12 form-floating mb-3">
                  <FilterTalla
                    handleChange={handleChange}
                    sizes={garmentType.sizes.map((s) => s.name)}
                    value={
                      (kidInfo.sizes && kidInfo.sizes[garmentType.name]) || ''
                    }
                    label={garmentType.name}
                    placeholder="Selecciona talla"
                    name={garmentType.name}
                  />
                </div>
              ))}
              <div className="col-12 mt-5 d-flex justify-content-center">
                    
                <PrimaryButton
                  disabled={!isValidForm}
                  value="Continuar"
                  onClick={() => {
                    setKidInfo((prev) => ({
                      ...prev,
                      sizes: sizes,
                    }));
                    history.push('/paso5');
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Paso4;
