import React, { useState, useEffect, useContext } from 'react';
import { PrimaryButton } from '../ui/atoms/Button';
import { useHistory, Link } from 'react-router-dom';
import KidInfoContext from '../contexts/KidInfoContext';
import { kidInfoInitialState } from '../contexts/KidInfoContext';
import Whatsapp from '../components/Whatsapp';
import { getEndpointItemsWithPagination } from '../services/api-calls';
import Paso5icon from '../img/paso5-icon.svg';
import { Helmet } from 'react-helmet';

const PrendaPrecio = ({
  id,
  value,
  p1,
  p2,
  p3,
  minPrice,
  middlePrice,
  maxPrice,
  onClick,
  selectedValue,
}) => {
  const [isP1Selected, setIsP1Selected] = useState(selectedValue === p1);
  const [isP2Selected, setIsP2Selected] = useState(selectedValue === p2);
  const [isP3Selected, setIsP3Selected] = useState(selectedValue === p3);

  const handleOnClick = (id, precio, priceValue) => {
    switch (precio) {
      case p1:
        setIsP1Selected(true);
        setIsP2Selected(false);
        setIsP3Selected(false);
        break;
      case p2:
        setIsP1Selected(false);
        setIsP2Selected(true);
        setIsP3Selected(false);
        break;
      case p3:
        setIsP1Selected(false);
        setIsP2Selected(false);
        setIsP3Selected(true);
        break;
      default:
        setIsP1Selected(false);
        setIsP2Selected(false);
        setIsP3Selected(false);
        break;
    }
    onClick(id, precio, value, priceValue);
  };

  return (
    <div className="mb-3 mt-3 prenda-precio">
      <p className="h4 mb-4 text-center">{value}</p>
      <div className="row">
        <div className="col-4">
          <div
            onClick={() => {
              handleOnClick(id, p1, minPrice);
            }}
            className={`filterPill ${isP1Selected && 'filterPill-selected'}`}
          >
            {p1}
          </div>
        </div>
        <div className="col-4">
          <div
            onClick={() => {
              handleOnClick(id, p2, middlePrice);
            }}
            className={`filterPill ${isP2Selected && 'filterPill-selected'}`}
          >
            {p2}
          </div>
        </div>
        <div className="col-4">
          <div
            onClick={() => {
              handleOnClick(id, p3, maxPrice);
            }}
            className={`filterPill ${isP3Selected && 'filterPill-selected'}`}
          >
            {p3}
          </div>
        </div>
      </div>
    </div>
  );
};

function Paso5() {
  const [kidInfo, setKidInfo] = useContext(KidInfoContext);
  const [garmentsValues, setGarmentsValues] = useState(
    kidInfoInitialState.garmentsValues
  );
  const [apiGarments, setApiGarments] = useState([]);
  const [isValidForm, setIsValidForm] = useState(false);

  const history = useHistory();
  
  const getId = (id, precio, name, value) => {
    setGarmentsValues((prev) => ({
      ...prev,
      [id]: { texto: precio, price: value, name },
    }));
  };

  useEffect(() => {
    let isActive = true;

    const getGarments = async () => {
      // Get all garments with the kid's gender
      let apiGarmentsData = await getEndpointItemsWithPagination(
        'garment_group_price_ranges'
      );
      apiGarmentsData = apiGarmentsData.filter(
        (s) => s.type.gender['@id'] === `/api/genders/${kidInfo.gender}`
      );

      apiGarmentsData = apiGarmentsData.map((garment) => ({
        id: garment['@id'],
        value: garment.name,
        p1: `-${garment.minPrice}€`,
        p2: `${garment.minPrice}€ - ${garment.maxPrice}€`,
        p3: `+${garment.maxPrice}€`,
        minPrice: garment.minPrice,
        maxPrice: garment.maxPrice,
        middlePrice: (garment.minPrice + garment.maxPrice) / 2,
      }));

      if (isActive) {
        setApiGarments(apiGarmentsData);
        setGarmentsValues(kidInfo.garmentsValues);
      }
    };

    getGarments();

    return () => {
      isActive = false;
    };
  }, [kidInfo.gender, kidInfo.garmentsValues]);

  useEffect(() => {
    let selectedPrices = 0;
    if (garmentsValues) {
      selectedPrices = Object.keys(garmentsValues).length;
    }

    setIsValidForm(
      garmentsValues !== kidInfoInitialState.garmentsValues &&
        selectedPrices === apiGarments.length
    );
  }, [garmentsValues, apiGarments.length]);

  return (
    <>
      {Object.keys(kidInfo.sizes).length === 0 ? (
        <div className="container pt-2 pt-md-5 pb-5">
          <p className='text-center fw-bold'>Debes seleccionar las tallas para poder continuar</p>
          <div className="col-12 mt-md-5 mt-3 d-flex justify-content-center">
            <PrimaryButton
              value="Selecciona tallas"
              onClick={() => {
                history.push('/paso4');
              }}
            />
          </div>
        </div>
      ) : (
        <>
          <Helmet>
            <title>¿Cuánto sueles pagar por estas prendas? - Dresskids</title>
          </Helmet>
          <div className="container pt-2 pt-md-5 pb-5">
            <Whatsapp />
            <Link to="/paso4">
              <i className="bi bi-arrow-left fs-3"></i>
            </Link>
            <div className="d-flex justify-content-center">
              <img src={Paso5icon} alt="Icono niño-niña" />
            </div>
            <h1 className="h1 text-center mb-0">
              ¿Cuánto sueles pagar por estas prendas?
            </h1>
            <div className="row d-flex justify-content-center mt-5">
              <div className="col-12 col-md-5 col-xl-5">
                <div className="row d-flex justify-content-center">
                  <div className="col-12">
                    {apiGarments.map((precio) => (
                      <PrendaPrecio
                        key={precio.id}
                        value={precio.value}
                        id={precio.id}
                        p1={precio.p1}
                        p2={precio.p2}
                        p3={precio.p3}
                        minPrice={precio.minPrice}
                        middlePrice={precio.middlePrice}
                        maxPrice={precio.maxPrice}
                        onClick={getId}
                        selectedValue={
                          garmentsValues && garmentsValues[precio.id]?.texto
                        }
                      />
                    ))}
                  </div>

                  <p className="text-justify">
                    Te aseguramos que tu pack no excederá en ningún caso los
                    importes que has indicado y te recordamos que{' '}
                    <strong>
                      si te quedas con el pack completo obtendrás un 25% de
                      descuento
                    </strong>{' '}
                    desde la primera compra, tantas veces como compres en
                    DressKids,{' '}
                    <strong>no pagarás nada hasta que recibas el pedido</strong>{' '}
                    y si no te encaja lo que hemos seleccionado para tu peque lo
                    devuelves sin ningún coste.
                  </p>
                  <p className="text-center fs-5">¿Te lo vas a perder?</p>

                  <div className="col-12 mt-4 d-flex justify-content-center">
                    <Link
                      className="w-100 d-flex justify-content-center"
                      to="/paso6"
                    >
                      <PrimaryButton
                        disabled={!isValidForm}
                        value="Continuar"
                        onClick={() => {
                          setKidInfo((prev) => ({ ...prev, garmentsValues }));
                        }}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Paso5;
