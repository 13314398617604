import React from 'react';
import Chat from '../img/chat.svg';

function Whatsapp() {
  return (
    
    <a
      href="https://api.whatsapp.com/send?phone=34747449077&text=¡Hola!,%20tengo%20una%20duda%20sobre%20Dresskids"
      target="_blank"
      className="whatsapp-icon chat"
      rel="noreferrer"
    >
      <img alt="Chat" src={Chat} />
    </a>
  );
}

export default Whatsapp;
