import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { API_URL } from '../constants';
import AuthContext from '../contexts/AuthContext';
import KidInfoContext from '../contexts/KidInfoContext';
import { PrimaryButton } from '../ui/atoms/Button';
import { SelectAtom } from '../ui/atoms/Select';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getAllowedDateRange } from '../services/date-calculator';
import { getEndpointItemsWithPagination } from '../services/api-calls';

/*TODO estos datos tiene que devolverlos la api. */
const days = [
  { name: '1', code: '0' },
  { name: '2', code: '1' },
  { name: '3', code: '2' },
  { name: '4', code: '3' },
  { name: '5', code: '4' },
  { name: '6', code: '5' },
  { name: '7', code: '6' },
  { name: '8', code: '7' },
  { name: '9', code: '8' },
  { name: '10', code: '9' },
  { name: '11', code: '10' },
  { name: '12', code: '11' },
  { name: '13', code: '12' },
  { name: '14', code: '13' },
  { name: '15', code: '14' },
  { name: '16', code: '15' },
  { name: '17', code: '16' },
  { name: '18', code: '17' },
  { name: '19', code: '18' },
  { name: '20', code: '19' },
  { name: '21', code: '20' },
  { name: '22', code: '21' },
  { name: '23', code: '22' },
  { name: '24', code: '23' },
  { name: '25', code: '24' },
  { name: '26', code: '25' },
  { name: '27', code: '26' },
  { name: '28', code: '27' },
  { name: '29', code: '28' },
  { name: '30', code: '29' },
  { name: '31', code: '30' },
];

/*TODO estos datos tiene que devolverlos la api. */
const meses = [
  { name: 'Enero', code: '0' },
  { name: 'Febrero', code: '1' },
  { name: 'Marzo', code: '2' },
  { name: 'Abril', code: '3' },
  { name: 'Mayo', code: '4' },
  { name: 'Junio', code: '5' },
  { name: 'Julio', code: '6' },
  { name: 'Agosto', code: '7' },
  { name: 'Septiembre', code: '8' },
  { name: 'Octubre', code: '9' },
  { name: 'Noviembre', code: '10' },
  { name: 'Diciembre', code: '11' },
];
/*TODO estos datos tiene que devolverlos la api, calculando los años del rango de edad de 1-12. Hasta que la api lo mande, tiene que calcularlo el front*/
const years = [
  { name: '2021', code: '0' },
  { name: '2020', code: '1' },
  { name: '2019', code: '2' },
  { name: '2018', code: '3' },
  { name: '2017', code: '4' },
  { name: '2016', code: '5' },
  { name: '2015', code: '6' },
  { name: '2014', code: '7' },
  { name: '2013', code: '8' },
  { name: '2012', code: '9' },
  { name: '2011', code: '10' },
  { name: '2010', code: '11' },
  { name: '2009', code: '12' },
];

const FilterGenero = ({ handleChange, generos, initialValue }) => {
  const handleOnChange = (e) => {
    const selection = e.target.value;
    handleChange(selection);
  };

  return (
    <SelectAtom
      fullWidth={true}
      label="Género"
      name="genero"
      onChange={handleOnChange}
      required={false}
      items={generos}
      defaultValue={initialValue}
      placeholder="Género"
    />
  );
};

const FilterColorOjos = ({ id, isActive, onClick, colorPicker }) => {
  return (
    <>
      <div
        style={{ backgroundColor: colorPicker }}
        className={`col-3 color-select ${isActive ? 'selected' : ''}`}
        onClick={() => onClick(id)}
      ></div>
    </>
  );
};

const FilterColorPelo = ({ id, isActive, onClick, colorPicker }) => {
  return (
    <>
      <div
        style={{ backgroundColor: colorPicker }}
        className={`col-3 color-select ${isActive ? 'selected' : ''}`}
        onClick={() => onClick(id)}
      ></div>
    </>
  );
};

const FilterColorPiel = ({ id, isActive, onClick, colorPicker }) => {
  return (
    <>
      <div
        style={{ backgroundColor: colorPicker }}
        className={`col-3 color-select ${isActive ? 'selected' : ''}`}
        onClick={() => onClick(id)}
      ></div>
    </>
  );
};

const ChildAvatar = ({ id, onClick, isActive, nombre, gender, birthday, inMenu, inUserArea }) => {
  const onHandleOnClick = (id) => {
    onClick(id);
  };


  let today = new Date().toISOString().split('T')[0]
  
  let añoKid = birthday.substring(0,4)
  let mesKid = birthday.substring(5,7)
  let diaKid = birthday.substring(8,10)
  let timeKid = new Date(añoKid, mesKid, diaKid);
  
  let año = today.substring(0,4);
  let mes = today.substring(5,7);
  let dia = today.substring(8,10);
  let timeToday = new Date (año, mes, dia);

  const y = 1000 * 60 * 60 * 24 * 365.2425;
  let year = Math.floor((timeToday.getTime() - timeKid.getTime()) / y);

  return (
    <div
      className="d-flex flex-column align-items-center mb-3 mb-md-0"
      onClick={ year && year < 13 || inUserArea?
        () => onHandleOnClick(id) : null
      }
    >
      <div
        className={`${gender === '/api/genders/2' ? 'niña' : 'niño'} ${
          isActive ? 'selected' : ''
        } litle
        ${year >= 13 && !inUserArea ? 'disabled' : ''}
        `}
      ></div>
      <p className={`${inMenu && 'white'} mb-0 mt-2 ${year >= 13 && !inUserArea && 'disabled'}`}>
        {nombre}
      </p>
      {year >= 13 &&
        <span className={`text-small`}>Fuera del rango de edad</span>
      }
    </div>
  );
};

function UserChildSelector({ inUserArea, home, inMenu }) {
  const [auth] = useContext(AuthContext);
  const [, setKidInfo] = useContext(KidInfoContext);
  const [generosApi, setGenerosApi] = useState([]);
  const [coloresOjos, setColoresOjos] = useState([]);
  const [coloresPelo, setColoresPelo] = useState([]);
  const [coloresPiel, setColoresPiel] = useState([]);
  const history = useHistory();
  const MySwal = withReactContent(Swal);

  const [childActive, setChildActive] = useState();
  const [children, setChildren] = useState([]);
  const [kidName, setKidName] = useState('');
  const [kidBirthday, setKidBirthday] = useState('');
  const [kidId, setKidId] = useState(null);
  const [kidEyesColor, setKidEyeColor] = useState('');
  const [kidHairColor, setKidHairColor] = useState('');
  const [kidSkinColor, setKidSkinColor] = useState('');
  const [kidGenero, setKidGenero] = useState('');
  const [updateChildrenList, setUpdateChildrenList] = useState(0);
  const [day, setDayValue] = useState('');
  const [month, setMonthValue] = useState('');
  const [year, setYearValue] = useState('');

  useEffect(() => {
    let dayKidInfo = kidBirthday?.substring(8) || '';
    if (dayKidInfo.substring(0, 1) === '0') {
      dayKidInfo = dayKidInfo.substring(1);
    }

    dayKidInfo = days.find((day) => day.name === dayKidInfo);
    setDayValue(dayKidInfo);

    let monthKidInfo = kidBirthday?.substring(5, 7) || '';
    if (monthKidInfo.substring(0, 1) === '0') {
      monthKidInfo = monthKidInfo.substring(1);
    }
    monthKidInfo = meses.find(
      (month) => month.code === '' + (Number(monthKidInfo) - 1)
    );
    setMonthValue(monthKidInfo);

    let yearKidInfo = kidBirthday?.substring(0, 4) || '';
    yearKidInfo = years.find((year) => year.name === yearKidInfo);
    setYearValue(yearKidInfo);

  }, [kidBirthday]);

  const { minDate, maxDate } = getAllowedDateRange();
  let fechaIncorrecta = false;
  if (kidBirthday) {
    const birthdayDate = new Date(kidBirthday);
    const minDateObj = new Date(minDate);
    const maxDateObj = new Date(maxDate);

    if (birthdayDate < minDateObj || birthdayDate > maxDateObj) {
      fechaIncorrecta = true;
    }
  }

  

  useEffect(() => {
    let monthV = Number(month?.code);
    let yearV = Number(year?.name);

    // if (month && year) {
    //   const calculateDaysInMonth = (y, m) => new Date(y, m, 0).getDate();
    //   const daysInMonth = calculateDaysInMonth(yearV, monthV + 1);
    //   const days = [];
    //   for (let i = 1; i <= daysInMonth; i++) {
    //     days.push({ name: '' + i, code: '' + i });
    //   }
    //   setDays(days);
    // }

    if (day && month && year) {
      let date = new Date(yearV, monthV, Number(day.name) + 1);
      setKidBirthday(date.toISOString().substring(0, 10));
    }

  }, [year, month, day, setKidBirthday]);



  const updateDay = (value) => {
    setDayValue(value.target.value);
  };
  const updateMonth = (value) => {
    setMonthValue(value.target.value);
  };

  const updateYear = (value) => {
    setYearValue(value.target.value);
  };

  const isValidForm = year && month && day && !fechaIncorrecta;

  const changeKidName = (e) => {
    setKidName(e.target.value);
  };

  useEffect(() => {
    let isActive = true;
    const getColoresOjosData = async () => {
      const colores = await getEndpointItemsWithPagination('eyes_colors');
      colores.sort((a, b) => a.position - b.position);
      if (isActive) {
        setColoresOjos(colores);
      }
    };

    getColoresOjosData();

    return () => {
      isActive = false;
    };
  }, []);

  useEffect(() => {
    let isActive = true;
    const getColoresPeloData = async () => {
      const colores = await getEndpointItemsWithPagination('hair_colors');
      colores.sort((a, b) => a.position - b.position);
      if (isActive) {
        setColoresPelo(colores);
      }
    };

    getColoresPeloData();

    return () => {
      isActive = false;
    };
  }, []);

  useEffect(() => {
    let isActive = true;
    const getColoresPielData = async () => {
      const colores = await getEndpointItemsWithPagination('skin_colors');
      colores.sort((a, b) => a.position - b.position);
      if (isActive) {
        setColoresPiel(colores);
      }
    };

    getColoresPielData();

    return () => {
      isActive = false;
    };
  }, []);

  useEffect(() => {
    let isActive = true;

    const getGenerosData = async () => {
      const generos = await getEndpointItemsWithPagination('genders');
      if (isActive) {
        setGenerosApi(generos);
      }
    };

    getGenerosData();

    return () => {
      isActive = false;
    };
  }, []);

  useEffect(() => {
    let isActive = true;

    const getChildrenData = async () => {
      const response = await fetch(`${API_URL}/kids`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
      const json = await response.json();
      let kids = json['hydra:member'];
      if (isActive) {
        setChildren(kids);
      }
    };

    getChildrenData();

    return () => {
      isActive = false;
    };
  }, [auth.token, updateChildrenList]);

  return (
    <>
      <div className="perfilesContainer d-flex flex-row flex-md-row justify-content-center align-items-center mt-md-4">
        {children && children.length > 0 && (
          <>
            {children &&
              children.map((child, index) => (
                <ChildAvatar
                  key={child.id}
                  id={child.id}
                  gender={child.gender}
                  nombre={child.name}
                  edad={child.age}
                  birthday={child.birthday}
                  inMenu={inMenu}
                  inUserArea={inUserArea}
                  isActive={childActive === child.id}
                  onClick={(id) => {
                    if (id === kidId) {
                      setChildActive(undefined);
                      setKidId(null);
                      return;
                    }
                    setKidBirthday(child.birthday.substring(0, 10));
                    setKidEyeColor(child.eyesColor);
                    setKidHairColor(child.hairColor);
                    setKidSkinColor(child.skinColor);
                    const filteredGender = generosApi.filter(
                      (g) => g['@id'] === child.gender
                    );
                    setKidGenero(filteredGender[0]);
                    setKidName(child.name);
                    setKidId(child.id);
                    setChildActive(index);
                    setKidInfo({
                      id: child.id,
                      creating: false,
                      name: child.name,
                      gender: child.gender.substring('/api/genders/'.length),
                      birthday: child.birthday.substring(0, 10),
                      eyesColor: child.eyesColor.id,
                      hairColor: child.hairColor.id,
                      skinColor: child.skinColor.id,
                    });
                    if (!inUserArea) {
                      history.push('/paso2');
                    }
                  }}
                />
              ))}
          </>
        )}

        <div className="d-flex flex-column align-items-center">
          <div
            className={`nuevo ${
              home && home ? 'addbuttonHome' : ''
            } d-flex justify-content-center align-items-center`}
            onClick={() => {
              setKidInfo({ creating: true });
              history.push('/nuevo-perfil');
            }}
          >
            <i className="bi bi-plus-lg"></i>
          </div>
          <p className={`${inMenu && 'white'} mb-0 mt-2`}>Añadir</p>
        </div>
      </div>

      {inUserArea && (childActive !== undefined || childActive === 0) && (
        <div className="row d-flex justify-content-center" id="container">
          <p className="fw-bold text-center mt-5 mb-4">
            Modificar datos de {children[childActive].name}
          </p>
          <div className="col-12 col-md-9 col-lg-4">
            <div className="row">
              <div className="col-12 form-floating mb-3">
                <input
                  type="text"
                  id="nombre"
                  class="form-control"
                  value={kidName}
                  onChange={changeKidName}
                />
                <label for="nombre">Nombre</label>
              </div>
              <div className="col-12 mt-2 mb-0">
                <p>Fecha de nacimiento</p>
              </div>

              <div className="col-12 col-md-4 form-floating mb-3">
                <SelectAtom
                  id="año"
                  fullWidth={true}
                  label="Año"
                  placeholder="Año"
                  items={years}
                  onChange={updateYear}
                  defaultValue={year}
                />
              </div>

              <div className="col-12 col-md-4 form-floating mb-3">
                <SelectAtom
                  id="mes"
                  label="Mes"
                  placeholder="Mes"
                  fullWidth={true}
                  items={meses}
                  onChange={updateMonth}
                  defaultValue={month}
                  error={fechaIncorrecta && { message: 'máx. 12 años' }}
                />
              </div>

              <div className="col-12 col-md-4 form-floating mb-3">
                <SelectAtom
                  id="dia"
                  fullWidth={true}
                  label="Día"
                  placeholder="Día"
                  items={days}
                  onChange={updateDay}
                  defaultValue={day}
                />
              </div>
              <div className="col-12 form-floating mb-3">
                <FilterGenero
                  handleChange={(name) => {
                    let selected = generosApi.filter((c) => c.name === name)[0];
                    setKidGenero(selected);
                  }}
                  generos={generosApi.map((c) => c.name)}
                  initialValue={kidGenero.name}
                />
              </div>
              <div className="col-12 form-floating mb-3">
                <p className="mb-3 h4 text-center">Color de ojos</p>
                <div className="d-flex justify-content-around">
                  {coloresOjos.map((ojos) => (
                    <FilterColorOjos
                      key={ojos.id}
                      id={ojos.id}
                      colorPicker={ojos.colorPicker}
                      isActive={kidEyesColor.id === ojos.id}
                      onClick={() => {
                        setKidEyeColor(ojos);
                      }}
                    />
                  ))}
                </div>
              </div>
              <div className="col-12 form-floating mb-3">
                <p className="mb-3 h4 text-center">Color de pelo</p>
                <div className="d-flex justify-content-around">
                  {coloresPelo.map((pelo) => (
                    <FilterColorPelo
                      key={pelo.id}
                      id={pelo.id}
                      colorPicker={pelo.colorPicker}
                      isActive={kidHairColor.id === pelo.id}
                      onClick={() => setKidHairColor(pelo)}
                    />
                  ))}
                </div>
              </div>
              <div className="col-12 form-floating mb-3">
                <p className="mb-4 h4 text-center">Color de piel</p>
                <div className="d-flex justify-content-around">
                  {coloresPiel.map((piel) => (
                    <FilterColorPiel
                      key={piel.id}
                      id={piel.id}
                      colorPicker={piel.colorPicker}
                      isActive={kidSkinColor.id === piel.id}
                      onClick={() => setKidSkinColor(piel)}
                    />
                  ))}
                </div>
              </div>
              <div className="col-12">
                <PrimaryButton
                  className="w-100 mt-3"
                  value="Modificar"
                  disabled={!isValidForm}
                  onClick={async () => {
                    await MySwal.fire({
                      html: 'Modificando...',
                      icon: 'info',
                      didOpen: async () => {
                        Swal.showLoading();
                        // TODO how to avoid memory leak in this fetch
                        // const modifyKidResponse =
                        await fetch(`${API_URL}/kids/${kidId}`, {
                          method: 'PATCH',
                          headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${auth.token}`,
                          },
                          body: JSON.stringify({
                            name: kidName,
                            birthday: kidBirthday,
                            eyesColor: '/api/eyes_colors/' + kidEyesColor.id,
                            hairColor: '/api/hair_colors/' + kidHairColor.id,
                            skinColor: '/api/skin_colors/' + kidSkinColor.id,
                            gender: kidGenero['@id'],
                          }),
                        });
                        MySwal.fire({
                          html: 'Perfil modificado',
                          icon: 'success',
                        });
                        setChildActive(undefined);
                        setKidId(null);
                        setUpdateChildrenList((prev) => prev + 1);
                      },
                    });
                  }}
                />
              </div>
              {
                // TODO Habilitar boton de eliminar cuando se puedan borrar de la base de datos kids con paquetes asociados a ellos
              }
              {/* <div className="col-12 mt-3">
                <PrimaryButton
                  className="w-100"
                  value="Eliminar"
                  onClick={() => {
                    MySwal.fire({
                      title: '¿Seguro que deseas eliminar este perfil?',
                      text: 'No podrás deshacer esta acción.',
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Eliminar',
                      cancelButtonText: 'Cancelar',
                    }).then(async (result) => {
                      if (result.isConfirmed) {
                        // TODO how to avoid mem leak in this fetch
                        const delteKidResponse = await fetch(
                          `${API_URL}/kids/${kidId}`,
                          {
                            method: 'DELETE',
                            headers: {
                              'Content-Type': 'application/json',
                              Authorization: `Bearer ${auth.token}`,
                            },
                          }
                        );

                        if (delteKidResponse.status === 204) {
                          const kidToDeleteId = kidId;
                          setChildActive(undefined);
                          setKidId(null);
                          setChildren((prev) => {
                            return prev.filter((k) => k.id !== kidToDeleteId);
                          });
                          Swal.fire(
                            'Perfil Eliminado',
                            'Se ha eliminado el perfil.',
                            'success'
                          );
                        }
                      }
                    });
                  }}
                />
              </div>*/}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default UserChildSelector;
