import { useContext, useEffect, useState } from 'react';
import { PrimaryButton } from '../ui/atoms/Button';
import { Link, useHistory } from 'react-router-dom';
import KidInfoContext from '../contexts/KidInfoContext';
import { kidInfoInitialState } from '../contexts/KidInfoContext';
import { API_IMAGES_URL } from '../constants';
import { getAgeFromBirthdate } from '../services/date-calculator';
import { getEndpointItemsWithPagination } from '../services/api-calls';
import Whatsapp from '../components/Whatsapp';
import Paso3icon from '../img/paso3-icon.svg';
import { Helmet } from 'react-helmet';

const FilterColores = ({ id, isActive, image, onClick, value }) => {
  const imageUrl = encodeURI(`${API_IMAGES_URL}/type_clothing/${image}`);

  return (
    <div className="col-12 col-md-6 mb-4 mb-md-0">
      <a href='#estilo'>
      <div
        style={{ backgroundImage: `url(${imageUrl})` }}
        className={`photo ${isActive ? 'selected' : ''}`}
        onClick={() => onClick(id)}
      >
        <p className="text-center h4">{value}</p>
      </div>
      </a>
    </div>
  );
};

const FilterEstilo = ({ id, isActive, image, onClick, value }) => {
  const imageUrl = encodeURI(`${API_IMAGES_URL}/style_clothing/${image}`);

  return (
    <div className="col-12 col-md-6 mb-4 mb-md-0">
      <div
        style={{ backgroundImage: `url(${imageUrl})` }}
        className={`photo ${isActive ? 'selected' : ''}`}
        onClick={() => onClick(id)}
      >
        <p className="text-center h4">{value}</p>
      </div>
    </div>
  );
};

function Paso3() {
  const [kidInfo, setKidInfo] = useContext(KidInfoContext);
  const [stylesApi, setStylesApi] = useState([]);
  const [colorsApi, setColorsApi] = useState([]);
  const [styleClothing, setStyleClothing] = useState(kidInfo.styleClothing);
  const [colorClothing, setColorClothing] = useState(kidInfo.colorClothing);
  const [isValidForm, setIsValidForm] = useState(false);
  console.log(isValidForm, 'isvalidform');

  const history = useHistory();

  useEffect(() => {
    setIsValidForm(styleClothing !== kidInfo.styleClothing && colorClothing !== kidInfo.colorClothing);
  }, [styleClothing, colorClothing]);

  useEffect(() => {
    let isActive = true;

    const getStyleImagesData = async () => {
      let images = await getEndpointItemsWithPagination('style_clothings');

      const age = getAgeFromBirthdate(kidInfo.birthday);
      images = images.filter(
        (image) =>
          image.gender['@id'] === `/api/genders/${kidInfo.gender}` &&
          image.minAge <= age &&
          image.maxAge >= age
      );

      const stylesData = images.map((image) => ({
        id: image.id,
        name: image.name,
        image: image.image,
      }));
      for (let styleData of stylesData) {
        if (styleData.id === kidInfo.styleClothing) {
          if (isActive) {
            setStyleClothing(styleData);
          }
          break;
        }
      }
      if (isActive) {
        setStylesApi(stylesData);
      }
    };

    getStyleImagesData();

    return () => {
      isActive = false;
    };
  }, [kidInfo.birthday, kidInfo.gender, kidInfo.styleClothing]);

  useEffect(() => {
    let isActive = true;

    const getColorImagesData = async () => {
      let images = await getEndpointItemsWithPagination('color_clothings');

      const age = getAgeFromBirthdate(kidInfo.birthday);
      images = images.filter(
        (image) =>
          image.gender['@id'] === `/api/genders/${kidInfo.gender}` &&
          image.minAge <= age &&
          image.maxAge >= age
      );

      const colorData = images.map((image) => ({
        id: image.id,
        name: image.name,
        image: image.image,
      }));

      for (let colorDatum of colorData) {
        if (colorDatum.id === kidInfo.colorClothing) {
          if (isActive) {
            setColorClothing(colorDatum);
          }
          break;
        }
      }
      if (isActive) {
        setColorsApi(colorData);
      }
    };

    getColorImagesData();

    return () => {
      isActive = false;
    };
  }, [kidInfo.birthday, kidInfo.gender, kidInfo.colorClothing]);

  return (
    <>
      <Helmet>
        <title>¿Cómo prefieres las prendas? - Dresskids</title>
      </Helmet>
      <div className="container pt-2 pt-md-5 pb-5">
        <Whatsapp />
        <Link to="/paso2">
          <i className="bi bi-arrow-left fs-3"></i>
        </Link>
        <div className="d-flex justify-content-center">
          <img src={Paso3icon} alt="Icono niño-niña" />
        </div>
        <h1 className="h1 text-center mb-0">En cuanto a los colores</h1>
        <h2 className="h1 text-center">¿Cómo prefieres las prendas?</h2>
        <div className="row d-flex justify-content-center mt-4">
          <div className="col-12 col-sm-6">
            <div className="row mt-4 mb-4">
              {colorsApi.map((color) => (
                <FilterColores
                  key={color.id}
                  id={color.id}
                  isActive={colorClothing === color}
                  image={color.image}
                  onClick={() => setColorClothing(color)}
                  value={color.name}
                />
              ))}
            </div>
          </div>


          {colorClothing !== kidInfo.colorClothing && (
            <>
              <h2 className="h1 text-center mt-4" id="estilo">¿Y el estilo?</h2>
              <div className="col-12 col-sm-6">
                <div className="row mt-4 ">
                  {stylesApi.map((estilo) => (
                    <FilterEstilo
                      key={estilo.id}
                      id={estilo.id}
                      isActive={styleClothing === estilo}
                      image={estilo.image}
                      onClick={() => setStyleClothing(estilo)}
                      value={estilo.name}
                    />
                  ))}
                </div>
              </div>
            </>
          )}

          <div className="col-12 mt-md-5 mt-3 d-flex justify-content-center">
            <PrimaryButton
              disabled={!isValidForm}
              value="Continuar"
              onClick={() => {
                setKidInfo((prev) => ({
                  ...prev,
                  colorClothing: colorClothing.id,
                  styleClothing: styleClothing.id,
                }));
                history.push('/paso4');
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Paso3;
