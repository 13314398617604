import { BrowserRouter as Router, Route } from 'react-router-dom';
import './App.css';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Home from './pages/Home.js';
import Login from './pages/Login.js';
import LoginUsuario from './pages/LoginUsuario.js';
import Paso1 from './pages/Paso1Sobre.js';
import Paso2 from './pages/Paso2Estilo.js';
import Paso3 from './pages/Paso3Colores.js';
import Paso4 from './pages/Paso4Tallas.js';
import Paso5 from './pages/Paso5Cuanto.js';
import Paso6 from './pages/Paso6PrendasNo.js';
import Paso7 from './pages/Paso7Comentario.js';
import Paso8 from './pages/Paso8Pack.js';
import Paso9 from './pages/Paso9Registro.js';
import Paso10 from './pages/Paso10Envio.js';
import Paso11 from './pages/Paso11Exito.js';
import AreaUsuario from './pages/AreaUsuario.js';
import ForgotPassword from './pages/ForgotPassword.js';
import ChangePassword from './pages/ChangePassword.js';
import NewPassword from './pages/NewPassword.js';
import Checkout from './components/area-usuario/Checkout';
import CheckoutFinalizado from './components/area-usuario/CheckoutFinalizado.js';
import QuienesSomos from './components/paginas-footer/QuienesSomos';
import NuevoPerfil from './pages/NuevoPerfil';
import 'bootstrap/dist/css/bootstrap.min.css';
import Proximamente from './pages/proximamente';
import AuthContext from './contexts/AuthContext';
import KidInfoContext from './contexts/KidInfoContext';
import { kidInfoInitialState } from './contexts/KidInfoContext';
import AvisoLegal from './components/paginas-footer/AvisoLegal.js';
import PoliticaPrivacidad from './components/paginas-footer/Politica-privacidad';
import CondicionesDeContratacion from './components/paginas-footer/CondicionesDeContratacion';
import PoliticaDeCookies from './components/paginas-footer/PoliticaDeCookies';
import NavigationGuards from './components/NavigationGuards';
import Paso0 from './pages/Paso0Genero';
import About from './components/paginas-footer/About';
import HowItWorks from './components/paginas-footer/HowItWorks';
import ErrorPago from './pages/ErrorPago';
import Contacto from './pages/Contacto';
import NuestrosOutfits from './components/paginas-footer/NuestrosOutfits';
import ComoFunciona from './components/paginas-footer/ComoFunciona';

import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-PJC7ZVW',
  dataLayerName: 'dataLayer',
};

TagManager.initialize(tagManagerArgs);

function App() {
  const authState = useState({ user: null, token: null });
  const kidInfoState = useState(kidInfoInitialState);

  return (
    <div className="App">
      <AuthContext.Provider value={authState}>
        <KidInfoContext.Provider value={kidInfoState}>
          <Router>
            <NavigationGuards />
            <Route path="/paso0" component={Paso0} />
            <Route path="/paso1" component={Paso1} />
            <Route path="/paso2" component={Paso2} />
            <Route path="/paso3" component={Paso3} />
            <Route path="/paso4" component={Paso4} />
            <Route path="/paso5" component={Paso5} />
            <Route path="/paso6" component={Paso6} />
            <Route path="/paso7" component={Paso7} />
            <Route path="/paso8" component={Paso8} />
            <Route path="/paso9" component={Paso9} />
            <Route path="/paso10" component={Paso10} />
            <Route path="/paso11/:packageId" component={Paso11} />
            <Route
              path="/fallo-compra/:packageId/:email"
              component={ErrorPago}
            />
            <Route path="/login" component={Login} />
            <Route path="/login-usuario" component={LoginUsuario} />
            <Route path="/nuevo-perfil" component={NuevoPerfil} />
            <Route path="/area-usuario" component={AreaUsuario} />
            <Route path="/restaurar-contrasena" component={ForgotPassword} />
            <Route path="/cambiar-contrasena" component={ChangePassword} />
            <Route
              path="/nueva-contrasena/:code/:email"
              component={NewPassword}
            />
            <Route path="/checkout/:packageId" component={Checkout} />
            <Route
              path="/checkout-finalizado/:packageId"
              component={CheckoutFinalizado}
            />
            <Route path="/about" component={About} />
            <Route
              path="/quienes-somos"
              title="quienes somos"
              component={QuienesSomos}
            />
            <Route path="/aviso-legal" component={AvisoLegal} />
            <Route path="/politica-privacidad" component={PoliticaPrivacidad} />
            <Route path="/contacto" component={Contacto} />
            <Route path="/nuestros-outfits" component={NuestrosOutfits} />
            <Route path="/como-funciona" component={ComoFunciona} />

            <Route
              path="/condiciones-de-contratacion"
              component={CondicionesDeContratacion}
            />
            <Route path="/politica-de-cookies" component={PoliticaDeCookies} />
            <Route exact path="/" component={Home} />
            {/* <Route exact path="/" component={Proximamente} /> */}
          </Router>
        </KidInfoContext.Provider>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
