import { useState, useEffect, useContext } from 'react';
import { PrimaryButton } from '../ui/atoms/Button';
import { Link, useHistory } from 'react-router-dom';
import { PATH_PAGINA_INICIO } from '../constants';
import KidInfoContext from '../contexts/KidInfoContext';
import { kidInfoInitialState } from '../contexts/KidInfoContext';
import { getAllowedDateRange } from '../services/date-calculator';
import { SelectAtom } from '../ui/atoms/Select';
import Whatsapp from '../components/Whatsapp';
import { getEndpointItemsWithPagination } from '../services/api-calls';
import Paso1icon from '../img/paso1-icon.svg';
import { Helmet } from 'react-helmet';

/*TODO estos datos tiene que devolverlos la api. */
const days = [
  { name: '1', code: '0' },
  { name: '2', code: '1' },
  { name: '3', code: '2' },
  { name: '4', code: '3' },
  { name: '5', code: '4' },
  { name: '6', code: '5' },
  { name: '7', code: '6' },
  { name: '8', code: '7' },
  { name: '9', code: '8' },
  { name: '10', code: '9' },
  { name: '11', code: '10' },
  { name: '12', code: '11' },
  { name: '13', code: '12' },
  { name: '14', code: '13' },
  { name: '15', code: '14' },
  { name: '16', code: '15' },
  { name: '17', code: '16' },
  { name: '18', code: '17' },
  { name: '19', code: '18' },
  { name: '20', code: '19' },
  { name: '21', code: '20' },
  { name: '22', code: '21' },
  { name: '23', code: '22' },
  { name: '24', code: '23' },
  { name: '25', code: '24' },
  { name: '26', code: '25' },
  { name: '27', code: '26' },
  { name: '28', code: '27' },
  { name: '29', code: '28' },
  { name: '30', code: '29' },
  { name: '31', code: '30' },
];

/*TODO estos datos tiene que devolverlos la api. */
const meses = [
  { name: 'Enero', code: '0' },
  { name: 'Febrero', code: '1' },
  { name: 'Marzo', code: '2' },
  { name: 'Abril', code: '3' },
  { name: 'Mayo', code: '4' },
  { name: 'Junio', code: '5' },
  { name: 'Julio', code: '6' },
  { name: 'Agosto', code: '7' },
  { name: 'Septiembre', code: '8' },
  { name: 'Octubre', code: '9' },
  { name: 'Noviembre', code: '10' },
  { name: 'Diciembre', code: '11' },
];
/*TODO estos datos tiene que devolverlos la api, calculando los años del rango de edad de 1-12. Hasta que la api lo mande, tiene que calcularlo el front*/
const years = [
  { name: '2021', code: '0' },
  { name: '2020', code: '1' },
  { name: '2019', code: '2' },
  { name: '2018', code: '3' },
  { name: '2017', code: '4' },
  { name: '2016', code: '5' },
  { name: '2015', code: '6' },
  { name: '2014', code: '7' },
  { name: '2013', code: '8' },
  { name: '2012', code: '9' },
  { name: '2011', code: '10' },
  { name: '2010', code: '11' },
  { name: '2009', code: '12' },
];

const ColorFilter = ({ id, isActive, onClick, colorPicker }) => {
  return (
    <>
      <div
        style={{ backgroundColor: colorPicker }}
        className={`col-3 color-select ${isActive ? 'selected' : ''}`}
        onClick={() => onClick(id)}
      ></div>
    </>
  );
};

const useColorApi = (endpoint) => {
  const [data, setData] = useState();

  useEffect(() => {
    let isActive = true;
    const getColorData = async () => {
      const colors = await getEndpointItemsWithPagination(endpoint);
      colors.sort((a, b) => a.position - b.position);
      if (isActive) {
        setData(colors);
      }
    };

    getColorData();

    return () => {
      isActive = false;
    };
  }, [endpoint]);

  return [data];
};

function Paso1() {
  const [kidInfo, setKidInfo] = useContext(KidInfoContext);
  const [coloresOjos] = useColorApi('eyes_colors');
  const [coloresPelo] = useColorApi('hair_colors');
  const [coloresPiel] = useColorApi('skin_colors');

  const [birthday, setBirthday] = useState(kidInfo.birthday);
  const [eyesColor, setEyesColor] = useState(kidInfo.eyesColor);
  const [hairColor, setHairColor] = useState(kidInfo.hairColor);
  const [skinColor, setSkinColor] = useState(kidInfo.skinColor);

  let dayKidInfo = birthday?.substring(8) || '';
  if (dayKidInfo.substring(0, 1) === '0') {
    dayKidInfo = dayKidInfo.substring(1);
  }
  dayKidInfo = days.find((day) => day.name === dayKidInfo);
  const [day, setDayValue] = useState(dayKidInfo);

  let monthKidInfo = birthday?.substring(5, 7) || '';
  if (monthKidInfo.substring(0, 1) === '0') {
    monthKidInfo = monthKidInfo.substring(1);
  }
  monthKidInfo = meses.find(
    (month) => month.code === '' + (Number(monthKidInfo) - 1)
  );
  const [month, setMonthValue] = useState(monthKidInfo);

  let yearKidInfo = birthday?.substring(0, 4) || '';
  yearKidInfo = years.find((year) => year.name === yearKidInfo);
  const [year, setYearValue] = useState(yearKidInfo);
  const history = useHistory();

  const { minDate, maxDate } = getAllowedDateRange();
  let fechaIncorrecta = false;
  if (birthday) {
    const birthdayDate = new Date(birthday);
    const minDateObj = new Date(minDate);
    const maxDateObj = new Date(maxDate);

    if (birthdayDate < minDateObj || birthdayDate > maxDateObj) {
      fechaIncorrecta = true;
    }
  }

  useEffect(() => {
    let monthV = Number(month?.code);
    let yearV = Number(year?.name);
    // if (month && year) {
    //   const calculateDaysInMonth = (y, m) => new Date(y, m, 0).getDate();
    //   const daysInMonth = calculateDaysInMonth(yearV, monthV + 1);
    //   const days = [];
    //   for (let i = 1; i <= daysInMonth; i++) {
    //     days.push({ name: '' + i, code: '' + i });
    //   }
    //   setDays(days);
    // }

    if (day && month && year) {
      let date = new Date(yearV, monthV, Number(day.name) + 1);
      setBirthday(date.toISOString().substring(0, 10));
    }
  }, [year, month, day, setBirthday]);

  const updateDay = (value) => {
    setDayValue(value.target.value);
  };
  const updateMonth = (value) => {
    setMonthValue(value.target.value);
  };

  const updateYear = (value) => {
    setYearValue(value.target.value);
  };

  const isValidForm =
    eyesColor !== kidInfoInitialState.eyesColor &&
    hairColor !== kidInfoInitialState.hairColor &&
    skinColor !== kidInfoInitialState.skinColor &&
    birthday !== kidInfoInitialState.birthday &&
    year &&
    month &&
    day &&
    !fechaIncorrecta;

  return (
    <>
      <Helmet>
        <title>Cuéntanos un poco sobre tu niño - Dresskids</title>
      </Helmet>
      <div className="container pt-2 pt-md-5 pb-5">
        <Whatsapp />
        <Link to={`${kidInfo.name ? PATH_PAGINA_INICIO : '/paso0'}`}>
          <i className="bi bi-arrow-left fs-3"></i>
        </Link>
        <div className="d-flex justify-content-center">
          <img src={Paso1icon} alt="Icono niño-niña" />
        </div>
        <h1 className="h1 text-center">
          Cuéntanos un poco sobre tu {kidInfo.gender === 1 ? 'niño' : 'niña'}
        </h1>

        <div className="row d-flex justify-content-center mt-4">
          <div className="col-12 col-sm-9 col-md-6 col-lg-4">
            <div className="row mt-4">
              <div className="col-12 mt-2 mb-0">
                <p>Fecha de nacimiento</p>
              </div>

              <div className="col-12 col-md-4 form-floating mb-3">
                <SelectAtom
                  id="año"
                  fullWidth={true}
                  label="Año"
                  placeholder="Año"
                  items={years}
                  onChange={updateYear}
                  defaultValue={year}
                />
              </div>

              <div className="col-12 col-md-4 form-floating mb-3">
                <SelectAtom
                  id="mes"
                  label="Mes"
                  placeholder="Mes"
                  fullWidth={true}
                  items={meses}
                  onChange={updateMonth}
                  defaultValue={month}
                  error={fechaIncorrecta && { message: 'máx. 12 años' }}
                />
              </div>

              <div className="col-12 col-md-4 form-floating mb-3">
                <SelectAtom
                  id="dia"
                  fullWidth={true}
                  label="Día"
                  placeholder="Día"
                  items={days}
                  onChange={updateDay}
                  defaultValue={day}
                />
              </div>
              <div className="col-12 mt-4">
                <p className="mb-3 h4 text-center">Color de ojos</p>
                <div className="d-flex justify-content-around">
                  {coloresOjos &&
                    coloresOjos.map((ojos) => (
                      <ColorFilter
                        key={ojos.id}
                        id={ojos.id}
                        colorPicker={ojos.colorPicker}
                        isActive={eyesColor === ojos.id}
                        onClick={() => setEyesColor(ojos.id)}
                      />
                    ))}
                </div>
              </div>
              <div className="col-12 mt-5">
                <p className="mb-3 h4 text-center">Color de pelo</p>
                <div className="d-flex justify-content-around">
                  {coloresPelo &&
                    coloresPelo.map((pelo) => (
                      <ColorFilter
                        key={pelo.id}
                        id={pelo.id}
                        colorPicker={pelo.colorPicker}
                        isActive={hairColor === pelo.id}
                        onClick={() => setHairColor(pelo.id)}
                      />
                    ))}
                </div>
              </div>
              <div className="col-12 mt-5">
                <p className="mb-4 h4 text-center">Color de piel</p>
                <div className="d-flex justify-content-around">
                  {coloresPiel &&
                    coloresPiel.map((piel) => (
                      <ColorFilter
                        key={piel.id}
                        id={piel.id}
                        colorPicker={piel.colorPicker}
                        isActive={skinColor === piel.id}
                        onClick={() => setSkinColor(piel.id)}
                      />
                    ))}
                </div>
              </div>
              <div className="col-12 mt-5 d-flex justify-content-center">
                <PrimaryButton
                  onClick={() => {
                    setKidInfo((prev) => ({
                      ...prev,
                      birthday,
                      eyesColor,
                      hairColor,
                      skinColor,
                    }));
                    history.push('/paso2');
                  }}
                  disabled={!isValidForm}
                  value="Continuar"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Paso1;
