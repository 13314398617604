import { useContext, useState } from 'react';
import { PrimaryButton } from '../ui/atoms/Button';
import { InputText } from '../ui/atoms/Input';
import { Link } from 'react-router-dom';
import { CheckBoxAtom } from '../ui/atoms/Checkbox';
import { useHistory } from 'react-router';
import { API_URL, TOKEN_ENDPOINT } from '../constants';
import AuthContext from '../contexts/AuthContext';
import KidInfoContext from '../contexts/KidInfoContext';
import Whatsapp from '../components/Whatsapp';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { saveToLocalStorage } from '../services/local-storage-manager';
import Paso9icon from '../img/paso9-icon.svg';
import { Helmet } from 'react-helmet';

function Paso9() {
  const [, setAuth] = useContext(AuthContext);
  const [kidInfo, setKidInfo] = useContext(KidInfoContext);
  const [childNameValue, setChildNameValue] = useState(kidInfo.name);
  const [emailValue, setEmailValue] = useState('');
  const [validEmail, setValidEmail] = useState(true);
  const [passValue, setPassValue] = useState('');
  const [validPass, setValidPass] = useState(true);
  const [conditionsStatus, setConditionsValue] = useState(false);
  const [newsletter, setNewsletterValue] = useState(false);
  const [fieldsWithError] = useState({});
  const history = useHistory();
  const MySwal = withReactContent(Swal);

  const updateNameValue = (field) => {
    setChildNameValue(field.value);
  };

  const updateMailValue = (field) => {
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(field.value)
      ? setValidEmail(true)
      : setValidEmail(false);
    setEmailValue(field.value);
  };

  const updatePassValue = (field) => {
    /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/.test(
      field.value
    ) || field.value === ''
      ? setValidPass(true)
      : setValidPass(false);
    setPassValue(field.value);
  };

  const acceptConditions = (value) => {
    setConditionsValue(value);
  };
  const acceptNewsletter = (value) => {
    setNewsletterValue(value);
  };

  const data = {
    email: emailValue,
    password: passValue,
    newsletter: newsletter
  };

  return (
    <>
      <Helmet>
        <title>Registro - Dresskids</title>
      </Helmet>

      <div className="container pt-2 pt-md-5 pb-5">
        <Whatsapp />
        <Link to="/paso8">
          <i className="bi bi-arrow-left fs-3"></i>
        </Link>
        <div className="d-flex justify-content-center">
          <img src={Paso9icon} alt="Icono" />
        </div>
        <h1 className="h1 text-center mb-0">¡Ya casi hemos terminado!</h1>
        <div className="row d-flex justify-content-center mt-5">
          <div className="col-12 col-sm-9 col-md-6 col-lg-4">
            <div className="row d-flex justify-content-center">
              <div className="col-12 form-floating mb-4">
                <InputText
                  onChange={updateNameValue}
                  error={fieldsWithError['name']}
                  name="name"
                  label={`Nombre de tu ${
                    kidInfo.gender === 2 ? 'niña' : 'niño'
                  }`}
                  placeholder={`Introduce el nombre de tu ${
                    kidInfo.gender === 2 ? 'niña' : 'niño'
                  }`}
                  required={true}
                  value={childNameValue}
                />
              </div>
              <div className="col-12 form-floating mb-4">
                <InputText
                  onChange={updateMailValue}
                  error={
                    fieldsWithError['email'] ||
                    (!validEmail && {
                      message: 'Email no válido',
                    })
                  }
                  type="email"
                  name="email"
                  label="Email"
                  placeholder="Introduce email"
                  required={true}
                  value={emailValue}
                />
              </div>
              <div className="col-12 form-floating mb-3">
                <InputText
                  onChange={updatePassValue}
                  error={
                    fieldsWithError['password'] ||
                    (!validPass && {
                      message:
                        'Contraseña no válida (mín.8 caract., 1 may, 1 num y un símbolo)',
                    })
                  }
                  type="password"
                  name="password"
                  label="Contraseña"
                  placeholder="Introduce contraseña"
                  required={true}
                  value={passValue}
                />
              </div>

              <div className="col-12 form-check mb-3">
                <CheckBoxAtom
                  onChange={acceptConditions}
                  value={conditionsStatus}
                />
                <span>
                  He leído, entendido y acepto las{' '}
                  <Link
                    to="/condiciones-de-contratacion"
                    target="_blank"
                    rel="noreferrer"
                    class="lightLink fw-bold"
                  >
                    condiciones de contratación
                  </Link>
                </span>
              </div>

              <div className="col-12 form-check mb-3">
                <CheckBoxAtom
                  onChange={acceptNewsletter}
                  value={newsletter}
                />
                <span>
                  Deseo recibir comunicaciones de Dress Kids Store, S.L.
                </span>
              </div>

              <div className="col-12 mt-4 d-flex justify-content-center">
                <div className="w-100 d-flex justify-content-center">
                  <PrimaryButton
                    disabled={
                      !conditionsStatus ||
                      !emailValue ||
                      !childNameValue ||
                      !passValue
                    }
                    onClick={async () => {
                      let correct = true;
                      await MySwal.fire({
                        html: 'Registrando usuario...',
                        icon: 'info',
                        didOpen: async () => {
                          Swal.showLoading();
                          // TODO how to avoid memory leak on this fetch
                          const userCreateResponse = await fetch(
                            `${API_URL}/users`,
                            {
                              method: 'POST',
                              headers: {
                                'Content-Type': 'application/json',
                              },
                              body: JSON.stringify(data),
                            }
                          );
                          const userCreateJson =
                            await userCreateResponse.json();
                          // console.log(userCreateJson, 'userCreateJson');
                          // console.log(userCreateResponse, 'userCreateResponse');
                          if (
                            userCreateResponse.status !== 200 &&
                            userCreateResponse.status !== 201
                          ) {
                            correct = false;
                            MySwal.fire({
                              html: 'Usuario ya registrado',
                              /* icon: 'error', */
                            });
                            return;
                          }
                          // TODO how to avoid memory leak on this fetch
                          const tokenResponse = await fetch(
                            `${TOKEN_ENDPOINT}`,
                            {
                              method: 'POST',
                              headers: {
                                'Content-Type': 'application/json',
                              },
                              body: JSON.stringify(data),
                            }
                          );
                          const tokenData = await tokenResponse.json();
                          const { id, email } = userCreateJson;
                          setAuth({
                            user: { id, email },
                            token: tokenData.token,
                          });
                          saveToLocalStorage({ id, email }, tokenData.token);

                          // TODO how to avoid memory leak on this fetch
                          await fetch(`${API_URL}/addresses`, {
                            method: 'POST',
                            headers: {
                              'Content-Type': 'application/json',
                              Authorization: `Bearer ${tokenData.token}`,
                            },
                            body: JSON.stringify({
                              address: '',
                              postalCode: '',
                              city: '',
                              country: '',
                              observations: '',
                            }),
                          });
                          // TODO check response correct
                          // createAddressResponse.status ...

                          if (kidInfo.creating) {
                            // TODO how to avoid memory leak on this fetch
                            const createKid = await fetch(`${API_URL}/kids`, {
                              method: 'POST',
                              headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${tokenData.token}`,
                              },
                              body: JSON.stringify({
                                name: childNameValue,
                                gender: '/api/genders/' + kidInfo.gender,
                                birthday: kidInfo.birthday,
                                eyesColor:
                                  '/api/eyes_colors/' + kidInfo.eyesColor,
                                size: '/api/sizes/' + kidInfo.size,
                                hairColor:
                                  '/api/hair_colors/' + kidInfo.hairColor,
                                skinColor:
                                  '/api/skin_colors/' + kidInfo.skinColor,
                                styleClothing:
                                  '/api/style_clothings/' +
                                  kidInfo.styleClothing,
                              }),
                            });
                            const createKidJson = await createKid.json();
                            setKidInfo((prev) => ({
                              ...prev,
                              id: createKidJson.id,
                            }));
                          }
                          setKidInfo((prev) => ({
                            ...prev,
                            name: childNameValue,
                          }));
                          MySwal.close();
                        },
                      });
                      if (correct) {
                        history.push('/paso10');
                      }
                    }}
                    value="Crear cuenta y continuar"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Paso9;
