import React, { useContext, useState } from 'react';
import { PrimaryButton } from '../ui/atoms/Button';
import { Link } from 'react-router-dom';
import KidInfoContext from '../contexts/KidInfoContext';
import Whatsapp from '../components/Whatsapp';
import Paso7icon from '../img/paso7-icon.svg';
import { Helmet } from 'react-helmet';

function Paso7() {
  const [kidInfo, setKidInfo] = useContext(KidInfoContext);
  const [observations, setObservations] = useState(kidInfo.observations);

  const handleOnChange = (e) => {
    setObservations(e.target.value);
  };

  console.log(kidInfo, "kidInfo")

  return (
    <>
      <Helmet>
        <title>Déjanos algún comentario que nos ayude - Dresskids</title>
      </Helmet>
      <div className="container pt-2 pt-md-5 pb-5">
        <Whatsapp />
        <Link to="/paso6">
          <i className="bi bi-arrow-left fs-3"></i>
        </Link>

        <div className="d-flex justify-content-center">
          <img src={Paso7icon} alt="Icono" />
        </div>
        <h1 className="h1 text-center mb-0 texto-largo">
          Déjanos algún comentario que nos ayude a acertar con las prendas que
          más le favorezcan
        </h1>

        <div className="row d-flex justify-content-center mt-5">
          <div className="col-12 col-md-6">
            <div className="row d-flex justify-content-center">
              <div className="col-12">
                <p className="fw-bold">Por ejemplo:</p>
                <div className="row">
                  <div className="col-10 ">
                    <p className="">
                      Los pantalones elásticos le quedan mejor.
                    </p>
                    <p className="">
                      Por altura y complexión, prefiero pantalones pitillo.
                    </p>
                    <p className="">
                      Pasa mucho calor en el cole, prefiero que lleve camisetas
                      de manga corta.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-5">
                <div className="form">
                  <textarea
                    value={observations}
                    onChange={handleOnChange}
                    className="form-control"
                    placeholder="Introduce un comentario..."
                    id="comentario"
                    rows="5"
                  ></textarea>
                </div>
              </div>

              <div className="col-12 mt-4 d-flex justify-content-center">
                <Link
                  className="w-100 d-flex justify-content-center"
                  to="/paso8"
                >
                  <PrimaryButton
                    value="Continuar"
                    onClick={() => {
                      setKidInfo((prev) => ({
                        ...prev,
                        observations,
                      }));
                    }}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Paso7;
