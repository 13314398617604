import Footer from '../components/footer';
import Header from '../components/Header';
import Whatsapp from '../components/Whatsapp';
import { useForm } from 'react-hook-form';
import { init, sendForm } from 'emailjs-com';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

function Contacto() {
  const [contactNumber, setContactNumber] = useState('000000');
  const { register, handleSubmit, watch, errors } = useForm();
  /*   const onSubmit = (data) => console.log(data); */
  init('7bFfxzMmur-88LYuQ');

  const generateContactNumber = () => {
    const numStr = '000000' + ((Math.random() * 1000000) | 0);
    setContactNumber(numStr.substring(numStr.length - 6));
  };

  const onSubmit = (data) => {
    console.log(data, 'data');
    generateContactNumber();
    sendForm('default_service', 'template_sy4v2sg', '#contact-form').then(
      function (response) {
        console.log('SUCCESS!', response.status, response.text);
        alert('¡Mensaje enviado correctamente!');
      },
      function (error) {
        console.log('FAILED...', error);
        alert('Ha ocurrido un error. Vuelve a intentarlo');
      }
    );
  };

  return (
    <>
      <Helmet>
        <title>Contacto</title>
      </Helmet>
      <Header isHome={false} />
      <Whatsapp />
      <div className="container mt-5 mb-md-5 heigh">
        <h1 className="h1 text-center">Contacto</h1>
        
        <div className="mt-5 row justify-content-center">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5">
          <p className='text-center'>Déjanos cualquier duda o comentario a través de nuestro formulario, nuestro equipo te responderá a la mayor brevedad, te recordamos que también puedes contactarnos a través del chat ¡Gracias!</p>
            <form id="contact-form" onSubmit={handleSubmit(onSubmit)}>
              <input
                type="hidden"
                name="contact_number"
                value={contactNumber}
              />
              <input
                type="text"
                name="user_name"
                {...register('name', {
                  required: 'Required',
                })}
                placeholder="Nombre"
                maxLength="30"
              />
              <br />
              <input
                type="email"
                name="user_email"
                {...register('email', {
                  required: 'Required',
                })}
                placeholder="Email"
              />
              <br />
              <textarea
                name="message"
                {...register('message', {
                  required: 'Required',
                })}
                placeholder="Mensaje"
              />
              <br />
              <div className="d-flex justify-content-center">
                <input
                  className="btn btn-primary mt-3"
                  type="submit"
                  value="Enviar"
                />
              </div>
            </form>
            <p id="text"></p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default Contacto;
