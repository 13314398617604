import { useContext, useEffect, useState } from 'react';
import Header from '../Header';
// import { PrimaryButton, SecondaryButton } from "../../ui/atoms/button";
import { PrimaryButton } from '../../ui/atoms/Button';
import Footer from '../footer';
import './checkout.css';
// import { ItemSeleccionable } from "../../ui/atoms/itemSeleccionable";
// import SimpleModal from '../../ui/atoms/Modal';
import Whatsapp from '../Whatsapp';
import { useParams } from 'react-router-dom';
import AuthContext from '../../contexts/AuthContext';
import { API_URL } from '../../constants';

function CheckoutFinalizado() {
  const [auth] = useContext(AuthContext);
  const [items, setItems] = useState([]);
  const [details, setDetails] = useState({});
  // const [modalState, setModalState] = useState(false);
  // const resetModalState = () => {
  //   setModalState(false);
  // };
  const { packageId } = useParams();

  const itemsDevueltos = items.filter((i) => i.quantityAccepted === 0);
  const itemsAceptados = items.filter((i) => i.quantityAccepted === 1);

  console.log(items, 'items');
  console.log(itemsAceptados, 'itemsAceptados');
  console.log(details, 'details');

  useEffect(() => {
    let isActive = true;
    // console.log('lets go ' + packageId);

    const getPedidoData = async () => {
      const pedidosResponse = await fetch(`${API_URL}/packages/${packageId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });

      const pedidosData = await pedidosResponse.json();

      const items = pedidosData.packageItems.map((pi) => ({
        ...pi.item,
        packageItemId: pi['@id'].substring('/api/package_items/'.length),
        price: pi.price,
        quantityAccepted: pi.quantityAccepted,
      }));

      const details = pedidosData;

      if (isActive) {
        //console.log({ items, details } , "items y details");
        setItems(items);
        setDetails(details);
      }
    };

    getPedidoData();

    return () => {
      isActive = false;
    };
  }, [auth.token, packageId]);

  return (
    <div>
      <Header isHome={false} />
      <Whatsapp />
      <div className="container pt-5 pb-5 ">
        <h1 className="h3 mb-4">Proceso check out finalizado</h1>

        {itemsDevueltos && itemsDevueltos.length > 0 && (
          <div className="row mb-4">
            <p>
              Para devolver las prendas que no quieres, solo tienes que darle al
              botón que te llevará a la página de Seur y seguir los pasos
              indicados.
            </p>

            <div className="d-flex  mb-5">
              <a
                target="_blank"
                href="https://www.seur.com/devoluciones/pages/devolucionInicio.do?id=1ffb041d-8967-4bb3-8c7a-16c7e01b903d"
                rel="noreferrer"
              >
                <PrimaryButton value="Devolución" />
              </a>
            </div>

            <p className="fw-bold fontSecondary">Prendas a devolver:</p>
            {itemsDevueltos.map((prenda) => {
              return (
                <div
                  key={prenda['@id']}
                  className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4"
                >
                  <div className="checkout-box">
                    <p className="fw-bold fontSecondary">
                      {prenda.description}
                    </p>
                    <p>Precio: {prenda.price}€</p>
                    <p>Ref.: {prenda.ean}</p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {itemsAceptados && itemsAceptados.length > 0 && (
          <div className="row mb-4">
            <p className="fw-bold fontSecondary">
              Prendas con las que te quedas:
            </p>
            {itemsAceptados.map((prenda) => {
              return (
                <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
                  <div className="checkout-box">
                    <p className="fw-bold fontSecondary">
                      {prenda.description}
                    </p>
                    <p>Precio: {prenda.price}€</p>
                    <p>Ref.: {prenda.ean}</p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <p className="fs-4 text-center mb-4">
          <span className="fontSecondary fw-bold ">Total:</span>{' '}
          {details && details.total}€
        </p>
        <p className="text-center h3 mt-3">
          ¡Muchas gracias por confiar en DressKids!
        </p>

        {/* <SimpleModal
          openmodal={modalState}
          onClose={resetModalState}
          text="Recomienda DressKids a tus amigos"
        />

        <div className="row d-flex justify-content-center mt-5">
          <div className="col-12 col-md-7 col-lg-5">
            <div className="row ">
              <div className="col-12">
                <p>
                  ¿Qué te ha parecido la experiencia de comprar en DressKids?
                </p>
              </div>
              <div className="col-6">
                <div className={`filterPill `}>Me ha gustado</div>
              </div>
              <div className="col-6">
                <div className={`filterPill `}>No me ha gustado</div>
              </div>
              <div className="col-12 mt-4">
                <p>¿Volverías a realizar un pedido?</p>
              </div>
              <div className="col-6">
                <div className={`filterPill `}>Sí</div>
              </div>
              <div className="col-6">
                <div className={`filterPill `}>No</div>
              </div>
              <div className="col-12 mt-4">
                <p>
                  Puedes dejarnos un comentario para que podamos seguir
                  mejorando:
                </p>
                <div class="form">
                  <textarea
                    class="form-control"
                    placeholder="Añade un comentario aquí"
                    id="comentario"
                    rows="5"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12 d-flex justify-content-center">
            <PrimaryButton value="Enviar" />
          </div>
        </div> */}
      </div>
      <Footer />
    </div>
  );
}

export default CheckoutFinalizado;
