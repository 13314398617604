import { createContext } from 'react';

export const kidInfoInitialState = {
  id: null,
  creating: false,
  name: '',
  gender: null,
  birthday: '',
  age: '',
  eyesColor: null,
  hairColor: null,
  skinColor: null,
  colorClothing: null,
  typeClothing: null,
  styleClothing: null,
  sizes: {},
  garmentsValues: {},
  packageType: null,
  garments: [],
  garmentNotWanted: [],
  observations: '',
  outlet: false
};

const KidInfoContext = createContext([kidInfoInitialState, () => {}]);

export default KidInfoContext;
