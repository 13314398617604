export const getAgeFromBirthdate = (birthdateString) => {
  const today = new Date();
  const birthDate = new Date(birthdateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const getAllowedDateRange = () => {
  const MAX_AGE = 12;
  const MIN_AGE = 1;
  // let minDate = new Date();
  // minDate.setFullYear(minDate.getFullYear() - MAX_AGE);
  // let maxDate = new Date();
  // // maxDate.setFullYear(maxDate.getFullYear() - MIN_AGE);
  // minDate.setFullYear(minDate.getFullYear() - (MAX_AGE+1));
  // return { minDate, maxDate };

  let minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - MAX_AGE - 1);

  let maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - MIN_AGE);
  return { minDate, maxDate };
};
