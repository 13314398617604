import Footer from '../footer';
import Header from '../Header';
import Whatsapp from '../Whatsapp';

function PoliticaDeCookies() {
  return (
    <>
      <Header isHome={false}/>
      <Whatsapp/>
      <div className="container mt-5 mb-md-5">
        <h1 className="h1">Política de cookies</h1>
        <p></p>
        <h2 className="h3">
          1. INFORMACIÓN SOBRE LA UTILIZACIÓN DE COOKIES - LEY DE COOKIES
        </h2>
        <p>
          En esta Web se pueden estar utilizando cookies en algunas páginas. La
          finalidad de dichas cookies es mejorar el servicio que ofrecen a sus
          clientes y a nuestros visitantes. En caso de ser instaladas, el
          administrador de esta Web le solicitará su consentimiento para
          hacerlo, y tendrá opción de aceptarlo.
        </p>
        <p>
          Art. 22 LSSI.- “Los prestadores de servicios podrán utilizar
          dispositivos de almacenamiento y recuperación de datos en equipos
          terminales de los destinatarios, a condición de que los mismos hayan
          dado su consentimiento después de que se les haya facilitado
          información clara y completa sobre su utilización, en particular,
          sobre los fines del tratamiento de los datos, con arreglo a lo
          dispuesto en la Ley Orgánica 15/1999, de 13 de diciembre, de
          Protección de Datos de Carácter Personal, Cuando sea técnicamente
          posible y eficaz, el consentimiento del destinatario para aceptar el
          tratamiento de los datos podrá facilitarse mediante el uso de los
          parámetros adecuados del navegador o de otras aplicaciones, siempre
          que aquél deba proceder a su configuración durante su instalación o
          actualización mediante una acción expresa a tal efecto. Lo anterior no
          impedirá el posible almacenamiento o acceso de índole técnica al solo
          fin de efectuar la transmisión de una comunicación por una red de
          comunicaciones electrónicas o, en la medida que resulte estrictamente
          necesario, para la prestación de un servicio de la sociedad de la
          información expresamente solicitado por el destinatario.”
        </p>
        <p>
          Una "Cookie" es un pequeño archivo que se almacena en el ordenador del
          usuario y nos permite reconocerle, obtener información sobre tus
          hábitos de navegación o personalizar la forma en que se muestra el
          contenido. El conjunto de "cookies" nos ayuda a mejorar la calidad de
          nuestra web, permitiéndonos controlar qué páginas encuentran nuestros
          usuarios útiles y cuáles no.
        </p>
        <p>
          Quedan excluidas del ámbito de aplicación del artículo 22.2 de la
          LSSI:
        </p>
        <ul>
          <li>Cookies de entrada del usuario</li>
          <li>Cookies de autenticación o identificación del usuario</li>
          <li>Cookies de sesión de reproductor multimedia</li>
          <li>Cookies de sesión para equilibrar la carga</li>
          <li>Cookies de personalización de la interfaz de usuario</li>
          <li>
            Determinadas cookies de completamente para intercambiar contenidos
            sociales.
          </li>
        </ul>
        <p>
          Por ello este sitio web puede utilizarlas ya que permiten el
          funcionamiento y la presentación de los servicios ofrecidos en el
          mismo.
        </p>
        <p> </p>
        <h2 className="h3">
          2. ADMINISTRACIÓN DE COOKIES Y REVOCACIÓN DEL CONSENTIMIENTO PRESTADO
        </h2>
        <p>
          Le informamos que usted puede{' '}
          <span>rechazar el uso e instalación de las cookies</span> de esta web
          cambiando la configuración de su navegador, pudiendo seguir usando
          este sitio web, también le advertimos que puede verse limitada la
          experiencia de uso de esta web si cambia algunas de las opciones que
          le damos:
        </p>
        <p> </p>
        <h2  className="h3">
         3. ADMINISTRACIÓN DE COOKIES POR NAVEGADOR:
        </h2>
        <ul>
          <li>
            <strong>Google Chrome,</strong>{' '}
            <a
              href="http://support.google.com/chrome/bin/answer.py?hl=es&amp;answer=95647"
              target="_blank"
              rel="noreferrer"
              className="lightLink"
            >
              http://support.google.com/chrome/bin/answer.py?hl=es&amp;answer=95647
            </a>
          </li>
        </ul>
        <ul>
          <li>
            <strong>Explorer, </strong>
            <a
              href="http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9"
              target="_blank"
              rel="noreferrer"
              className="lightLink"
            >
              http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9
            </a>
          </li>
        </ul>
        <ul>
          <li>
            <strong>Firefox, </strong>
            <a
              href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we"
              target="_blank"
              rel="noreferrer"
              className="lightLink"
            >
              http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we
            </a>
          </li>
        </ul>
        <ul>
          <li>
            <strong>Safari, </strong>
            <a
              href="http://support.apple.com/kb/ph5042"
              target="_blank"
              rel="noreferrer"
              className="lightLink"
            >
              http://support.apple.com/kb/ph5042
            </a>
          </li>
        </ul>
        <p>
          Muchos navegadores permiten activar un{' '}
          <span>
            <strong>modo privado</strong>
          </span>{' '}
          mediante el cual las cookies se borran siempre después de su visita.
          Dependiendo de cada navegador, este modo privado puede tener
          diferentes nombres, abajo puede encontrar una lista de los navegadores
          más comunes y los diferentes nombres de este “modo privado”:
        </p>
        <ul>
          <li>Internet Explorer v11.0.205: in private.</li>
          <li>
            FireFox <span>2.0.0.14 </span>y superior: Navegación Privada.
          </li>
          <li>Google Chrome 84.0.4147.135 y superior: Incógnito.</li>
          <li>Safari 13.1.1: Navegación Privada.</li>
          <li>Opera 10.5 y superior: Navegación Privada.</li>
          <li>Microsoft Edge: 85.0.564.41: in private</li>
        </ul>
        <p>
          Por favor, lea atentamente la sección de ayuda de su navegador para
          conocer más acerca de cómo activar el “modo privado”. Podrá seguir
          visitando nuestra Web aunque su navegador esté en “modo privado”, no
          obstante, la experiencia de usuario puede no ser óptima y algunas
          utilidades pueden no funcionar.
        </p>
        <p>
          Si necesita más información acerca del bloqueo en su equipo de
          cookies, no dude en atender la explicación que se le da en este enlace
          Web:
          http://windows.microsoft.com/es-es/windows-vista/block-or-allow-cookies
        </p>
        <p>
          Aunque las cookies son esenciales para el funcionamiento de internet,
          aportando innumerables ventajas en la prestación de servicios
          interactivos, facilitándole la navegación y el uso de nuestra web, las
          cookies no pueden dañar su equipo y que, a cambio, el que estén
          activadas nos ayudan a identificar y resolver los errores.
        </p>
        <p> </p>
        <h2  className="h3">
          4. TIPOS DE COOKIES:
        </h2>
        <p>
          La información que le proporcionamos a continuación, le ayudará a
          comprender los diferentes tipos de cookies.
        </p>
        <ul>
          <li>
            Según quien sea la <strong>entidad</strong> que gestione las
            cookies:
          </li>
        </ul>
        <ul>
          <li>
            <strong>Cookies propias</strong>: son aquellas que se envían al
            equipo terminal del usuario desde un equipo o dominio gestionado por
            el propio editor y desde el que presta el servicio solicitado por el
            usuario.
          </li>
          <li>
            <strong>Cookies de tercero</strong>: son aquellas que se envían al
            equipo terminal del usuario desde un equipo o dominio que no es
            gestionado por el editor, sino por otra entidad que trata los datos
            obtenidos a través de las cookies.
          </li>
        </ul>
        <ul>
          <li>
            Según su <strong>finalidad:</strong>
          </li>
        </ul>
        <ul>
          <li>
            <strong>Cookies técnicas</strong>: aquellas que permiten al usuario
            la navegación y la utilización de las diferentes opciones o
            servicios que en ella existen.
          </li>
          <li>
            <strong>Cookies de preferencias o personalización</strong>: aquellas
            que permiten recordar información para que el usuario acceda al
            servicio con determinadas características que puedan diferenciar su
            experiencia de la de otros usuarios (por ejemplo el idioma).
          </li>
          <li>
            <strong>Cookies de análisis o medición</strong>: aquellas que
            permiten al responsable el seguimiento y análisis del comportamiento
            de los usuarios de los sitios web a los que están vinculadas.
          </li>
          <li>
            <strong>Cookies de publicidad comportamental</strong>: son aquellas
            que almacena información del comportamiento de los usuarios obtenida
            a través de la observación continuada de sus hábitos de navegación.
          </li>
        </ul>
        <ul>
          <li>
            Según <strong>el tiempo</strong> que permanecen activadas en el
            equipo terminal podemos distinguir:
          </li>
        </ul>
        <ul>
          <li>
            <strong>Cookies de sesión</strong>: son cookies temporales que
            permanecen en el archivo de cookies de su navegador hasta que
            abandone la página web, por lo que ninguna queda registrada en el
            disco duro del usuario. La información obtenida por medio de estas
            cookies, sirven para analizar pautas de tráfico en la web. A la
            larga, esto nos permite proporcionar una mejor experiencia para
            mejorar el contenido y facilitando su uso.
          </li>
        </ul>
        <ul>
          <li>
            <strong>Cookies permanentes</strong>: son almacenadas en el disco
            duro y nuestra web las lee cada vez que usted realiza una nueva
            visita. Una web permanente posee una fecha de expiración
            determinada. La cookie dejará de funcionar después de esa fecha. Las
            utilizamos, generalmente, para facilitar los servicios de compra y
            registro.
          </li>
        </ul>
        <p>
          Además le informamos que las cookies permiten obtener la siguiente
          información:
        </p>
        <ul>
          <li>Fecha y hora de la última vez que el usuario visitó la Web</li>
          <li>
            Diseño de contenido que el usuario escogió en su primera visita a la
            web
          </li>
          <li>
            Elementos de seguridad que intervienen en el control de acceso a las
            áreas restringidas
          </li>
          <li>Otro tipo de información de determinadas cookies específicas</li>
        </ul>
        <p>
          En todo caso y en cumplimiento de la{' '}
          <strong>“Ley de Cookies” Ley 13/2012 de 30 de marzo</strong>, y del
          art. 22 de la LSSI, nuestra página Web, en caso de instalar alguna
          cookie <strong>le solicitará su consentimiento previo</strong> a la
          instalación de la misma en el mismo momento que indexe nuestra
          dirección Web.
        </p>
      </div>
      <Footer />
    </>
  );
}
export default PoliticaDeCookies;
