import { useContext, useEffect, useState } from 'react';
import { CounterButton, PrimaryButton } from '../ui/atoms/Button';
import { Link, useHistory } from 'react-router-dom';
import AuthContext from '../contexts/AuthContext';
import KidInfoContext from '../contexts/KidInfoContext';
import { API_URL } from '../constants';
import Whatsapp from '../components/Whatsapp';
import { getEndpointItemsWithPagination } from '../services/api-calls';
import PencilIcon from '../img/pencil.svg';
import { Helmet } from 'react-helmet';

const Counter = ({ id, addInSection, restInSection, name }) => {
  const [quantity, setQuantity] = useState(0);

  const add = () => {
    if (addInSection(name)) {
      setQuantity((prevQuantity) => prevQuantity + 1);
    }
  };

  const rest = () => {
    if (quantity > 0 && restInSection(name)) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  return (
    <div>
      <CounterButton onClick={() => rest(id)} value="-" className="ms-2" />
      <span>{quantity}</span>
      <CounterButton onClick={() => add(id)} value="+" className="me-2" />
    </div>
  );
};

const Section = ({ section, add, rest }) => {
  const [quantity, setQuantity] = useState(0);

  const addInSection = (name) => {
    if (add(name)) {
      setQuantity((prevQuantity) => prevQuantity + 1);
      return true;
    } else {
      return false;
    }
  };

  const restInSection = (name) => {
    if (quantity > 0 && rest(name)) {
      setQuantity((prevQuantity) => prevQuantity - 1);
      return true;
    } else {
      return false;
    }
  };

  return (
    <div key={section.id}>
      <div className="accordion-item w-100">
        <h2
          className="accordion-header"
          id={`panelsStayOpen-headingOne${section.id}`}
        >
          <button
            className="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#panelsStayOpen-collapse${section.id}`}
            aria-expanded="false"
            aria-controls={`panelsStayOpen-collapse${section.id}`}
          >
            <div className="d-flex justify-content-center align-items-center me-3 count">
              {quantity}
            </div>
            <span>{section.name}</span>
          </button>
        </h2>
      </div>
      <div
        id={`panelsStayOpen-collapse${section.id}`}
        className="accordion-collapse collapse show"
        aria-labelledby={`panelsStayOpen-headingOne${section.id}`}
      >
        <div className="row">
          {section.garments &&
            section.garments.map((garment) => {
              return (
                <div key={garment.id}>
                  <div className="accordion-body d-flex justify-content-between">
                    <span>{garment.name}</span>
                    <Counter
                      addInSection={addInSection}
                      restInSection={restInSection}
                      id={garment.id}
                      name={garment.name}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

const CollapsePrendas = ({
  sections,
  setCustomGarments,
  numberOfCustomGarments,
  setNumberOfCustomGarments,
  maxCustomGarments,
}) => {
  const add = (garment) => {
    if (numberOfCustomGarments + 1 <= maxCustomGarments) {
      setNumberOfCustomGarments((prev) => prev + 1);
      setCustomGarments((prev) => {
        const prevGarmentQuantity = prev[garment] || 0;
        return { ...prev, [garment]: prevGarmentQuantity + 1 };
      });
      return true;
    } else {
      return false;
    }
  };
  const rest = (garment) => {
    if (numberOfCustomGarments - 1 >= 0) {
      setNumberOfCustomGarments((prev) => prev - 1);
      setCustomGarments((prev) => {
        const prevGarmentQuantity = prev[garment] || 0;
        return { ...prev, [garment]: prevGarmentQuantity - 1 };
      });
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="accordion mt-3">
      {sections &&
        sections.map((section) => {
          return (
            <Section key={section.id} section={section} add={add} rest={rest} />
          );
        })}
    </div>
  );
};

function Paso8() {
  const [auth] = useContext(AuthContext);
  const [kidInfo, setKidInfo] = useContext(KidInfoContext);
  const [dresskids, setDresskids] = useState(false);
  const [personalizado, setPersonalizado] = useState(false);
  const [apiGarmentTypes, setApiGarmentTypes] = useState([]);
  const [customGarments, setCustomGarments] = useState({});
  const [numberOfCustomGarments, setNumberOfCustomGarments] = useState(0);
  const history = useHistory();

  console.log(kidInfo, "kidinfo paso 8")
  const MAX_CUSTOM_GARMENTS = 5;

  const isValid =
    dresskids ||
    (personalizado && numberOfCustomGarments === MAX_CUSTOM_GARMENTS);

  useEffect(() => {
    let isActive = true;

    const getSizes = async () => {
      // Get all garment types with the kid's gender
      let apiGarmentTypes = await getEndpointItemsWithPagination(
        'garment_types'
      );
      apiGarmentTypes = apiGarmentTypes.filter(
        (s) => s.gender['@id'] === `/api/genders/${kidInfo.gender}`
      );
      apiGarmentTypes.sort((a, b) => a.position - b.position);
      apiGarmentTypes = apiGarmentTypes.map((gt) => ({ ...gt, garments: [] }));

      // Get all garments with the kid's gender
      let apiGarmentsData = await getEndpointItemsWithPagination('garments');
      apiGarmentsData = apiGarmentsData.filter(
        (g) => g.type.gender === `/api/genders/${kidInfo.gender}`
      );
      apiGarmentsData = apiGarmentsData.filter(
        (g) => !kidInfo.garmentNotWanted.includes(g.name)
      );
      apiGarmentsData = apiGarmentsData.map((g) => ({
        ...g,
        id: Number(g['@id'].substring('/api/garments/'.length)),
      }));

      // Put array of sizes within corresponding garment type
      for (const apiGarmentDatum of apiGarmentsData) {
        for (const apiGarmentType of apiGarmentTypes) {
          if (apiGarmentDatum.type.id === apiGarmentType.id) {
            apiGarmentType.garments.push(apiGarmentDatum);
          }
        }
      }

      for (let index = apiGarmentTypes.length - 1; index >= 0; index--) {
        if (apiGarmentTypes[index].garments.length === 0) {
          apiGarmentTypes.splice(index, 1);
        }
      }

      if (isActive) {
        setApiGarmentTypes(apiGarmentTypes);
      }
    };

    getSizes();

    return () => {
      isActive = false;
    };
  }, [kidInfo.gender, kidInfo.sizes, kidInfo.garmentNotWanted]);

  const handleDresskidsClick = () => {
    setDresskids(!dresskids ? true : false);
    setPersonalizado(personalizado && false);
  };
  const handlePersonalizadoClick = () => {
    setDresskids(dresskids && false);
    setPersonalizado(!personalizado ? true : false);
  };

  return (
    <>
      <Helmet>
        <title>Escoge tu pack - Dresskids</title>
      </Helmet>

      <div className="container pt-2 pt-md-5 pb-5">
        <Whatsapp />
        <Link to="/paso7">
          <i className="bi bi-arrow-left fs-3"></i>
        </Link>
        <div className="d-flex justify-content-center">
          <img src={PencilIcon} alt="Icono lápiz" />
        </div>
        <h1 className="h1 text-center mb-0">Escoge tu pack</h1>
        <div className="row d-flex justify-content-center mt-5">
          <div className="col-12 col-md-6">
            <div className="row d-flex justify-content-center">
              <div className="col-6">
                <div
                  onClick={() => handleDresskidsClick()}
                  className={`filterPill ${dresskids && 'filterPill-selected'}`}
                >
                  Pack DressKids
                </div>
              </div>
              <div className="col-6">
                <div
                  onClick={() => handlePersonalizadoClick()}
                  className={`filterPill ${
                    personalizado && 'filterPill-selected'
                  }`}
                >
                  Pack Personalizado
                </div>
              </div>

              <div className="col-12 mt-5 d-flex align-items-center flex-column">
                {personalizado && (
                  <>
                    <p>
                      Selecciona 5 tipos de prendas que quieras que incluya el
                      pack:
                    </p>

                    <CollapsePrendas
                      sections={apiGarmentTypes}
                      setCustomGarments={setCustomGarments}
                      numberOfCustomGarments={numberOfCustomGarments}
                      setNumberOfCustomGarments={setNumberOfCustomGarments}
                      maxCustomGarments={MAX_CUSTOM_GARMENTS}
                    />
                  </>
                )}
                {dresskids && <p>¡Recibirás 5 prendas combinables entre si!</p>}
              </div>

              <div className="col-12 mt-5 d-flex justify-content-center">
                <div
                  className="w-100 d-flex justify-content-center"
                  to="/paso9"
                >
                  <PrimaryButton
                    value="Continuar"
                    disabled={!isValid}
                    onClick={async () => {
                      if (dresskids) {
                        setKidInfo((prev) => ({ ...prev, packageType: 2 }));
                        setKidInfo((prev) => ({ ...prev, garments: [] }));
                      } else if (personalizado) {
                        setKidInfo((prev) => ({ ...prev, packageType: 1 }));
                        const garments = [];
                        for (const customGarment in customGarments) {
                          if (customGarments[customGarment] !== 0) {
                            garments.push({
                              garment: customGarment,
                              quantity: customGarments[customGarment],
                            });
                          }
                        }
                        setKidInfo((prev) => ({ ...prev, garments }));
                      }

                      if (auth.user) {
                        if (kidInfo.creating) {
                          // TODO how to avoid memory leak in this fetch
                          const createKid = await fetch(`${API_URL}/kids`, {
                            method: 'POST',
                            headers: {
                              'Content-Type': 'application/json',
                              Authorization: `Bearer ${auth.token}`,
                            },
                            body: JSON.stringify({
                              name: kidInfo.name,
                              gender: '/api/genders/' + kidInfo.gender,
                              birthday: kidInfo.birthday,
                              eyesColor:
                                '/api/eyes_colors/' + kidInfo.eyesColor,
                              size: '/api/sizes/' + kidInfo.size,
                              hairColor:
                                '/api/hair_colors/' + kidInfo.hairColor,
                              skinColor:
                                '/api/skin_colors/' + kidInfo.skinColor,
                              styleClothing:
                                '/api/style_clothings/' + kidInfo.styleClothing,
                            }),
                          });
                          const createKidJson = await createKid.json();
                          setKidInfo((prev) => ({
                            ...prev,
                            id: createKidJson.id,
                          }));
                        }
                        history.push('/paso10');
                      } else {
                        history.push('/paso9');
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Paso8;
