import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

export const SelectAtom = ({
  id = Math.round(Math.random() * (100 - 1)),
  label,
  name,
  onChange,
  items,
  defaultValue,
  placeholder,
  fullWidth,
  required,
  resetValue,
  error,
  className,
  tabIndex,
}) => {
  // test methods - TODO add functionality
  const classes = selectCustomStyles();
  const [value, setValue] = useState('');
  const [state, setIcoStatus] = useState(true);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    resetValue && setValue(defaultValue);
  }, [resetValue, defaultValue]);

  const handleChange = (event) => {
    onChange(event);
    setValue(event.target.value.name || event.target.value);
  };

  const changeIcoStatus = () => {
    const status = !state ? true : false;
    setIcoStatus(status);
  };

  return (
    <FormControl
      variant="filled"
      style={fullWidth && { width: '100%' }}
      required={required ? required : false}
      className={`${classes.formControl} ${className} ${
        error && error.message && 'error-field'
      }`}
    >
      <InputLabel
        shrink={true}
        htmlFor={`label-${id}`}
        // notched={true}
        id={`${id}`}
      >
        {label}
      </InputLabel>
      <Select
        id={`label-${id}`}
        value={value}
        name={name}
        inputProps={{
          tabIndex: tabIndex,
          'aria-hidden': 'true',
          id: `label-${id}`,
        }}
        aria-hidden="true"
        onChange={handleChange}
        onOpen={changeIcoStatus}
        onClose={changeIcoStatus}
        displayEmpty
        // label={label}
        placeholder={placeholder}
        renderValue={(selected) => {
          if (!value) {
            return <span className={classes.placeHolder}>{placeholder}</span>;
          } else {
            return <span>{selected.name || selected}</span>;
          }
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,

          classes: { paper: classes.dropdownStyle },
        }}
      >
        {items &&
          items.map((item, key) => (
            <MenuItem
              disabled={item.disabled}
              key={item.key || key}
              value={
                item.value ||
                (item.code && { code: item.code, name: item.name }) ||
                item.id ||
                item
              }
            >
              {item.name || item}
            </MenuItem>
          ))}
      </Select>
      {error && <span className={classes.errorMessage}>{error.message}</span>}
    </FormControl>
  );
};

const selectCustomStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  icon: {
    fontSize: '30px',
    position: 'absolute',
    zIndex: '888',
    right: '1px',
  },

  formControl: {
    // backgroundColor: '#fff',
    // width: '100%',
    // "& + fieldset":{
    //   "&.PrivateNotchedOutline":{
    //     "& .legendLabelled":{
    //       fontSize:'15px'
    //     }
    //   },
    //   "&.PrivateNotchedOutline-legendLabelled":{
    //     fontSize:'15px'
    //   },
    // },

    position: 'relative',
    '& .MuiFilledInput-root': {
      // width: 'auto',
      height: '52px',
      borderRadius: '5px',
      backgroundColor: '#fff',
      borderColor: 'var(--primary-color)',
      border: '1px solid var(--primary-color)',
      '&:before': {
        content: '',
        border: '0px',
      },
    },
    '&.error-field .MuiFilledInput-root': {
      border: '2px solid red',
    },
    '& .MuiFilledInput-input': {
      padding: '15px 30px 10px 20px',
    },

    '& .MuiSelect-selectMenu': {
      fontFamily: 'Kreon',
      fontSize: '15px',
      color: '#000',
      lineHeight: '12px',
    },
    '&.error-field .MuiSelect-selectMenu': {
      color: 'red',
    },
    '&.error-field .MuiInputLabel-filled': {
      color: 'red',
    },
    '& .MuiSelect-select.MuiSelect-select': {
      background: 'transparent',
      width: '100%',
    },
    '& .MuiFilledInput-underline:after': {
      content: '',
      border: '0px;',
    },
    '& .MuiFilledInput-root.Mui-focused': {
      // border: '1px solid #242324',
      border: '1px solid var(--secondary-color)',
      //boxShadow: '0px 0px 0px 4px rgba(247, 149, 129, 1)',
      boxShadow: ' 0px 0px 0px 4px var(--secondary-color-disabled)',
    },
    '& .MuiInputLabel-filled': {
      position: 'absolute',
      top: '-15px',
      color: '#000',
      background: '#fff',
      fontFamily: 'Kreon',
      paddingRight: '6px',
      paddingLeft: '6px',
    },
    // '& .MuiOutlinedInput-notchedOutline' : {
    //   borderColor: '#242324 !important'
    // },
    '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red',
    },
  },
  placeHolder: {
    color: '#79757B',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  dropdownStyle: {
    border: '1px solid #242324',
    borderRadius: '10px',
    backgroundColor: '#FFFFFF',

    overflowY: 'auto',
    margin: 0,
    padding: 0,
    listStyle: 'none',
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#ebebeb',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'var(--primary-color)',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },

    '& ul li': {
      color: '#242324',
      fontFamily: 'Kreon',
      fontSize: '18px',
      letterSpacing: 0,
      lineHeight: '30px',
    },
  },
  errorMessage: {
    fontFamily: 'Kreon',
    color: 'red',
    display: 'block',
    // padding: '10px 0px',
    fontSize: '12px',
    position: 'absolute',
    bottom: '-10px',
    left: '10px',
    padding: '5px',
    background: '#fff',
  },
}));
