import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { API_URL } from '../../constants';
import AuthContext from '../../contexts/AuthContext';

import { PrimaryButton, SecondaryButton } from '../../ui/atoms/Button';
import SimpleModal from '../../ui/atoms/modal.js';
import './tablaPedidos.css';

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const dd = String(date.getDate()).padStart(2, '0');
  const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = date.getFullYear();
  const formattedDate = dd + '/' + mm + '/' + yyyy;
  return formattedDate;
};

function TablaPedidos() {
  const [auth] = useContext(AuthContext);
  const [pedidos, setPedidos] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const email = auth.user.email;

  useEffect(() => {
    let isActive = true;
    const getPedidosData = async () => {
      const pedidosResponse = await fetch(`${API_URL}/packages/me`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });

      let pedidosData = await pedidosResponse.json();
      console.log(pedidosData, 'pedidosData');
      if (!pedidosData['hydra:member']) return;
      pedidosData = pedidosData['hydra:member'];
      for (let pedidoData of pedidosData) {
        const kidId = pedidoData.kid?.substring('/api/kids/'.length);
        const kidResponse = await fetch(`${API_URL}/kids/${kidId}`, {
          method: 'GET',
        });
        const kidJson = await kidResponse.json();
        const kidName = kidJson.name;
        pedidoData.kid = kidName;

        switch (pedidoData.status) {
          case 'pending':
            if (pedidoData.retryPayment === true) {
              pedidoData.statusTranslation = 'Pago pendiente';
              pedidoData.statusColor = 'red';
            } else {
              pedidoData.statusTranslation = 'Pendiente';
              pedidoData.statusColor = 'orange';
            }

            break;

          case 'pending_packages':
            pedidoData.statusTranslation = 'Pendiente';
            pedidoData.statusColor = 'orange';
            break;

          case 'received':
            pedidoData.statusTranslation = 'Recibido';
            pedidoData.statusColor = 'cornflowerblue';
            break;

          case 'accepted':
            pedidoData.statusTranslation = 'Check out hecho';
            pedidoData.statusColor = 'green';
            break;

          case 'rejected':
            pedidoData.statusTranslation = 'Check out hecho';
            pedidoData.statusColor = 'green';
            break;

          case 'rejected_partial':
            pedidoData.statusTranslation = 'Check out hecho';
            pedidoData.statusColor = 'green';
            break;

          case 'send':
            pedidoData.statusTranslation = 'Enviado';
            pedidoData.statusColor = 'cornflowerblue';
            break;

          default:
            pedidoData.statusTranslation = pedidoData.status;
            pedidoData.statusColor = 'black';
            break;
        }
      }
      if (isActive) {
        setPedidos(pedidosData);
        setLoading(false);
      }
    };

    getPedidosData();

    return () => {
      isActive = false;
    };
  }, [auth.token]);

  return (
    <div className="tablaContainer d-flex justify-content-center mt-2 mb-3 mt-md-5 ">
      {loading ? (
        <p>Cargando pedidos...</p>
      ) : pedidos && pedidos.length > 0 ? (
        <table className="tabla-pedidos">
          <thead>
            <tr>
              <th>Nº de pedido</th>
              <th>Fecha</th>
              <th>Para</th>
              <th>Estado</th>
              <th>Total</th>
              <th></th>
              <th></th>
              {/* <th></th> */}
            </tr>
          </thead>
          <tbody>
            {pedidos &&
              pedidos.map((pedido) => (
                <tr className="mb-3">
                  <td>{pedido.code}</td>
                  <td>
                    {pedido.date && formatDate(pedido.date.substring(0, 10))}
                  </td>
                  <td className="text-capitalize">{pedido.kid}</td>
                  <td>
                    {
                      <span style={{ color: pedido.statusColor }}>
                        {pedido.statusTranslation}
                      </span>
                    }
                  </td>
                  <td>{`${
                    (pedido && pedido.total === '0.00') ||
                    pedido.status === 'send'
                      ? '-'
                      : pedido.total + '€'
                  } ${pedido && pedido.isPaid ? '(Pagado)' : ''}`}</td>
                  {/* <td></td> */}
                  <td>
                    <SimpleModal
                      //openmodal={modalState}
                      //onClose={resetModalState}
                      text="Detalles"
                      title="Detalles del pedido"
                      data={pedido}
                      secondary={true}
                    />
                  </td>
                  <td>
                    {pedido.status === 'received' && (
                      <PrimaryButton
                        //disabled={pedido.status !== 'received'}
                        value="Check out"
                        className="litle w-75 mb-2"
                        onClick={() => {
                          history.push(`/checkout/${pedido.id}`);
                        }}
                      />
                    )}

{/*                     <PrimaryButton
                      //disabled={pedido.status !== 'received'}
                      value="Check out"
                      className="litle w-75 mb-2"
                      onClick={() => {
                        history.push(`/checkout/${pedido.id}`);
                      }}
                    /> */}

                    {pedido.status === 'send' && (
                      <PrimaryButton
                        disabled={true}
                        value="Check out"
                        className="litle w-75 mb-2"
                      />
                    )}
                    {pedido.status === 'rejected_partial' && !pedido.isPaid && (
                      <a
                        target="_blank"
                        href="https://www.seur.com/devoluciones/pages/devolucionInicio.do?id=1ffb041d-8967-4bb3-8c7a-16c7e01b903d"
                        rel="noreferrer"
                      >
                        <PrimaryButton
                          className="litle w-75 mb-2"
                          value="Devolución"
                        />
                      </a>
                    )}
                    {pedido.status === 'rejected' && !pedido.isPaid && (
                      <a
                        target="_blank"
                        href="https://www.seur.com/devoluciones/pages/devolucionInicio.do?id=1ffb041d-8967-4bb3-8c7a-16c7e01b903d"
                        rel="noreferrer"
                      >
                        <PrimaryButton
                          className="litle w-75 mb-2"
                          value="Devolución"
                        />
                      </a>
                    )}
                    {pedido.retryPayment === true && (
                      <PrimaryButton
                        className="litle w-75 mb-2"
                        value="Pagar"
                        onClick={async () => {
                          window.location = `${API_URL}/do-purchase/${pedido.code}/${email}`;
                        }}
                      />
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        <p>Aún no has realizado ningún pedido</p>
      )}
    </div>
  );
}

export default TablaPedidos;
