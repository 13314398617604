import Footer from '../footer';
import Header from '../Header';
import Whatsapp from '../Whatsapp';
import { Link } from 'react-router-dom';
import { PrimaryButton } from '../../ui/atoms/Button.js';
import { Helmet } from 'react-helmet';
import Outfit from '../../ui/atoms/Outfit';


function QuienesSomos() {
  return (
    <>
      <Header isHome={false} />
      <Whatsapp />
      <div className="container mt-5 mb-md-5">
        <Helmet>
          <title>
            Tienda de ropa infantil online y Estilistas para niños | DressKids
          </title>
          <meta
            name="description"
            content="Nuestros estilistas diseñan looks para tus niños, para ir al cole, al parque o de fiesta ¡Pide tu pack en tu tienda de ropa para niños con más estilo!"/>
        </Helmet>

        <h1 className="h1 text-center">
          Tu tienda de ropa infantil con servicio de estilismo
        </h1>
        <div className="mt-5">
          <p>
            Cuando Javi y su equipo, los fundadores de la tienda online de ropa para
            niños y niñas DressKids, trabajaban en banca y dedicaban muchísimas
            horas a su trabajo, salían muy cansados y sin ganas de hacer tareas
            estresantes. Sin embargo, eran muchos los días en los que tenían que
            ir de tiendas a comprar ropa para sus hijos porque llegaban con ella
            rota del colegio o porque se daban cuenta de que se les había
            quedado pequeña de un día para otro (¿a quién no le ha pasado alguna
            vez que sus hijos hayan crecido de un día para otro?). ¡Hasta hubo
            una semana en la que tuvieron que comprar tres pantalones!
          </p>
          <p>
            La única solución que encontraban era comprar ropa a la hora de la
            comida y perder muchísimo tiempo navegando por Internet en busca de
            prendas y combinaciones adecuadas al estilo y las necesidades de sus
            hijos, en distintas páginas web de ropa infantil. Lo que sí que
            intentaban evitar a toda costa era tener que ir con sus hijos a
            tiendas físicas porque era una actividad de lo más estresante y que
            ellos no disfrutaban para nada.
          </p>
          <p>
            Todo esto les llevó a pensar que quizás habría un servicio que
            pudiera encargarse de elegir y comprar la ropa infantil de sus hijos
            por ellos. Al no encontrar ninguna web o tienda de ropa para niños
            con servicio de personal shopper infantil que satisfaciera sus
            necesidades y se adaptara a su presupuesto, decidieron crear un
            servicio de cajas de ropa infantil, y, así, nació DressKids y su
            equipo de estilistas para niños.
          </p>
          <h2 className="h3">
            ¿Qué hacen por ti, nuestros estilistas para niños?
          </h2>
          <p>
            El objetivo de nuestro equipo de estilistas de moda infantil es que
            los más pequeños de la casa puedan vestir con unas prendas adecuadas
            a sus necesidades, actividades y, sobre todo, gustos.
          </p>
          <p>
            Son especialistas en colores, texturas, materiales y estudian hasta
            el más mínimo detalle del cuestionario que, como padres, nos hacéis
            llegar a la hora de solicitar vuestro pack DressKids.
          </p>
          <p>
            Nuestro equipo de estilistas para niños conoce a la perfección las
            últimas tendencias en moda infantil y saben elegir las marcas de
            ropa para niños y niñas que mejor resultado dan y más se adaptan a
            cada presupuesto.
          </p>
          <h2 className="h3">¿Cuánto cuesta un estilista infantil?</h2>
          <p>
            En los packs de tu tienda de ropa para niños DressKids, el servicio
            de estilista infantil es completamente <strong>gratuito</strong>.
            Dicho esto, no siempre es así, piensa que un servicio puntual de
            personal shopper infantil suele rondar los 100€ por asesoría (eso
            sin tener en cuenta el precio de las prendas de ropa infantil que
            elija para cada cliente).
          </p>
          <h2 className="h3">
            Algunas de las cajas de DressKids que hemos diseñado
          </h2>
          <p>
            La variedad de packs de ropa infantil que podemos crear es tan
            amplia y diversa como los clientes que han confiado ya en nosotros;
            aquí te dejamos algunos ejemplos de outfits seleccionados por
            nuestros estilistas infantiles:
          </p>
          <div className='row'>
            <div className='col-3'>
              <Outfit foto="formalKidNino"/>
            </div>
            <div className='col-3'>
              <Outfit foto="sportMiniNino"/>
            </div>
            <div className='col-3'>
              <Outfit foto="sportMiniNina"/>
            </div>
            <div className='col-3'>
              <Outfit foto="formalMiniNina"/>
            </div>
          </div>
        </div>
        <div className='mb-4 mt-3 d-flex justify-content-center'>
          <Link to="/paso0">
            <PrimaryButton className="mt-3" value="Quiero probar Dresskids" />
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default QuienesSomos;
