import Footer from '../footer';
import Header from '../Header';
import Whatsapp from '../Whatsapp';

function About() {
  return (
    <>
      <Header />
      <Whatsapp/>
      <div className="container mt-5 mb-md-5">
        <h1 className="h1 mb-4">¿Por qué DressKids?</h1>
        <p className="h3">Invierte tu tiempo donde más te importa</p>
        <p>
          Con DressKids podrás comprar la ropa de los peques en unos pocos
          minutos,<strong> ganando todo ese tiempo que invertías hasta ahora</strong>, para
          disfrutarlo donde, cómo y con quien quieras.
        </p>
        <p className="h3 mt-5">Evita llevar de tiendas a tu hij@s</p>
        <p>
          DressKids <strong>te manda la ropa a casa. Pruébales la ropa tranquilamente</strong> en
          su entorno de confort sin <strong>ningún estrés </strong>y sin prisas.<strong>Evitas</strong> las
          <strong>aglomeraciones,</strong> los <strong>desplazamientos</strong> y el <strong>mal estar de los niños</strong> cuando vais a comprar ropa.
        </p>
        <p className="h3 mt-5">Da una solución rápida y sencilla a tu día a día </p>
        <p>
          Un breve formulario nos ayudará a conocer tus gustos. El resto lo hará
          DressKids por ti. <strong>Es muy rápido y cómodo vestir a tu niño con mucho
          estilo.</strong> Además, podrás devolver gratuitamente las prendas que no
          desees, con un <strong>método de devolución muy sencillo.</strong>
        </p>
        <p className="h3 mt-5">Nos adaptamos a tus necesidades</p>
        <p>
          Desde DressKids te ofrecemos dos alternativas de pack, para que elijas
          la que más se <strong>adapte a tus necesidades.</strong> También podrás elegir entre
          distintos estilos y rango de precios. <strong>Te lo mandamos a donde quieras</strong> y
          lo podrás <strong>devolver donde mejor te convenga</strong> a través de los miles de
          puntos PickUp repartidos por toda la geografía española.
        </p>
        <p className="h3 mt-5">Solución económica</p>
        <p>
          <strong>No pagarás nada hasta que no decidas qué prendas te quedas.</strong> Las
          prendas <strong>no tienen sobrecoste</strong> en el precio, cuestan lo mismo que en
          tienda (1). Si te quedas con el pack completo, se te aplicará un <strong>25%
          de descuento</strong> en el total de la compra. Todas las devoluciones son
          gratuitas.
        </p>

        <small>(1) Precio recomendado por el proveedor</small>
      </div>
      <Footer />
    </>
  );
}
export default About;
