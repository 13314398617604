import { useContext, useEffect, useState } from 'react';
import { PrimaryButton, SecondaryButton } from '../ui/atoms/Button';
import { decodeTokeWithJwt } from '../ui/atoms/jwt-decode.js';
import { InputText } from '../ui/atoms/Input';
import { Link, useHistory } from 'react-router-dom';
import AuthContext from '../contexts/AuthContext';
import KidInfoContext from '../contexts/KidInfoContext';
import {
  PATH_PAGINA_INICIO,
  API_URL,
  TOKEN_ENDPOINT,
  API_BASE_URL,
} from '../constants';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Whatsapp from '../components/Whatsapp';
import { saveToLocalStorage } from '../services/local-storage-manager';
import { Helmet } from 'react-helmet';
import SimpleLogo from '../img/simple-logo.svg';

function Login() {
  const [kidInfo] = useContext(KidInfoContext);
  const isUserAlreadyLogged = decodeTokeWithJwt(
    localStorage.getItem('AuthToken')
  );
  const [emailValue, setEmailValue] = useState('');
  const [passValue, setPassValue] = useState('');
  // const [sessionOpen, setSessionState] = useState(
  const [,] = useState(
    !!(isUserAlreadyLogged && isUserAlreadyLogged.type === 'Normal')
  );
  const [provinces, setProvinces] = useState([]);
  const [, setAuth] = useContext(AuthContext);
  const MySwal = withReactContent(Swal);
  const history = useHistory();

  const updateMailValue = (field) => {
    setEmailValue(field.value);
  };

  const updatePassValue = (field) => {
    setPassValue(field.value);
  };

  useEffect(() => {
    let isActive = true;

    const getProvincesData = async () => {
      const response = await fetch(`${API_URL}/provinces`);
      const provincesDataJson = await response.json();
      let provincesDataFirst = provincesDataJson['hydra:member'];
      provincesDataFirst = provincesDataFirst.map((province, index) => ({
        ...province,
        code: '' + index,
      }));
      let nextUrl = provincesDataJson['hydra:view']['hydra:next'];
      const response2 = await fetch(`${API_BASE_URL}${nextUrl}`);
      const provincesDataJson2 = await response2.json();
      let provincesDataSecond = provincesDataJson2['hydra:member'];
      provincesDataSecond = provincesDataSecond.map((province, index) => ({
        ...province,
        code: '' + index,
      }));
      let provincesData = [...provincesDataFirst, ...provincesDataSecond];
      provincesData.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      if (isActive) {
        setProvinces(provincesData);
      }
    };

    getProvincesData();

    return () => {
      isActive = false;
    };
  }, [setProvinces]);


  return (
    <div className="container pt-5 pb-5">
      <Helmet>
        <title>Inicia sesión - Dresskids</title>
      </Helmet>
      <Whatsapp />
      <Link to={PATH_PAGINA_INICIO}>
        <i className="bi bi-arrow-left fs-3"></i>
      </Link>
      <div className='d-flex justify-content-center'>
        <img src={SimpleLogo}  alt="Logo"/>
      </div>
      <h1 className="h1 text-center">¿Tienes cuenta en Dresskids?</h1>
      <div className="row d-flex justify-content-center mt-4">
        <div className="col-12 col-sm-9 col-md-6 col-lg-4">
          <div className="row mt-4">
            <div className="col-12 form-floating mb-4">
              <InputText
                onChange={updateMailValue}
                name="email"
                label="Email"
                placeholder="Introduce email"
              />
            </div>
            <div className="col-12 form-floating mb-4">
              <InputText
                onChange={updatePassValue}
                name="contraseña"
                type="password"
                label="Contraseña"
                placeholder="Introduce contraseña"
              />
              <p className="mt-3 mb-0">
                <Link className="darkLink" to="/restaurar-contrasena">
                  {' '}
                  ¿Olvidaste tu contraseña?
                </Link>
              </p>
            </div>
            <div className="col-12">
              <PrimaryButton
                className="w-100"
                value="Entrar"
                onClick={async () => {
                  let loginSucces = true;
                  await MySwal.fire({
                    html: 'Iniciando sesión...',
                    icon: 'info',
                    didOpen: async () => {
                      Swal.showLoading();
                      // TODO how to avoid mem leak on this fetch
                      const tokenResponse = await fetch(`${TOKEN_ENDPOINT}`, {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                          email: emailValue,
                          password: passValue,
                        }),
                        
                      });

                      if (tokenResponse.status === 401) {
                        MySwal.fire({
                          html: 'Email o contraseña incorrectos.',
                          icon: 'error',
                        });
                        loginSucces = false;
                      }
                      let token;
                      let user;
                      if (loginSucces) {
                        const tokenJson = await tokenResponse.json();
                        token = tokenJson.token;

                        // TODO how to aovid mem leak on this fetch
                        const userResponse = await fetch(
                          `${API_URL}/users/me`,
                          {
                            method: 'GET',
                            headers: {
                              'Content-Type': 'application/json',
                              Authorization: `Bearer ${token}`,
                            },
                          }
                        );
                        user = await userResponse.json();
                        if (userResponse.status !== 200) {
                          MySwal.fire({
                            html: 'Email o contraseña incorrectos.',
                            icon: 'error',
                          });
                          loginSucces = false;
                        }
                      }
                      if (loginSucces) {
                        // TODO how to aovid mem leak on this fetch
                        const addressResponse = await fetch(
                          `${API_URL}/address/me`,
                          {
                            method: 'GET',
                            headers: {
                              'Content-Type': 'application/json',
                              Authorization: `Bearer ${token}`,
                            },
                          }
                        );
                        let userAddress = await addressResponse.json();
                        let address = userAddress['hydra:member'][0];
                        if (address) {
                          let addressCountry = provinces.find(
                            (p) => p.name === address.country
                          );
                          address = { ...address, country: addressCountry };
                        }
                        setAuth({
                          user: { ...user, address },
                          token,
                        });
                        saveToLocalStorage(user, token);
                        MySwal.close();
                      }
                    },
                  });
                  history.push('/area-usuario');
                }}
              />
            </div>
            <div className="col-12 mt-3">
              <SecondaryButton
                className="w-100"
                value="Aún no tengo cuenta"
                onClick={() => {
                  if (kidInfo.gender) {
                    history.push('/paso1');
                  } else {
                    history.push('/paso0');
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
