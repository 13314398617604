import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

export const InputText = ({
  className,
  id = '' + Math.round(Math.random() * (1000 - 1)),
  name,
  value,
  defaultValue,
  label,
  placeholder,
  disabled = false,
  required,
  onClick,
  onBlur,
  onChange,
  onKeyDown,
  type = 'text',
  multiline = false,
  error,
  tabIndex,
  maxLength,
}) => {
  const classes = customStyles();

  const handleOnChange = (event) => {
    onChange &&
      onChange({
        name: event.target.name,
        value: event.target.value,
      });
  };

  const handleOnBlur = (event) => {
    onBlur &&
      onBlur({
        name: event.target.name,
        value: event.target.value,
      });
  };

  return (
    <div className={`${className} ${classes.textFieldContainer}`}>
      <TextField
        multiline={multiline}
        id={id}
        type={type}
        onKeyDown={onKeyDown}
        onClick={onClick}
        name={name}
        value={value}
        defaultValue={defaultValue}
        className={`${classes.root} ${multiline && 'multiline'} ${
          error && error.message && 'error-field'
        } ${!label && 'visibilityLabel'}`}
        required={required}
        label={label ? label : 'buscar'}
        aria-hidden="true"
        // shrink={true}
        // notched={true}
        placeholder={placeholder}
        variant="outlined"
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        disabled={disabled}
        autoComplete={`true`}
        error={error ? error.message : false}
        inputProps={{ tabIndex: tabIndex, maxLength: maxLength }}
        InputLabelProps={{
          shrink: true,
          htmlFor: id,
          id: id,
        }}
      />
      {error && <span className={classes.errorMessage}>{error.message}</span>}
    </div>
  );
};

const customStyles = makeStyles((theme) => ({
  textFieldContainer: {
    position: 'relative',
  },
  root: {
    display: 'block',
    // height: "44px",
    '& i': {
      position: 'absolute',
      fontSize: '30px',
      color: '#79757B',
      left: '10px',
    },

    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: '0px',
      // height: "40px",
    },

    '& div.MuiInputBase-root': {
      width: '100%',
      '&.Mui-disabled': {
        '& input': {
          border: '1px solid lightgray',
          color: 'lightgray',
        },
      },
    },
    '& label.MuiFormLabel-root': {
      fontFamily: 'Kreon',
      color: '#242324',
      paddingLeft: '7px',
      paddingRight: '7px',
      backgroundColor: '#fff',
      '&.Mui-focused': {
        color: '#242324',
        // "& .MuiOutlinedInput-notchedOutline": {
        //   borderColor: "#000"
        // }
      },
    },
    '&.visibilityLabel label.MuiFormLabel-root': {
      visibility: 'hidden',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#40716b ',
      borderRadius: '10px',
    },
    '&.error-field label.MuiFormLabel-root': {
      color: 'red',
    },
    '& input': {
      padding: '0 14px',
      height: '52px',
      border: '1px solid var(--primary-color) ',
      borderRadius: '5px',
      backgroundColor: '#fff',
      fontFamily: 'Kreon',
      color: '#000',
      fontSize: '15px',
      fontWeight: 600,
      letterSpacing: 0,
      '&.MuiOutlinedInput-input.MuiOutlinedInput-inputAdornedStart': {
        paddingLeft: '40px',
      },

      '& + fieldset': {
        backgroundColor: 'transparent',
        border: '0px',
      },
      '&:focus': {
        border: '1px solid var(--primary-color)',
      },
      '&:valid:focus': {
        border: '1px solid var(--primary-color)',
      },

      '&:-internal-autofill-selected': {
        borderRadius: '10px',
        background: 'none',
      },
    },
    '&.error-field input': {
      color: 'red',
    },

    '&.multiline': {
      height: '110px',
      '&:focus': {
        outline: 'none',
      },
      '& div.MuiInputBase-root': {
        width: '100%',
        height: '120px',
        fontFamily: 'Kreon',
        fontSize: '14px',
        '& textarea': {
          height: '100px !important',
        },
      },
    },
  },
  errorMessage: {
    fontFamily: 'Kreon',
    color: 'red',
    display: 'block',
    fontSize: '12px',
    bottom: '-19px',
    left: '10px',
    padding: '3px 5px 5px 5px',
    lineHeight: '12px',
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#242324 !important',
  },
}));
