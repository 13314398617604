import React, { forwardRef } from 'react';
import styled from 'styled-components';

export const ButtonAtom = forwardRef((props, ref) => {
  const {
    disabled,
    className,
    value,
    onClick,
    onFocus,
    centerText = true,
  } = props;

  return (
    <button
      onFocus={onFocus}
      onClick={onClick}
      ref={ref}
      disabled={disabled}
      className={`${className} ${centerText && 'text-centered'}`}
      aria-hidden="true"
      aria-label="Center Align"
    >
      {value}
    </button>
  );
});

export const PrimaryButton = styled(ButtonAtom)`
  min-height: 50px;
  border: 0px;
  border-radius: 10px;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  padding: 0 20px;
  min-width: 200px;
  margin: auto;
  font-family: Kreon !important;
  font-size: 16px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 30px;
  cursor: pointer;
  padding-left: 20px;
  text-align: left;
  transition: 0.3s ease-out;

  &.text-centered {
    text-align: center;
    padding: 0px 15px;

    i {
      font-size: 25px;
      vertical-align: middle;
    }
  }

  &.litle {
    text-transform: none;
    font-weight: 600;
    height: 43px;
    min-width: 110px;
    box-shadow: 2px 2px 5px 0px rgb(0 0 0 / 9%);
    margin: 0;
  }

  &:active,
  &:focus {
    border: 0px;
    outline: 0px;
    color: #fff;
    background-color: var(--primary-color);
  }

  &:hover {
    color: #fff;
    background-color: var(--primary-color);
  }

  &:hover::before {
    background-color: var(--secondary-color);
    transition: 0.3s ease-out;
  }

  &:disabled {
    background-color: var(--secondary-color-disabled);
    color: var(--primary-color-disabled);
    cursor: auto;
  }

  @media (max-width: 576px) {
    width: 100%;
  }
`;

export const SecondaryButton = styled(ButtonAtom)`
  box-sizing: border-box;
  height: 50px;
  border: 3px solid var(--secondary-color);
  border-radius: 10px;
  background-color: #fff;
  min-width: 200px;
  margin: auto;
  color: var(--primary-color);
  font-family: Kreon !important;
  font-size: 16px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 20px;
  cursor: pointer;
  padding-left: 20px;
  text-align: left;
  transition: 0.3s ease-out;

  &.text-centered {
    text-align: center;
    padding: 0px 15px;
  }

  &.litle {
    text-transform: none;
    font-weight: 600;
    height: 43px;
    min-width: 110px;
    margin: 0;
  }

  &:active,
  &:focus {
    outline: 0px;
    background-color: var(--primary-color);
    color: #fff;
  }

  &:hover {
    background-color: var(--primary-color);
    color: #fff;
    border: 3px solid var(--primary-color);
  }

  &:hover::before {
    transition: 0.3s ease-out;
  }

  &:hover i {
    color: #fff;
  }
  &:focus i {
    color: #fff;
  }

  &:disabled {
    border: 2px solid #e0dede;
    color: #e0dede;
    background: #fff;
    cursor: auto;
  }
  @media (max-width: 576px) {
    width: 100%;
  }
`;

export const CounterButton = styled(ButtonAtom)`
  border-style: none;
  background: none;
  font-size: 18px;
  &:hover {
    color: var(--primary-color);
  }
`;
