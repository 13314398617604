function ResumenPedido(props) {
  // console.log(props.pedido.type, 'props.pedido.type');
  let date = new Date();
  const fechaFinal = 'Nov 26 2022 23:59:59 GMT+0100';
  const fechaInicio = 'Nov 21 2022 00:00:00 GMT+0100';

  return (
    <div className="resumen-container">
      <div
        className="w-100 resumen-pedido fw-bold fontSecondary"
        data-bs-toggle="collapse"
        href="#collapseResumen"
        role="button"
        aria-expanded="false"
        aria-controls="collapseResumen"
      >
        <h2 className="mb-2 h4">Resumen de compra</h2>
        <i className="fas fa-chevron-down"></i>
      </div>
      <div className={`collapse ${props.show && 'show'}`} id="collapseResumen">
        <div className="row mt-2">
          <div className="col-5">
            <div className="img-pedido"></div>
          </div>
          <div className="col-7 pt-2">
            {props.pedido.type === 'personalizado' ? (
              <>
                <p className="fw-bold ">
                  Pack{' '}
                  {props.pedido && props.pedido.type === 'personalizdo'
                    ? 'Personalizado'
                    : 'Dresskids'}
                </p>
                {props.pedido &&
                  props.pedido.garments &&
                  props.pedido.garments.map((prenda) => {
                    return (
                      <div key={prenda.id}>
                        <div className="d-flex flex-row justify-content-between">
                          <p>{prenda.garment}</p>
                          <p>{prenda.quantity}</p>
                        </div>
                      </div>
                    );
                  })}
                {Date.parse(date) <= Date.parse(fechaFinal) &&
                Date.parse(date) >= Date.parse(fechaInicio) ? (
                  <>
                    <p className="mb-2">
                      Envío{' '}
                      <span className="text-decoration-line-through">
                        4,95€
                      </span>{' '}
                      
                      <p className="fw-bold">Promoción Black Friday ¡portes gratuítos!</p>
                    </p>
                    <p className="fw-bold">Devoluciones GRATIS</p>
                  </>
                ) : (
                  <>
                    <p className="mb-2">Envío 4,95€</p>
                    <p className="fw-bold">Devoluciones GRATIS</p>
                  </>
                )}
              </>
            ) : (
              <>
                <p className="fw-bold fontSecondary">Pack DressKids</p>
                <p className="mb-2">
                  Recibirás 5 prendas combinables entre si.
                </p>
                <p className="mb-2">Envío 4,95€</p>
                <p className="fw-bold">Devoluciones GRATIS</p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResumenPedido;
