import { Link, useHistory } from 'react-router-dom';
import { PrimaryButton } from '../ui/atoms/Button';
import { useContext, useEffect, useState } from 'react';
import { API_URL } from '../constants';
import { getAllowedDateRange } from '../services/date-calculator';
import AuthContext from '../contexts/AuthContext';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { SelectAtom } from '../ui/atoms/Select';
import Whatsapp from '../components/Whatsapp';

const ChildSelector = ({ setter }) => {
  const [isActive, setisActive] = useState();

  const buttons = [
    {
      id: 2,
      sex: 'niña',
    },
    {
      id: 1,
      sex: 'niño',
    },
  ];

  const Selector = ({ id, onClick, sex, isActive }) => {
    const onHandleOnClick = (id) => {
      onClick(id);
    };

    return (
      <div
        onClick={() => onHandleOnClick(id)}
        className={`${sex} ${isActive ? 'selected' : ''} litle `}
      ></div>
    );
  };

  return (
    <>
      {buttons &&
        buttons.map((button) => (
          <Selector
            key={button.id}
            id={button.id}
            isActive={isActive === button.id}
            onClick={() => {
              setisActive(button.id);
              setter(button.id);
            }}
            sex={button.sex}
          />
        ))}
    </>
  );
};

const FilterColorOjos = ({ id, isActive, onClick, colorPicker }) => {
  return (
    <>
      <div
        style={{ backgroundColor: colorPicker }}
        className={`col-3 color-select ${isActive ? 'selected' : ''}`}
        onClick={() => onClick(id)}
      ></div>
    </>
  );
};

const FilterColorPelo = ({ id, isActive, onClick, colorPicker }) => {
  return (
    <>
      <div
        style={{ backgroundColor: colorPicker }}
        className={`col-3 color-select ${isActive ? 'selected' : ''}`}
        onClick={() => onClick(id)}
      ></div>
    </>
  );
};

const FilterColorPiel = ({ id, isActive, onClick, colorPicker }) => {
  return (
    <>
      <div
        style={{ backgroundColor: colorPicker }}
        className={`col-3 color-select ${isActive ? 'selected' : ''}`}
        onClick={() => onClick(id)}
      ></div>
    </>
  );
};

/*TODO estos datos tiene que devolverlos la api. */
const days = [
  { name: '1', code: '0' },
  { name: '2', code: '1' },
  { name: '3', code: '2' },
  { name: '4', code: '3' },
  { name: '5', code: '4' },
  { name: '6', code: '5' },
  { name: '7', code: '6' },
  { name: '8', code: '7' },
  { name: '9', code: '8' },
  { name: '10', code: '9' },
  { name: '11', code: '10' },
  { name: '12', code: '11' },
  { name: '13', code: '12' },
  { name: '14', code: '13' },
  { name: '15', code: '14' },
  { name: '16', code: '15' },
  { name: '17', code: '16' },
  { name: '18', code: '17' },
  { name: '19', code: '18' },
  { name: '20', code: '19' },
  { name: '21', code: '20' },
  { name: '22', code: '21' },
  { name: '23', code: '22' },
  { name: '24', code: '23' },
  { name: '25', code: '24' },
  { name: '26', code: '25' },
  { name: '27', code: '26' },
  { name: '28', code: '27' },
  { name: '29', code: '28' },
  { name: '30', code: '29' },
  { name: '31', code: '30' },
];

/*TODO estos datos tiene que devolverlos la api. */
const meses = [
  { name: 'Enero', code: '0' },
  { name: 'Febrero', code: '1' },
  { name: 'Marzo', code: '2' },
  { name: 'Abril', code: '3' },
  { name: 'Mayo', code: '4' },
  { name: 'Junio', code: '5' },
  { name: 'Julio', code: '6' },
  { name: 'Agosto', code: '7' },
  { name: 'Septiembre', code: '8' },
  { name: 'Octubre', code: '9' },
  { name: 'Noviembre', code: '10' },
  { name: 'Diciembre', code: '11' },
];
/*TODO estos datos tiene que devolverlos la api, calculando los años del rango de edad de 1-12. Hasta que la api lo mande, tiene que calcularlo el front*/
const years = [
  { name: '2021', code: '0' },
  { name: '2020', code: '1' },
  { name: '2019', code: '2' },
  { name: '2018', code: '3' },
  { name: '2017', code: '4' },
  { name: '2016', code: '5' },
  { name: '2015', code: '6' },
  { name: '2014', code: '7' },
  { name: '2013', code: '8' },
  { name: '2012', code: '9' },
  { name: '2011', code: '10' },
  { name: '2010', code: '11' },
  { name: '2009', code: '12' },
];

function NuevoPerfil() {
  const [auth] = useContext(AuthContext);
  const [coloresOjos, setColoresOjos] = useState([]);
  const [coloresPelo, setColoresPelo] = useState([]);
  const [coloresPiel, setColoresPiel] = useState([]);

  const [gender, setGender] = useState('');
  const [name, setName] = useState('');
  const [birthday, setBirthday] = useState('');
  const [eyesColor, setEyeColor] = useState('');
  const [hairColor, setHairColor] = useState('');
  const [skinColor, setSkinColor] = useState('');
  let dayKidInfo = birthday?.substring(8) || '';
  if (dayKidInfo.substring(0, 1) === '0') {
    dayKidInfo = dayKidInfo.substring(1);
  }
  dayKidInfo = days.find((day) => day.name === dayKidInfo);
  const [day, setDayValue] = useState(dayKidInfo);

  let monthKidInfo = birthday?.substring(5, 7) || '';
  if (monthKidInfo.substring(0, 1) === '0') {
    monthKidInfo = monthKidInfo.substring(1);
  }
  monthKidInfo = meses.find(
    (month) => month.code === '' + (Number(monthKidInfo) - 1)
  );
  const [month, setMonthValue] = useState(monthKidInfo);

  let yearKidInfo = birthday?.substring(0, 4) || '';
  yearKidInfo = years.find((year) => year.name === yearKidInfo);
  const [year, setYearValue] = useState(yearKidInfo);
  const MySwal = withReactContent(Swal);
  const history = useHistory();

  const { minDate, maxDate } = getAllowedDateRange();
  let fechaIncorrecta = false;
  if (birthday) {
    const birthdayDate = new Date(birthday);
    const minDateObj = new Date(minDate);
    const maxDateObj = new Date(maxDate);

    if (birthdayDate < minDateObj || birthdayDate > maxDateObj) {
      fechaIncorrecta = true;
    }
  }

  useEffect(() => {
    let monthV = Number(month?.code);
    let yearV = Number(year?.name);
    // if (month && year) {
    //   const calculateDaysInMonth = (y, m) => new Date(y, m, 0).getDate();
    //   const daysInMonth = calculateDaysInMonth(yearV, monthV + 1);
    //   const days = [];
    //   for (let i = 1; i <= daysInMonth; i++) {
    //     days.push({ name: '' + i, code: '' + i });
    //   }
    //   setDays(days);
    // }

    if (day && month && year) {
      let date = new Date(yearV, monthV, Number(day.name) + 1);
      setBirthday(date.toISOString().substring(0, 10));
    }
  }, [year, month, day, setBirthday]);

  const changeKidName = (e) => {
    setName(e.target.value);
  };

  const updateDay = (value) => {
    setDayValue(value.target.value);
  };
  const updateMonth = (value) => {
    setMonthValue(value.target.value);
  };

  const updateYear = (value) => {
    setYearValue(value.target.value);
  };

  useEffect(() => {
    let isActive = true;
    const getColoresOjosData = async () => {
      const response = await fetch(`${API_URL}/eyes_colors`);
      const json = await response.json();
      const colores = json['hydra:member'];
      colores.sort((a, b) => a.position - b.position);
      if (isActive) {
        setColoresOjos(colores);
      }
    };

    getColoresOjosData();

    return () => {
      isActive = false;
    };
  }, []);

  useEffect(() => {
    let isActive = true;
    const getColoresPeloData = async () => {
      const response = await fetch(`${API_URL}/hair_colors`);
      const json = await response.json();
      const colores = json['hydra:member'];
      colores.sort((a, b) => a.position - b.position);
      if (isActive) {
        setColoresPelo(colores);
      }
    };

    getColoresPeloData();

    return () => {
      isActive = false;
    };
  }, []);

  useEffect(() => {
    let isActive = true;
    const getColoresPielData = async () => {
      const response = await fetch(`${API_URL}/skin_colors`);
      const json = await response.json();
      const colores = json['hydra:member'];
      colores.sort((a, b) => a.position - b.position);
      if (isActive) {
        setColoresPiel(colores);
      }
    };

    getColoresPielData();

    return () => {
      isActive = false;
    };
  }, []);

  const isValidForm =
    gender > 0 &&
    name.length > 0 &&
    /*birthday.length > 0 &&*/
    eyesColor.id !== undefined &&
    hairColor.id !== undefined &&
    skinColor.id !== undefined &&
    year &&
    month &&
    day &&
    !fechaIncorrecta;

  return (
    <div className="container pt-5 pb-5">
      <Whatsapp />
      <Link to={'/area-usuario'}>
        <i className="bi bi-arrow-left fs-3"></i>
      </Link>
      <h1 className="h1 text-center">Vamos a crear un nuevo perfil</h1>

      <div className="row d-flex justify-content-center mt-4">
        <div className="col-12 col-sm-9 col-md-6 col-lg-4">
          <div className="row mt-4">
            <div className="col-12">
              <div className="d-flex flex-row flex-md-row justify-content-center align-items-center mb-3 mb-md-0">
                <ChildSelector setter={setGender} />
              </div>
            </div>

            <div className="col-12 mt-4 mb-3">
              <label className="mb-2">Nombre</label>
              <input
                type="text"
                className="input-pasos w-100"
                value={name}
                onChange={changeKidName}
              />
            </div>

            <div className="col-12 mt-2 mb-0">
              <p>Fecha de nacimiento</p>
            </div>

            <div className="col-12 col-md-4 form-floating mb-3">
              <SelectAtom
                id="año"
                fullWidth={true}
                label="Año"
                placeholder="Año"
                items={years}
                onChange={updateYear}
                defaultValue={years[year]}
              />
            </div>

            <div className="col-12 col-md-4 form-floating mb-3">
              <SelectAtom
                id="mes"
                label="Mes"
                placeholder="Mes"
                fullWidth={true}
                items={meses}
                onChange={updateMonth}
                defaultValue={meses[month]}
                error={fechaIncorrecta && { message: 'fuera de rango' }}
              />
            </div>

            <div className="col-12 col-md-4 form-floating mb-3">
              <SelectAtom
                id="dia"
                fullWidth={true}
                label="Día"
                placeholder="Día"
                items={days}
                onChange={updateDay}
                defaultValue={days[day]}
              />
            </div>

            <div className="col-12 form-floating mt-4 mb-3">
              <p className="mb-3 h4 text-center">Color de ojos</p>
              <div className="d-flex justify-content-around">
                {coloresOjos.map((ojos) => (
                  <FilterColorOjos
                    key={ojos.id}
                    id={ojos.id}
                    colorPicker={ojos.colorPicker}
                    isActive={eyesColor.id === ojos.id}
                    onClick={() => {
                      setEyeColor(ojos);
                    }}
                  />
                ))}
              </div>
            </div>
            <div className="col-12 form-floating mb-3">
              <p className="mb-3 h4 text-center">Color de pelo</p>
              <div className="d-flex justify-content-around">
                {coloresPelo.map((pelo) => (
                  <FilterColorPelo
                    key={pelo.id}
                    id={pelo.id}
                    colorPicker={pelo.colorPicker}
                    isActive={hairColor.id === pelo.id}
                    onClick={() => setHairColor(pelo)}
                  />
                ))}
              </div>
            </div>
            <div className="col-12 form-floating mb-3">
              <p className="mb-4 h4 text-center">Color de piel</p>
              <div className="d-flex justify-content-around">
                {coloresPiel.map((piel) => (
                  <FilterColorPiel
                    key={piel.id}
                    id={piel.id}
                    colorPicker={piel.colorPicker}
                    isActive={skinColor.id === piel.id}
                    onClick={() => setSkinColor(piel)}
                  />
                ))}
              </div>
            </div>

            <div className="col-12 mt-4 d-flex justify-content-center">
              <PrimaryButton
                value="Crear Perfil"
                disabled={!isValidForm}
                onClick={async () => {
                  MySwal.fire({
                    html: 'Creando perfil.',
                    icon: 'info',
                    didOpen: async () => {
                      Swal.showLoading();
                      await fetch(`${API_URL}/kids`, {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json',
                          Authorization: `Bearer ${auth.token}`,
                        },
                        body: JSON.stringify({
                          name: name,
                          birthday: birthday,
                          eyesColor: '/api/eyes_colors/' + eyesColor.id,
                          hairColor: '/api/hair_colors/' + hairColor.id,
                          skinColor: '/api/skin_colors/' + skinColor.id,
                          gender: '/api/genders/' + gender,
                        }),
                      });
                      // TODO confirm kid profile correctly created
                      Swal.close();
                    },
                  }).then(() => {
                    MySwal.fire({
                      icon: 'success',
                      html: 'Perfil creado.',
                      timer: 1000,
                    }).then(() => {
                      history.push('/area-usuario');
                    });
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NuevoPerfil;
