import { useContext, useState } from 'react';
import Footer from '../components/footer';
import Header from '../components/Header';
// import { PrimaryButton, SecondaryButton } from "../ui/atoms/button";
import { PrimaryButton } from '../ui/atoms/Button';
import AuthContext from '../contexts/AuthContext';
import { InputText } from '../ui/atoms/Input';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useHistory } from 'react-router-dom';
import { API_URL } from '../constants';
import Whatsapp from '../components/Whatsapp';

function ChangePassword() {
  const [auth] = useContext(AuthContext);
  const [actualPass, setActualPass] = useState();
  const [newPass, setNewPass] = useState();
  const [repeatNewPass, setRepeatNewPass] = useState();
  const [validPass, setValidPass] = useState(true);
  const [samePass, setSamePass] = useState(true);
  const [fieldsWithError] = useState({});
  const MySwal = withReactContent(Swal);
  const history = useHistory();


  const updatePassValue = (field) => {
    /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/.test(
      field.value
    ) || field.value === ''
      ? setValidPass(true)
      : setValidPass(false);
    setNewPass(field.value);
    repeatNewPass === field.value ? setSamePass(true) : setSamePass(false);
  };

  const updateSamePass = (field) => {
    newPass === field.value ? setSamePass(true) : setSamePass(false);
    setRepeatNewPass(field.value);
  };

  const changeActualPass = (field) => {
    setActualPass(field.value);
  };

  return (
    <>
      <Header isHome={false}/>
      <Whatsapp />
      <div className="container pt-5 pb-5 ">
        <div className="row d-flex justify-content-center mt-2 mt-md-5">
          <h2 className="h3">Cambiar contraseña</h2>
          <p>Introduce tu contraseña actual y la nueva contraseña.</p>

          <div className="col-12 col-md-4">
            <div className="row d-flex justify-content-center mt-5">
              <div className="col-12 form-floating mb-3">
                <InputText
                  name="actualPass"
                  label="Contraseña actual"
                  onChange={changeActualPass}
                  value={actualPass}
                  type="password"
                />
              </div>
              <div className="col-12 form-floating mb-3">
                <InputText
                  name="newPass"
                  label="Nueva contraseña"
                  onChange={updatePassValue}
                  value={newPass}
                  error={
                    fieldsWithError['password'] ||
                    (!validPass && {
                      message:
                        'Contraseña no válida (mín.8 caract., 1 may, 1 num y un símbolo)',
                    })
                  }
                  type="password"
                />
              </div>
              <div className="col-12 form-floating mb-3">
                <InputText
                  name="newPass"
                  label="Repetir nueva contraseña"
                  onChange={updateSamePass}
                  value={repeatNewPass}
                  error={
                    fieldsWithError['password'] ||
                    (!samePass && {
                      message: 'Las contraseñas no coinciden',
                    })
                  }
                  type="password"
                />
              </div>

              <div className="col-12 d-flex justify-content-center">
                <PrimaryButton
                  onClick={async () => {
                    let correct = true;
                    await MySwal.fire({
                      html: 'Cambiando contraseña.',
                      icon: 'info',
                      didOpen: async () => {
                        Swal.showLoading();
                        const changePasswordResponse = await fetch(
                          `${API_URL}/user/change-password`,
                          {
                            method: 'POST',
                            headers: {
                              'Content-Type': 'application/json',
                              Authorization: `Bearer ${auth.token}`,
                            },
                            body: JSON.stringify({
                              currentPassword: actualPass,
                              newPassword: newPass,
                              repeatNewPassword: newPass,
                            }),
                          }
                        );
                        if (changePasswordResponse.status !== 200) {
                          correct = false;
                        }
                        // TODO confirm password changed correctly
                        Swal.close();
                      },
                    });

                    if (correct) {
                      MySwal.fire({
                        icon: 'success',
                        html: 'Contraseña modificada.',
                      });
                      history.push('/area-usuario');
                    } else {
                      MySwal.fire({
                        icon: 'error',
                        html: 'No se ha podido modifcar la contraseña', // TODO recplace with errorMessage,
                      });
                    }
                  }}
                  value="Enviar"
                  disabled={!validPass || actualPass === undefined}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ChangePassword;
