import { useState } from 'react';
import Footer from '../components/footer';
import Header from '../components/Header';
// import { PrimaryButton, SecondaryButton } from "../ui/atoms/button";
import { PrimaryButton } from '../ui/atoms/Button';
import { InputText } from '../ui/atoms/Input';
import { API_URL } from '../constants';
import Swal from 'sweetalert2';
import Whatsapp from '../components/Whatsapp';
import withReactContent from 'sweetalert2-react-content';

function ForgotPassword(props) {
  const [email, setEmail] = useState();
  const [validEmail, setValidEmail] = useState(true);
  const [fieldsWithError] = useState({});
  const MySwal = withReactContent(Swal);

  const changeEmail = (field) => {
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(field.value)
      ? setValidEmail(true)
      : setValidEmail(false);
    setEmail(field.value);
  };

  return (
    <>
      <Header isHome={false}/>
      <Whatsapp />
      <div className="container pt-5 pb-5 heigh">
        <div className="row d-flex justify-content-center mt-2 mt-md-5">
          <h2 className="h3">Restaurar contraseña</h2>
          <p>
            Introduce tu email para que podamos mandarte un enlace para
            restaurar tu contraseña.
          </p>

          <div className="col-12 col-md-8">
            <div className="row d-flex justify-content-center mt-5">
              <div className="col-12 col-md-6 form-floating mb-3">
                <InputText
                  name="email"
                  label="Email"
                  onChange={changeEmail}
                  value={email}
                  error={
                    fieldsWithError['email'] ||
                    (!validEmail && {
                      message: 'Email no válido',
                    })
                  }
                />
              </div>

              <div className="col-12 d-flex justify-content-center">
                <PrimaryButton
                  disabled={!validEmail}
                  onClick={async () => {
                    await MySwal.fire({
                      html: 'Reseteando contraseña...',
                      icon: 'info',
                      didOpen: async () => {
                        Swal.showLoading();
                        let correct = true;
                        const resetResponse = await fetch(
                          `${API_URL}/user/request-reset/${encodeURIComponent(
                            email
                          )} `,
                          {
                            method: 'POST',
                            headers: {
                              'Content-Type': 'application/json',
                            },
                          }
                        );
                        if (resetResponse.status !== 200) {
                          correct = false;
                        }
                        if (correct) {
                          await MySwal.fire({
                            icon: 'success',
                            html: 'Email enviado correctamente.',
                          });
                        } else {
                          MySwal.fire({
                            icon: 'error',
                            html: 'No se ha podido mandar el email.', // TODO recplace with errorMessage,
                          });
                        }
                      },
                    });
                  }}
                  value="Enviar"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ForgotPassword;
