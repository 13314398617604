import Footer from '../components/footer';
import Header from '../components/Header';
import { PrimaryButton } from '../ui/atoms/Button';
import Whatsapp from '../components/Whatsapp';
import { API_URL } from '../constants';
import Error from '../img/error.png';
import { useParams } from 'react-router-dom';

function ErrorPago() {
  const { packageId, email } = useParams();
  // console.log(packageId, email);
  return (
    <>
      <Header isHome= {false}/>
      <Whatsapp />
      <div className="container mt-5 mb-md-5">
        <h1 className="h1 text-center">¡Ups! Algo ha salido mal</h1>

        <div className="d-flex flex-column justify-content-center mt-5">
          <div className="d-flex justify-content-center mb-4">
            <img className="error" src={Error} alt="" />
          </div>

          <PrimaryButton
            value="Reintentar pago"
            className="mb-5 mt-4 mb-md-0"
            onClick={async () => {
              window.location = `${API_URL}/do-purchase/${packageId}/${email}`;
            }}
          />
        </div>
      </div>
      <Footer />
    </>
  );
}
export default ErrorPago;
