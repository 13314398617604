import { PrimaryButton } from '../ui/atoms/Button';
import { Link, useParams } from 'react-router-dom';
import ResumenPedido from '../components/ResumenPedido';
import { useContext, useEffect, useState } from 'react';
import Whatsapp from '../components/Whatsapp';
import { API_BASE_URL, API_URL } from '../constants';
import AuthContext from '../contexts/AuthContext';
import { Helmet } from 'react-helmet';
import Paso11icon from '../img/paso11-icon.svg';

function Paso11() {
  const [auth] = useContext(AuthContext);
  const [pedido, setPedido] = useState(null);
  const { packageId } = useParams();
  // console.log(packageId, 'ID');
  useEffect(() => {
    if (!auth.token) return;

    let isActive = true;
    const getPedidoData = async () => {
      const pedidosResponse = await fetch(`${API_URL}/packages/${packageId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });

      const pedidosData = await pedidosResponse.json();
      let pedidoData = {};
      //console.log('datos que llega en pedidosData:', pedidosData);
      if (pedidosData.typePackage === '/api/type_packages/1') {
        let garments = pedidosData.garmentCustom;
        garments = garments.split('\n');
        garments = garments.filter((g) => g !== '');
        garments = garments.map((g) => g.split(' Cantidad:'));
        garments = garments.map((g) => ({ garment: g[0], quantity: g[1] }));

        pedidoData = {
          type: 'personalizado',
          garments,
        };
      }

      let kidId = pedidosData.kid;

      const kidResponse = await fetch(`${API_BASE_URL}${kidId}`);
      const kidData = await kidResponse.json();
      pedidoData.kidName = kidData.name;
      pedidoData.code = pedidosData.code;

      if (isActive) {
        setPedido(pedidoData);
      }
    };

    getPedidoData();

    return () => {
      isActive = false;
    };
  }, [auth.token, packageId]);

  return (
    <div className="container pt-2 pt-md-5 pb-5 heigh">
      {pedido && pedido.code && (
        <Helmet>
          <script>
            {`gtag('event', 'conversion', { 'send_to': 'AW-306788203/TXO3CPblovECEOvupJIB', 'transaction_id': '${pedido.code}' });`}
          </script>
          <script>
            {`!(function (f, b, e, v, n, t, s) {
              if (f.fbq) return;
              n = f.fbq = function () {
                n.callMethod
                  ? n.callMethod.apply(n, arguments)
                  : n.queue.push(arguments);
              };
              if (!f._fbq) f._fbq = n;
              n.push = n;
              n.loaded = !0;
              n.version = '2.0';
              n.queue = [];
              t = b.createElement(e);
              t.async = !0;
              t.src = v;
              s = b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t, s);
            })(
              window,
              document,
              'script',
              'https://connect.facebook.net/en_US/fbevents.js'
            );
            fbq('init', '2647341192239057');
            fbq('track', 'PageView');
            fbq('track', 'Purchase', {value: 4.95, currency: 'EURO'});
            `}
          </script>
          <noscript>
            <img
              height="1"
              width="1"
              src="https://www.facebook.com/tr?id=2647341192239057&ev=PageView&noscript=1"
            />
          </noscript>
        </Helmet>
      )}
      <Whatsapp />

      <div className="d-flex justify-content-center mt-4">
        <div className="d-flex justify-content-center">
          <img src={Paso11icon} alt="Icono" />
        </div>
        <h1 className="h1 text-center texto-largo mb-0">
          {pedido
            ? `¡La compra para ${pedido.kidName} se ha realizado correctamente!`
            : `¡La compra se ha realizado correctamente!`}
        </h1>
      </div>

      <div className="row d-flex justify-content-center mt-5">
        <div className="col-12 col-md-6">
          {pedido && <ResumenPedido show={true} pedido={pedido} />}

          <p className="h3 text-center mb-4">
            ¡Recibe el paquete y disfrútalo con tu peque!{' '}
          </p>
          <p>
            Revisa y prueba todas las prendas en casa tranquilamente y sin
            prisas. Tienes hasta 5 días para seleccionar las prendas. Elige
            aquellas que quieres quedarte y cuáles no y completa el check out.
          </p>
          <p>
            Para la devolución, dispones de una bolsa para introducir en ella
            las prendas que decidas no quedarte.
          </p>

          <div className="row">
            <div className="col-12 mt-3 d-flex justify-content-center">
              <Link to="/area-usuario">
                <PrimaryButton value="Finalizar" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Paso11;
