import React, { useContext, useEffect, useState } from 'react';
import { PrimaryButton } from '../ui/atoms/Button';
import { Link } from 'react-router-dom';
import KidInfoContext from '../contexts/KidInfoContext';
import Whatsapp from '../components/Whatsapp';
import { getEndpointItemsWithPagination } from '../services/api-calls';
import Paso6icon from '../img/paso6-icon.svg';
import { Helmet } from 'react-helmet';
import { CheckBoxAtom } from '../ui/atoms/Checkbox';

const PrendaFilter = ({ id, name, togglePrenda, isToggled }) => {
  const [isSelected, setSelected] = useState(isToggled);

  const onClickHandler = () => {
    setSelected((prev) => !prev);
    togglePrenda(id);
  };

  return (
    <div
      className={`filterPill ${isSelected && 'filterPill-selected'} mx-2 my-1`}
      onClick={onClickHandler}
    >
      {name}
    </div>
  );
};

function Paso6() {
  const [kidInfo, setKidInfo] = useContext(KidInfoContext);
  const [prendas, setPrendas] = useState([]);
  const [garmentNotWanted, setGarmentNotWanted] = useState(
    kidInfo.prendasNo || []
  );
  const [outlet, setOutletValue] = useState(false);
  console.log(outlet);
  console.log(kidInfo, 'kidInfo');

  useEffect(() => {
    let isActive = true;

    const getSizes = async () => {
      // Get all garments with the kid's gender
      let apiGarmentsData = await getEndpointItemsWithPagination('garments');
      apiGarmentsData = apiGarmentsData.filter(
        (s) => s.type.gender === `/api/genders/${kidInfo.gender}`
      );

      if (isActive) {
        setGarmentNotWanted(kidInfo.garments || []);
        setPrendas(apiGarmentsData);
      }
    };

    getSizes();

    return () => {
      isActive = false;
    };
  }, [kidInfo.gender, kidInfo.garments]);

  const togglePrenda = (name) => {
    if (garmentNotWanted.includes(name)) {
      setGarmentNotWanted(
        garmentNotWanted.filter((prendaName) => prendaName !== name)
      );
    } else {
      setGarmentNotWanted([...garmentNotWanted, name]);
    }
  };
  const acceptOutlet = (value) => {
    setOutletValue(value);
  };
  return (
    <>
      <Helmet>
        <title>¿Hay alguna prenda que no quieras recibir? - Dresskids</title>
      </Helmet>
      <div className="container pt-2 pt-md-5 pb-5">
        <Whatsapp />
        <Link to="/paso5">
          <i className="bi bi-arrow-left fs-3"></i>
        </Link>
        <div className="d-flex justify-content-center">
          <img src={Paso6icon} alt="Icono niño-niña" />
        </div>
        <h1 className="h1 text-center mb-0">
          ¿Hay alguna prenda que no quieras recibir?
        </h1>
        <div className="row d-flex justify-content-center mt-5">
          <div className="col-12 col-md-6">
            <div className="row d-flex justify-content-center">
              <div className="col-12 d-flex flex-wrap justify-content-start">
                {prendas &&
                  prendas.map((prenda) => (
                    <PrendaFilter
                      key={prenda.name}
                      id={prenda.name}
                      name={prenda.name}
                      togglePrenda={togglePrenda}
                      isToggled={garmentNotWanted.includes(prenda.name)}
                    />
                  ))}
              </div>
              <div className="col-12 form-check mt-5 mb-2">
                <p>
                  Si hay disponibilidad, ¿Te gustaría recibir alguna prenda de
                  Outlet?
                </p>
                <CheckBoxAtom onChange={acceptOutlet} value={outlet} />
                <span>Sí, me gustaría.</span>
              </div>
              <div className="col-12 mt-4 d-flex justify-content-center">
                <Link
                  className="w-100 d-flex justify-content-center"
                  to="/paso7"
                >
                  <PrimaryButton
                    value="Continuar"
                    onClick={() => {
                      setKidInfo((prev) => ({
                        ...prev,
                        garmentNotWanted,
                        outlet,
                      }));
                    }}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Paso6;
